.swiper-wrapper.row {
    flex-wrap: nowrap;
}

.swiper.rows {
    .col {
        margin-bottom: 0;
    }
}

.swiper-container {
    overflow: hidden;
}

.swiper-slide {
    height: initial;
    overflow: hidden;
}

.swiper-pagination {
    position: static;
    margin-top: 1.6rem;

    &-bullet {
        --circle: -0.4rem;
        background-color: $color-border-01;
        opacity: 1;
        position: relative;
        margin: 0 0.3rem !important;
        transition: 0.4s;
        border-radius: 0.4rem;

        &::before {
            content: '';
            position: absolute;
            top: var(--circle);
            right: var(--circle);
            bottom: var(--circle);
            left: var(--circle);
            border: 1px solid var(--color-pri);
            border-radius: 100rem;
            opacity: 0;
            transition: 0.1s ease-in-out;
            display: none;
        }

        &-active {
            background-color: var(--color-pri);
            width: 5.5rem;
            border-radius: 0.4rem;

            &::before {
                opacity: 1;
            }
        }
    }
}

.swiper-control {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    --size: 4rem;

    @include max(1250px) {
        --size: 3rem;
    }

    &-btn {
        width: var(--size);
        height: var(--size);
        border-radius: 100rem;
        background-color: white;
        border: .1rem solid var(--color-pri);
        @include mid-flex;
        cursor: pointer;
        @include transitionRe;
        z-index: 5;
        position: relative;
        overflow: hidden;
        font-size: 1.6rem;
        @include affter(var(--color-pri));
        color: var(--color-pri);

        >* {
            position: relative;
            z-index: 2;
        }

        &.swiper-button-disabled {
            // background-color: #dddddd;
            cursor: default;
            box-shadow: none;
            opacity: 0.5;
            display: none;
        }

        i {}

        @include hover {
            &:not(.swiper-button-disabled) {
                color: white !important;

                &::after {
                    left: 0;
                    width: 100%;
                }
            }
        }
    }

    &.white {
        .swiper-control-btn {
            background-color: transparent;
            border-color: white;
            color: white;
        }

        &.mobile-plus {
            .swiper-control-btn {
                @include max(1350px) {
                    background-color: white;
                    border-color: $color-pri;
                    color: $color-pri;
                }
            }

        }
    }

    &.posi {
        .swiper-control-btn {
            @include posi-vertical;
        }

        &.minus {
            .swiper-prev {
                right: calc(100% + 4rem);

                @include max(1350px) {
                    right: unset;
                    left: calc(var(--size) / 2 * -1);
                }
            }

            .swiper-next {
                left: calc(100% + 4rem);

                @include max(1350px) {
                    left: unset;
                    right: calc(var(--size) / 2 * -1);
                }
            }

            &.mobile-plus {
                @include max($res-mobile) {
                    .swiper-prev {
                        left: calc((var(--size) / 2 * -1) + (1.5rem / 2));
                    }

                    .swiper-next {
                        right: calc((var(--size) / 2 * -1) + (1.5rem / 2));
                    }
                }
            }
        }

        &.midle {
            .swiper-prev {
                left: calc(var(--size) / 2 * -1);
            }

            .swiper-next {
                right: calc(var(--size) / 2 * -1);
            }
        }

        &.plus {
            .swiper-prev {
                left: 1rem;
            }

            .swiper-next {
                right: 1rem;
            }

        }

        &.mobile-plus {
            @include max($res-mobile) {
                .swiper-prev {
                    left: calc((var(--size) / 2 * -1) + (1.5rem / 2));
                }

                .swiper-next {
                    right: calc((var(--size) / 2 * -1) + (1.5rem / 2));
                }
            }
        }
    }

    &.custom {
        // &.out {
        //     @include max(1480px) {
        //         display: none;
        //     }
        // }

        // &.in {
        //     @include min(1481px) {
        //         display: none;
        //     }
        // }

        .swiper-control-btn {
            top: 25%;
            background-color: transparent;
            border: unset !important;


            &::after {
                display: none;
            }

            i {
                @include fz-20;
            }

            &:hover {
                i {
                    color: var(--color-hover);
                }
            }

            // @include max(1550px) {
            //     &.swiper-prev {
            //         right: calc(100% + 1rem);
            //     }

            //     &.swiper-next {
            //         left: calc(100% + 1rem);
            //     }
            // }

            // @include max(1480px) {
            //     &.swiper-prev {
            //         right: calc(100% - 5rem);
            //     }

            //     &.swiper-next {
            //         left: calc(100% - 5rem);
            //     }
            // }
        }
    }
}

.swiper-pagination-progressbar {
    background: $color-border-01;
    height: .1rem !important;
}

.swiper-pagination-progressbar-fill {
    background-color: $color-second !important;
}