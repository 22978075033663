:root {
    // text
    --text: var(--color-white);
    --text-hover: var(--color-white);
    --fw: 400;
    // background sub
    --back: var(--color-pri);
    // sub
    --textsub: var(--color-white);
    // font size
    --fz: 1.6rem;

    @include max(1470px) {
        --fz: 1.6rem;
    }
}

.hd-nav {
    height: 100%;

    .menu {
        &-nav {
            height: 100%;

            >.menu-list {
                height: 100%;
                gap: 2.4rem;

                >.menu-item {
                    height: 100%;
                    @include flex;
                    align-items: center;


                    &:last-child {
                        >.menu-list {
                            left: initial;
                            right: 0;
                        }
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        bottom: 4.6rem;
                        @include posi-horizontal;
                        height: 0.1rem;
                        background: var(--color-white);
                        width: 0;
                        // border-radius: 100px;
                        @include transitionRe;
                    }

                    &:hover:not(.dropdown),
                    &:has(.current-menu-item) {
                        &::before {
                            width: 100%;
                        }

                        &::after {
                            animation: menuLa 1s ease-in-out 0.5s forwards;
                        }
                    }

                    &.dropdown {
                        &:hover {
                            &::before {
                                width: 100%;
                            }
                        }
                    }

                    &:hover {
                        >a {
                            color: var(--text-hover);

                            i {
                                transform: rotate(180deg);
                            }
                        }
                    }

                    >.menu-link {
                        height: 100%;
                        @include flex;
                        align-items: center;
                        color: var(--text);
                    }

                    &.current_page_item,
                    &.current-menu-item,
                    &.current_page_parent,
                    &.current-menu-parent,
                    &.current-post-parent {
                        >a {
                            color: var(--text-hover);

                            
                        }

                        &::before {
                            width: 100%;
                        }

                        &::after {
                            animation: menuLa 1s ease-in-out 0.5s forwards;
                        }
                    }

                    >.menu-list {
                        top: calc(100% + 0.7rem);

                        &::after {
                            @include pseudo;
                            bottom: 100%;
                            left: 0;
                            height: 0.7rem;
                            width: 100%;
                        }
                    }
                }
            }
        }

        &-list {
            @include flex;
            align-items: center;
        }

        &-item {
            position: relative;
            @include transition(0.2s, ease-in-out);

            &:hover {
                >.menu-list {
                    @include show;

                    >.menu-item {

                        &.current_page_item,
                        &.current-menu-item {
                            >a {
                                &::before {
                                    width: 100%;
                                }

                                color: var(--text-hover);
                            }
                        }

                        &:hover {
                            background: rgba(255, 255, 255, 0.30);
                        }
                    }
                }
            }

            .menu-link,
            a {
                @include transition(0.2s, ease-in-out);

                i {
                    @include transition(0.2s, ease-in-out);
                }
            }

            >.menu-link,
            >a {
                color: var(--textsub);
                font-weight: var(--fw);
                display: block;
                font-size: var(--fz);

                >i {
                    display: none;
                    font-weight: 600;
                    margin-left: 0.4rem;
                    font-size: 1.4rem;
                    color: inherit;
                }
            }

            &.dropdown {
                >a {
                    >i {
                        display: inline-block;
                    }
                }
            }

            >.menu-list {
                position: absolute;
                top: 0;
                left: 0;
                min-width: 25rem;
                max-width: 28rem;
                @include flex;
                flex-direction: column;
                align-items: flex-start;
                background: var(--back);
                box-shadow: var(--sdow);
                border-radius: .4rem;
                @include transitionRe;
                @include hidden(0, -5px);

                >.menu-item {
                    margin-left: 0;
                    width: 100%;

                    // &:not(:last-child) {
                    //     border-bottom: 0.1rem solid rgba($color: white, $alpha: 0.5);
                    // }
                    &:hover {
                        &::after {
                            opacity: 1;
                        }

                        >.menu-link,
                        >a {
                            color: var(--text-hover);

                            // &::after {
                            //     width: 100%;
                            // }

                            >i {
                                // color: white;
                            }
                        }
                    }

                    >.menu-link,
                    >a {
                        padding: .5rem 1rem;
                        @include flex;
                        align-items: center;
                        justify-content: space-between;
                        position: relative;

                        // &::after {
                        //     @include pseudo;
                        //     @include posi-horizontal;
                        //     height: 100%;
                        //     background: $color-pri;
                        //     width: 0%;
                        //     z-index: -1;
                        //     transition: all .4s ease-in-out;
                        // }
                        >i {
                            transform: rotate(-90deg) translate(5px, 0);
                            margin-top: 0.8rem;
                            font-size: 1rem;
                        }
                    }

                    >.menu-list {
                        left: 100%;
                    }
                }
            }
        }
    }
}

.mobile-nav {
    .menu {
        &-nav {
            >.menu-list {
                >.menu-item {

                    &.current_page_item,
                    &.current-menu-item,
                    &.current_page_parent,
                    &.current-menu-parent,
                    &.current-post-parent {
                        >a {
                            color: var(--text-hover);
                            // padding-left: 3rem;

                            &::after {
                                opacity: 1;
                            }
                        }
                    }

                    &:has(.current-menu-item) {
                        >a {
                            color: var(--text-hover);
                        }
                    }

                    >a {
                        padding: 1.2rem;
                        @include flex;
                        justify-content: space-between;
                        align-items: center;
                        color: #303030;
                        font-size: 1.4rem;
                        font-weight: 600;
                        border-bottom: 1px solid $color-border-01;
                        position: relative;

                        // &::after {
                        //     content: '';
                        //     position: absolute;
                        //     background-image: url(../assets/images/la-dc-1.svg);
                        //     width: 1.5rem;
                        //     height: 1.5rem;
                        //     background-repeat: no-repeat;
                        //     background-size: contain;
                        //     background-position: center;
                        //     left: 1rem;
                        //     top: 1.4rem;
                        //     transform: rotate(-107deg);
                        //     opacity: 0;
                        // }

                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }

        &-item {
            &.dropdown {
                >a {
                    >i {
                        display: flex;
                    }
                }
            }

            &.active {
                >a {
                    color: var(--text-hover) !important;

                    >i {
                        transform: rotate(180deg);
                    }
                }
            }

            a {
                position: relative;

                i {
                    width: 2rem;
                    height: 2rem;
                    @include mid-flex;
                    display: none;
                    @include transitionRe;
                }
            }

            >a {
                @include flex;
                align-items: center;
                justify-content: space-between;
                // padding: 1.2rem 1.2rem 1.2rem 2.4rem;
                padding: 1.2rem;
                font-size: 1.4rem;
                color: $color-text;
                font-weight: 500;
            }

            >.menu-list {
                display: none;
                margin-left: 1.6rem;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 2rem;
                    bottom: 2rem;
                    left: 0;
                    width: 1px;
                    background-color: $color-border-01;
                }

                >.menu-item {

                    &.current_page_item,
                    &.current-menu-item {
                        >a {
                            color: var(--text-hover);
                        }
                    }

                    &:has(.current-menu-item) {
                        >a {
                            color: var(--text-hover);
                        }
                    }
                }
            }
        }
    }
}

@keyframes menuLa {
    0% {
        clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
    }

    50% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }

    75% {
        transform: rotate(10deg);
    }

    100% {
        transform: rotate(0deg);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
}