.back-to-top {
    width: 5.6rem;
    height: 5.6rem;
    border-radius: 1.6rem;
    // border: 1px solid $color-pri;
    background-color: #94A3B8;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: fixed;
    bottom: 6rem;
    right: 14.5rem;
    z-index: 7;
    transform: translateX(3rem);
    opacity: 0;
    transition: 0.5s linear;
    cursor: pointer;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;

    @include max(768px) {
        right: 1.5rem;
    }

    &:hover {
        i {
            &:nth-child(1) {
                transform: translateY(-3rem) scale(0);
                opacity: 0;
            }

            &:nth-child(2) {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }

    i {
        color: $color-white;
        font-size: 1.8rem;
        position: absolute;
        transition: 0.4s ease-in-out;

        &:nth-child(2) {
            transform: translateY(3rem);
            opacity: 0;
        }
    }

    &.active {
        visibility: visible;
        transform: translateX(0);
        opacity: 1;
    }

    .triangle {
        display: block;
        width: 0;
        height: 0;
        z-index: 2;
        border: 8px solid transparent;
        border-bottom: 6px solid #fff;
        margin-top: -6px;
        border-radius: 5px;

        &:nth-child(1) {
            animation: BTT 1.5s linear 0.6s infinite;
        }

        &:nth-child(2) {
            animation: BTT 1.5s linear 0.3s infinite;
        }

        &:nth-child(3) {
            animation: BTT 1.5s linear 0s infinite;
        }
    }

    &:hover {
        @media only screen and (min-width: 901px) {
            visibility: visible;
            opacity: 1;
            transform: translateX(0);
            // background-color: $c-yel;
        }
    }
}