.btn {
    @include mid-flex;
    width: fit-content;
    // width: 20rem;
    // max-width: 100%;
    text-align: center;
    cursor: pointer;
    border-radius: 5rem;
    font-weight: 600;
    @include font-pri;
    @include transition(0.5s, ease);
    overflow: hidden;
    position: relative;
    background: transparent;
    gap: 1rem;
    color: $color-white;
    font-size: 1.8rem;
    height: 4.8rem;
    // border-radius: 100rem;
    background-color: var(--color-pri);
    border: 0.1rem solid var(--color-pri);

    >* {
        position: relative;
        z-index: 2;
    }

    &:after {
        position: absolute;
        content: "";
        width: 120%;
        height: 102%;
        top: 0;
        left: -13%;
        // background: rgba(0, 0, 0, 0.2);
        background: $color-white;
        transition: transform 0.6s;
        z-index: 0;
        transform-origin: right;
        transform: skew(30deg) scaleX(0);
    }

    .txt {
        position: relative;
        display: grid;
        place-items: center;
        height: 100%;
        padding: 1.4rem 2.5rem;
        transition: 0.4s;
        @include flex;
        justify-content: center;
        gap: 1rem;
        color: white;
        flex-shrink: 0;
    }

    img {
        width: 4rem;
        height: 4rem;
        filter: brightness(0) invert(1);
    }

    &:hover {
        .txt {
            color: var(--color-pri);
        }

        img {
            filter: brightness(0) saturate(100%) invert(35%) sepia(49%) saturate(6636%) hue-rotate(340deg) brightness(86%) contrast(121%);
        }

        &::after {
            transform: skew(30deg) scaleX(1);
            transform-origin: left;
        }

    }

    &.black {
        background-color: black;

        .txt {
            color: white
        }

        @include hover {
            .txt {
                color: $color-white
            }
        }
    }

    &.white {
        background-color: white;
        // box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.10);
        border: 0.1rem solid var(--color-pri);

        // @include max(1200px) {
        //     height: 3.8rem;
        // }

        .txt {
            color: var(--color-pri);
        }

        &::after {
            background: var(--color-pri);
        }

        @include hover {

            .txt {
                color: $color-white
            }
        }
    }

    &.bd-white {
        background-color: white;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.10);
        border: unset;

        .txt {
            color: var(--color-pri);
            flex-direction: row-reverse;

            img {
                filter: unset;
            }
        }

        &::after {
            background: var(--color-pri);
        }

        @include hover {

            .txt {
                color: $color-white;

                img {
                    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(10%) hue-rotate(238deg) brightness(104%) contrast(103%);
                }
            }
        }
    }

    &.second {
        background-color: var(--color-second);
        border: 0.1rem solid var(--color-second);

        .txt {
            color: white;
        }

        &::after {
            background-color: white;
        }

        @include hover {
            img {
                filter: none;
            }

            .txt {
                color: var(--color-second)
            }
        }
    }

    &.third {
        background-color: white;
        border: 0.1rem solid var(--color-second);

        .txt {
            color: var(--color-second);
        }

        &::after {
            background-color: var(--color-second);
        }

        img {
            filter: none;
        }

        @include hover {
            .txt {
                color: white
            }

            img {
                filter: brightness(0) invert(1);
            }
        }
    }

    &-gr {
        @include flex-wr;
        gap: 1rem;
    }

    &.center {
        margin-left: auto;
        margin-right: auto;
    }

    &.full {
        width: 100%;
    }

    &.btn-pri {
        padding: 0 1.2rem;

        // @include max(1200px) {
        //     height: 3.8rem;
        // }

        @include max(500px) {
            padding: 0;
        }

        .txt {
            @include max(1200px) {
                font-size: 1.4rem;
            }
        }
    }

    &.bg-trans {
        background-color: transparent;

        .txt {
            color: $color-pri;
        }

        &::after {
            background-color: $color-pri;
        }

        &:hover {
            .txt {
                color: $color-white;
            }
        }
    }

    &.arrow {
        .txt {
            padding-right: .4rem;

            gap: 2rem;
        }
    }

    &.w200 {
        width: 20rem;
        max-width: 100%;
    }
}

.seeDetail {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 600;
    transition: 0.4s;

    @include hover {
        color: var(--color-second);
    }
}

.link {
    display: block;
    color: $color-pri;
    width: 100%;
    font-weight: 700;
    @include fz-16;
    transition: .3s;

    &:hover {
        color: var(--color-second);
    }

    &.center {
        text-align: center;
    }
}