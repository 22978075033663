// text animation
.text-hori {
    .char {
        transition: 0.6s calc(var(--char-index) * 0.02s) ease-in-out;
        transform: translateX(4rem);
        opacity: 0;
    }

    &.is-inview {
        .char {
            transform: translateX(0);
            opacity: 1;
        }
    }
}

.text-verti {
    .char {
        transition: 0.6s calc(var(--char-index) * 0.02s) ease-in-out;
        transform: translateY(4rem);
        opacity: 0;
    }

    &.is-inview {
        .char {
            transform: translateY(0);
            opacity: 1;
        }
    }
}

.text-scale {
    .char {
        transition: 0.6s calc(var(--char-index) * 0.02s) ease-in-out;
        transform: scale(0);
        opacity: 0;
    }

    &.is-inview {
        .char {
            transform: scale(1);
            opacity: 1;
        }
    }
}

// count
.count {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0.1rem solid $color-border-01;
    border-radius: 0.8rem;
    width: fit-content;
    padding: 0.5rem;
    gap: 1rem;

    &-btn {
        width: 3rem;
        height: 3rem;
        @include mid-flex;
        cursor: pointer;
        border-radius: 0.4rem;
        transition: 0.4s;

        i {
            font-size: 1rem;
        }

        @include hover {
            background: var(--color-pri);
            color: white;
        }
    }
}

// mona-content
// .mona-content {
//     h3 {
//         @include fz-36;
//         font-weight: 600;
//     }

//     .note {
//         padding-left: 1rem;
//         border-left: 0.2rem solid var(--color-pri);

//         p {
//             color: var(--color-pri);
//             font-style: italic;
//             @include fz-14;
//         }
//     }

//     .quote {
//         padding-left: 3rem;
//         border-left: 0.2rem solid #F2794D;

//         p {
//             color: #F2794D;
//             font-style: italic;
//             @include fz-20;
//         }
//     }

//     blockquote {
//         margin: 0 !important;
//         padding-left: 3rem !important;
//         border-left: 0.2rem solid #F2794D !important;
//         background: transparent !important;

//         &::before {
//             content: none !important;
//         }

//         p {
//             color: #F2794D !important;
//             font-style: italic !important;
//             font-size: 2rem !important;
//         }
//     }

//     .wp-block-flex {
//         display: flex;
//         flex-wrap: wrap;
//         gap: 2.4rem;

//         @include max(650px) {
//             gap: 1.2rem;
//         }

//         .wp-block-image {
//             width: calc(100% / 3 - (2.4rem - (2.4rem / 3)));

//             @include max(650px) {
//                 width: calc(100% / 2 - (1.2rem - (1.2rem / 2)));
//             }
//         }
//     }

//     .wp-block-image {
//         border-radius: 1.6rem;

//         img {
//             width: 100%;
//             height: 100%;
//             object-fit: cover;
//         }
//     }
// }

// hover
.hover-rec {
    &::after {
        @include pseudo;
        @include full(0rem, 0rem, 0rem, 0rem);
        border: 0.1rem solid transparent;
        border-radius: inherit;
        transition: 0.2s;
        pointer-events: none;
    }

    @include hover {
        &::after {
            @include full(2%, 2%, 2%, 2%);
            border-color: var(--color-second);
        }
    }
}

// title
.title {
    .title-pri {
        color: var(--Primary, #D72429);
        @include fz-56;
        font-weight: 700;
        text-transform: capitalize;
        text-wrap: pretty;
        @include mb(4.8rem);

        &.white {
            color: white;
        }

        &.center {
            text-align: center;
        }
    }

    .title-second {
        color: var(--color-pri);
        @include fz-56;
        font-weight: 700;
        @include mb(2.4rem);
    }

    .title-sub {
        color: var(--Gray-700, #686060);
        text-align: center;
    }
}

// checker
.checker {
    cursor: pointer;
    display: flex;
    gap: 1rem;
    user-select: none;

    .sqr {
        width: 2.4rem;
        height: 2.4rem;
        flex-shrink: 0;
        position: relative;
        display: block;

        &::after {
            @include pseudo;
            width: 100%;
            height: 100%;
            background-image: url("../assets/images/check-empty.svg");
            @include default-background;
            left: 0;
            top: 0;
        }
    }

    .circle {
        width: 2.4rem;
        height: 2.4rem;
        flex-shrink: 0;
        position: relative;
        display: block;

        &::after {
            @include pseudo;
            width: 100%;
            height: 100%;
            background-image: url("../assets/images/radio-empty.svg");
            @include default-background;
            left: 0;
            top: 0;
        }
    }

    .txt {
        display: block;

        @include max(500px) {
            font-size: 1.4rem;
        }
    }

    input {
        display: none;
    }

    input:checked~.sqr {
        &::after {
            background-image: url("../assets/images/check-fill.svg");
        }
    }

    input:checked~.circle {
        &::after {
            background-image: url("../assets/images/radio-fill.svg");
        }
    }
}

.news-list {
    @include flex-list;
}

.news-item {
    @include flex-item;
    width: calc(100% / 3);

    &-wr {
        width: 100%;
        @include flex-col;
        justify-content: space-between;

        .image {
            @include mb(2.4rem);

            &-inner {
                border-radius: .8rem;
                @include imgPd(279, 438);

                img {
                    @include imgCover;
                    transition: .3s;
                }
            }
        }
    }

    &-content {
        .news-item-tt {
            color: var(--Gray-900, #131111);
            font-weight: 700;
            @include mb(1.2rem);
            @include lc(2);
            transition: .3s;
            text-wrap: pretty;

            &:hover {
                color: $color-pri;
            }
        }

        .author {
            @include mb(1.2rem);
        }

        .news-item-desc {
            @include mb(1.2rem);
            color: var(--Neutral-Gray-700, #334155);
            @include lc(3);
            text-wrap: pretty;
        }

    }

    &:hover {
        .image {
            img {
                transform: translate(-50%, -50%)scale(1.1);
            }
        }
    }

    @include max(768px) {
        width: 50%;
    }

    @include max(425px) {
        &-wr {
            .image {
                margin-bottom: .8rem;
            }
        }

        &-content {

            .news-item-tt,
            .author,
            .news-item-desc {
                margin-bottom: .4rem;
            }

            .news-item-tt,
            .link {
                @include fz-14;
            }

            .news-item-desc,
            .author {
                @include fz-12;

                &-txt {
                    @include fz-12;
                }
            }
        }
    }
}