.news {
    @include pb(10rem);

    @include max(600px) {}

    &-top {
        .news-item {
            width: 100%;
            padding: unset;
            margin-bottom: unset;

            &-wr {
                @include flex-list;
                flex-direction: row;

                .image {
                    @include flex-item;
                    width: calc(5 / 12 * 100%);

                    &-inner {
                        width: 100%;
                    }
                }

                .news-item-content {
                    @include flex-item;
                    flex-direction: column;
                    @include mid-flex;
                    align-items: flex-start;
                    width: calc(7 / 12 * 100%);
                    // margin-bottom: unset;

                    .news-item {

                        &-tt,
                        .author,
                        &-desc,
                        .link {
                            height: fit-content;
                        }
                    }
                }
            }
        }
    }
}