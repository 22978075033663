// sidefix bar
.sidefix {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    right: 14.5rem;
    bottom: 13.2rem;
    z-index: 5;
    gap: 2rem;

    @include max(768px) {
        right: 1.5rem;
    }

    &-item {
        position: relative;
        padding: 1.2rem;
        background-color: var(--color-pri);
        border-radius: 1.6rem;
        @include mid-flex;
        cursor: pointer;
    }


    &-link {
        width: 3.2rem;
        height: 3.2rem;
        @include mid-flex;

        img {
            width: inherit;
            height: inherit;
            animation: jittery 2.5s linear infinite;
        }
    }
}

.sideright {
    border: 1px solid #E2E8F0;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    right: 0rem;
    bottom: 6rem;
    z-index: 5;
    background: $color-white;
    border-radius: 1.6rem 0rem 0rem 1.6rem;



    //animation for item
    &-item {
        &:nth-child(1) {
            .sideright-link {
                img {
                    animation: phoneRing 2.5s linear infinite;
                }
            }
        }

        &:nth-child(2) {
            .sideright-link {
                img {
                    animation: note 2.5s linear infinite;
                }
            }
        }

        &:nth-child(3) {
            .sideright-link {
                img {
                    animation: DownUp 2.5s linear infinite;
                }
            }
        }
    }

    &-item {
        // padding: 1.2rem;
        width: 10.6rem;
        height: 10.6rem;
        position: relative;
        // background-color: var(--color-pri);
        // border-radius: 1.6rem;
        @include mid-flex;
        cursor: pointer;

        &:not(:first-child) {
            border-top: 1px solid #E2E8F0;
        }


        &:hover {
            .sideright-link {
                .text {
                    color: $color-pri;
                }

                img {
                    scale: 1.1;
                }
            }
        }
    }

    &-link {
        width: 2.4rem;
        height: 2.4rem;
        @include mid-flex;
        flex-direction: column;
        gap: .4rem;
        transition: .3s;

        img {
            width: inherit;
            height: inherit;
            // animation: phoneRing 2.5s linear infinite;
            transition: .3s;
        }

        .text {
            text-wrap: nowrap;
            color: var(--Neutral-Gray-900, #0F172A);
            text-align: center;
            font-family: "Roboto";
            @include fz-16;
            font-weight: 500;
            text-transform: uppercase;
            transition: .3s;

        }
    }

    @include max (768px) {
        bottom: 22rem;

        &-item {
            width: 7rem;
            height: 7rem;
        }

        &-link {
            width: 2rem;
            height: 2rem;

            .text {
                font-size: 1rem;
            }
        }
    }
}