.contact {
    &-wr {
        box-shadow: 0rem 0.9rem 1.8rem 0rem rgba(0, 0, 0, 0.15);
    }

    .contact-rt {
        margin-bottom: unset;
        @include mid-flex;
    }

    .contact-lf {
        background: $color-pri;
        margin-bottom: unset;

        .wrapper {
            padding: 4.8rem 0;
            width: calc(390 / 438 * 100%);
            max-width: 100%;
            margin: 0 auto;

            @include max(800px) {
                padding: 2.4rem 0;
            }

            .logo {
                width: 30rem;
                max-width: 100%;
                margin: auto;
                @include mt(2.4rem);
                @include mb(3.2rem);

                .cuscom-logo-link {
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }

            .social {
                &-list {
                    gap: 1.6rem;
                    justify-content: center;
                }

                &-link {
                    width: 4.8rem;
                    height: 4.8rem;

                    &:nth-child(4) {
                        width: 9.4rem;
                        height: 4.8rem;
                    }

                    @include max(800px) {
                        width: 3.8rem;
                        height: 3.8rem;

                        &:nth-child(4) {
                            width: 8.4rem;
                            height: 4.8rem;
                        }
                    }
                }
            }
        }
    }

    @include max(768px) {
        &-wr {
            flex-direction: column;
        }

        &-lf,
        &-rt {
            width: 100%;
        }
    }

    @include max(425px) {
        .ic-text .ic-text-item .text {
            @include fz-14;
        }
    }

    @include max(320px) {

        .wrapper,
        .form-contact {
            width: 100% !important;
        }
    }
}

.ic-text {
    &-wr {}

    .ic-text-item {
        &:last-child {
            @include mb(3.2rem);
        }

        @include mb(1.6rem);
        display: flex;
        gap: 1.2rem;

        .ic {
            flex-shrink: 0;
            width: 2.4rem;
            height: 2.4rem;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .text {
            // flex: 1;
            color: $color-white;
            @include font-second;
            transition: .3s;
            text-wrap: pretty;

            &:hover {
                color: var(--color-hover);
            }
        }
    }
}

.form-contact {
    padding: 4rem 0;

    @include max(800px) {
        padding: 2rem 0;
    }

    // width: 74.8rem;
    width: calc(748 / 940 * 100%);
    max-width: 100%;

    .title {
        @include mb(2.4rem);

        &-second {
            color: var(--Neutral-Gray-900, #0F172A);
            @include fz-36;
            font-weight: 700;
            text-align: center;
            margin-bottom: .4rem;
        }

        &-sub {
            color: var(--Neutral-Gray-600, #475569);
            text-align: center;
            @include fz-18;
        }
    }
}