.page-numbers {
    @include flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 5;
    margin-top: 1.6rem;

    >li {
        &:not(:first-child) {
            margin-left: 0.8rem;
        }

        &>.page-numbers {
            // border: 0.1rem solid var(--color-pri);
            width: 3.6rem;
            height: 3.6rem;
            border-radius: 100rem;
            @include mid-flex;
            background-color: transparent;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            color: #4C4B50;
            font-weight: 700;
            @include fz-16;
            @include affter(var(--color-pri));
            transition: .3s;

            &:after {
                z-index: -1;
            }

            @include hover {
                &:not(.dot) {
                    color: white;

                    &::after {
                        left: 0;
                        width: 100%;
                    }
                }
            }

            &.current {
                color: white;

                &::after {
                    left: 0;
                    width: 100%;
                }
            }

            &.dot {
                background-color: transparent;
            }

            i {
                font-weight: 400;
            }
        }
    }
}