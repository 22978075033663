.author-share {
    @include mb(2.6rem);

    .as-flex {
        @include flex-wr;
        gap: 1.6rem;
        justify-content: space-between;
    }

}

.author {
    color: var(--Neutral-Gray-600, #475569);

    &-txt {
        @include fz-16;
        font-weight: 700;
    }

    &-space,
    &-date {
        font-weight: 400;
    }
}

.share {
    &-wr {
        @include flex-wr;
        gap: 1.6rem;
    }
}

.news-dt {
    .title {
        &-pri {
            @include mb(2rem);
        }
    }

    .image {
        @include mb(4.8rem);

        &-inner {
            @include imgPd(633, 1378);

            img {
                border-radius: .8rem;
                @include imgCover;
            }
        }
    }
}

.news-related {
    .container {
        // margin: auto -1.6rem;
    }

    &-wr {
        position: relative;
    }

    .swiper {
        margin: 0 -1.6rem;
    }

    .swiper-slide {
        width: calc(100% / 3);
    }

    .news-item {
        width: 100%;
        // padding: unset;
    }
}