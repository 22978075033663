.ft {
    width: 100%;
    position: relative;
    background: var(--color-pri);
    overflow: hidden;

    &-flex {
        // @include mb(-4.8rem);

        @include max(900px) {
            margin-bottom: unset;

            .ft-item {
                .tt {
                    margin-top: unset;
                }

                &:nth-child(2) {
                    order: 0;
                    width: 100%;

                    @include max(600px) {
                        margin-bottom: 2rem;
                    }
                }

                &:nth-child(1),
                &:nth-child(3) {
                    order: 1;
                    width: 50%;

                    @include max(600px) {
                        width: 100%;

                        .wrapper {
                            width: 100%;
                        }
                    }

                    .wrapper {
                        width: 100%;
                        text-align: left;
                    }
                }

                &:nth-child(1) {
                    @include max(600px) {
                        margin-bottom: 2rem;
                    }
                }

                &:nth-child(3) {
                    @include max(600px) {

                        .name-txt,
                        .val {
                            @include fz-14;
                        }
                    }
                }
            }

        }
    }

    .tt {
        @include mt(5.9rem);
        color: var(--color-white);
        @include fz-20;
        font-weight: 700;
        @include mb(1.6rem);
        text-transform: uppercase;
    }

    &-wr {

        width: 100%;
        height: 100%;
        margin: 0 auto;
        padding: 0 7.2rem;

        @include max(1200px) {
            padding: 0 1.5rem;
        }

    }

    &-logo {
        width: fit-content;
        margin: auto;
        @include mt(4.8rem);
        @include mb(2.4rem);

        .custom-logo-link {
            width: 35rem;
            max-width: 100%;
        }

        @include max(425px) {
            width: 80%;
            @include mid-flex;
        }
    }

    &-item {
        width: calc(100% / 3);

        &:nth-child(1) {
            .wrapper {
                width: 27.3rem;
                max-width: 100%;
                margin-right: auto;
            }
        }

        &:nth-child(3) {
            .wrapper {
                width: 46.8rem;
                max-width: 100%;
                margin-left: auto;
                text-align: right;
            }
        }
    }

    // menu
    .menu {
        &-list {
            @include flex-list;
        }

        &-item {
            @include flex-item;
            width: 50%;
            @include mb(2rem);
        }

        &-link {
            color: var(--color-white);
            @include fz-18;
            font-weight: 700;
            transition: .3s;

            &:hover {
                color: var(--color-hover);
            }
        }
    }

    &-social {
        display: flex;
        gap: 2rem;

        .txt {
            color: white;
            font-weight: 500;
        }

        .social {
            width: fit-content;
            margin: 0 auto;
            display: flex;
            align-items: center;
            gap: 2rem;
            @include mt(1.6rem);
            @include mb(2.4rem);

            &-link {
                width: 4.8rem;
                height: 4.8rem;

                img {
                    width: inherit;
                    height: inherit;
                }

                @include max(800px) {
                    width: 3.8rem;
                    height: 3.8rem;
                }
            }
        }
    }

    &-hotline {
        @include mt(1.6rem);

        .text {
            color: $color-white;
            text-align: center;

            &.bold {
                font-weight: 700;

            }

            .val {
                text-wrap: pretty;
                transition: .3s;

                &:hover {
                    color: var(--color-hover);
                }
            }
        }
    }

    &-content {
        &:last-child {
            @include mt(1.6rem);
        }

        .name {
            margin-bottom: .4rem;

            &-txt {
                color: var(--color-white);
                font-weight: 700;
            }
        }

        .val {
            color: var(--color-white);
            @include fz-16;
            font-style: normal;
            font-weight: 400;
        }
    }

    &-company {
        color: var(--color-white);
        text-align: center;
        @include fz-20;
        font-weight: 700;
        text-wrap: pretty;
    }

    &-line {
        border-top: 1px solid rgba(255, 255, 255, .3);
        height: 100%;
    }

    &-copyright {
        padding: 1.4rem 0;
        @include flex-wr;
        justify-content: space-between;
        gap: 1.6rem;

        @include max(735px) {
            justify-content: center;
        }

        @include max(675px) {
            gap: .6rem;
        }

        .text {
            color: var(--color-white);
            @include fz-14;
            text-wrap: pretty;
            @include mid-flex;

            @include max(675px) {
                text-align: center;
                gap: .6rem;
            }
        }
    }

    &-signature {
        &-wr {
            @include flex-wr;
            align-items: center;
            gap: 1.1rem;
            justify-content: flex-end;

            .text {
                color: var(--color-white);
            }

            .image {
                height: 2.4rem;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}