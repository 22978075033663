.t-title {
    @include fz-44;
    font-weight: 700;
    color: $color-pri;

    @include max(600px) {
        font-size: 2.2rem;
    }
}

.title-sub {
    font-weight: 700;
    color: $color-third;

    &.dc {
        --w: 1.9rem;
        display: inline-block;
        padding-right: calc(var(--w) + .4rem);
        position: relative;

        &::after {
            @include pseudo;
            background-image: url(../assets/images/la-dc-1.svg);
            width: var(--w);
            height: var(--w);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            right: 0;
            top: -.4rem;
            clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
        }

        &.is-inview {
            &::after {
                animation: laRight 1s ease-in-out .3s forwards;
            }
        }
    }
}

.t-title-second {
    @include fz-32;
    font-weight: 700;
    color: $color-pri;
}

@keyframes laRight {
    0% {
        clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
    }

    50% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }

    75% {
        transform: rotate(10deg);
    }

    100% {
        transform: rotate(0deg);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
}

.t-center {
    text-align: center;
}

.fw-1 {
    font-weight: 100;
}

.fw-2 {
    font-weight: 200;
}

.fw-3 {
    font-weight: 300;
}

.fw-5 {
    font-weight: 500;
}

.fw-6 {
    font-weight: 600;
}

.fw-7 {
    font-weight: 700;
}

.fw-8 {
    font-weight: 800;
}

.fw-b {
    font-weight: bold;
}

.fsi {
    font-style: italic;
}

.t-up {
    text-transform: uppercase;
}

.t9 {
    @include fz-9
}

.t11 {
    @include fz-11
}

.t12 {
    @include fz-12
}

.t14 {
    @include fz-14
}

.t16 {
    @include fz-16
}

.t18 {
    @include fz-18
}

.t20 {
    @include fz-20
}

.t24 {
    @include fz-24
}

.t32 {
    @include fz-32
}

.t40 {
    @include fz-40
}

.t48 {
    @include fz-48
}

.t56 {
    @include fz-56
}

.t64 {
    @include fz-64
}

.t72 {
    @include fz-72
}