// --------------------------------------
.mobile {
    position: fixed;
    z-index: 140;
    top: var(--size-hd);
    left: 0;
    bottom: 0;
    min-width: 50rem;
    width: 50rem;
    background-color: white;
    box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.05);
    @include transition(0.4s, linear);
    transform: translateX(-110%);
    @include default-background;
    min-width: 30rem;

    @include max($mobile) {
        width: 30rem;
        // min-width: initial;
    }

    @include max(800px) {}

    &.sticky {
        top: var(--size-hd);
    }

    &.open {
        transform: translateX(0);
    }

    @include min($res-mobile-min) {
        display: none;
    }

    &-con {
        width: 100%;
        height: 100%;
        @include flex;
        flex-direction: column;
        overflow-y: auto;
    }

    &-heading {
        font-size: 1.8rem;
        line-height: 1.5;
        color: $color-pri;
        font-weight: 700;
        margin-bottom: 2rem;
        text-transform: uppercase;
    }

    &-overlay {
        @include full-fixed;
        z-index: 100;
        background-color: rgba(0, 0, 0, 0.8);
        @include transition(0.6s, ease);
        opacity: 0;
        visibility: hidden;
        transform: translateX(-110%);
        border-top: 1px solid $color-border-01;
        top: var(--size-hd);

        &.sticky {
            top: var(--size-hd);
        }

        &.open {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
        }

        @include min($res-mobile-min) {
            display: none;
        }
    }

    &-wr {
        padding-top: 2rem;
        position: relative;
    }

    &-list {
        // margin-bottom: 2rem;
    }

    &-content {
        padding: 2.4rem 1.2rem;
        margin-top: auto;

        .titles {
            color: var(--color-pri);
            font-weight: 700;
            @include fz-14;
            margin-bottom: 1rem;
            padding: 0;

            &:not(:first-child) {
                margin-top: 2rem;
            }
        }

        &-bl {
            margin-bottom: .6rem;

            .name {
                @include flex;

                .ic {
                    width: 1.4rem;
                    height: 1.4rem;
                    @include mid-flex;
                    margin-right: 0.8rem;
                    flex-shrink: 0;
                    margin-top: 0.4rem;
                    border-radius: 100rem;

                    img {
                        width: inherit;
                        height: inherit;
                    }
                }

                &-txt {
                    display: block;

                    a {
                        word-break: break-word;
                    }
                }
            }

            .val {
                font-size: 1.4rem;
            }
        }

        .social {
            display: flex;
            gap: 1rem;

            &-link {
                width: 3rem;
                height: 3rem;
                border-radius: 100rem;
                // background: var(--color-pri);
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 3rem;
                    height: 3rem;
                    border-radius: 0;
                    filter: brightness(0) saturate(100%) invert(19%) sepia(100%) saturate(2373%) hue-rotate(343deg) brightness(95%) contrast(93%);
                }
            }
        }
    }

    &-title {
        font-size: 1.6rem;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 1.6rem;
    }

    &-mxh {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4rem;
        @include flex;
        align-items: center;
        border-top: 1px solid $color-border-01;

        &-link {
            @include mid-flex;
            flex: 1;
            height: 100%;
            background-color: white;
            cursor: pointer;

            &:not(:first-child) {
                border-left: 1px solid $color-border-01;
            }

            img {
                width: 2.4rem;
                height: 2.4rem;
            }
        }
    }

    &-text {
        @include fz-14;
        display: block;
        color: $color-pri;
        word-break: break-word;
        font-weight: 500;

        &:not(:last-child) {
            margin-bottom: 0.8rem;
        }
    }

    &-account {
        position: absolute;
        top: 1rem;
        left: 1rem;
    }

    &-re {
        @include flex;
        align-items: center;

        &-link {
            @include fz-16;
            color: $color-pri;
            text-decoration: underline;

            &:not(:first-child) {
                margin-left: 16px;
            }
        }
    }

    &-close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        width: 3.5rem;
        height: 3.5rem;
        border: 1px solid $color-pri;
        @include mid-flex;
        cursor: pointer;
        background-color: white;

        &:hover .icon {
            transform: rotate(360deg);
        }

        .icon {
            transition: 0.6s ease-in-out;
            font-weight: 500;
            font-size: 1.8rem;
            color: $color-pri;
        }
    }

    &-fl {
        .fw-6 {
            margin-bottom: 0.8rem;
            margin-bottom: 0.8rem;
            display: inline;
            width: fit-content;
            font-size: 1.4rem;
        }

        .mobile-text {
            display: inline;
            font-size: 1.4rem;
        }

        .dot {
            display: none;
        }
    }

    &-nav {
        @include max(1200px) {
            .menu-list>.menu-item.active>a {
                color: var(--color-text) !important;
            }

            .menu-nav>.menu-list>.menu-item.current-menu-item>a {
                color: var(--color-pri);
            }
        }
    }
}