.hd {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.4s;
    z-index: 50;
    height: var(--size-hd);
    background: $color-pri;
    // box-shadow: var(--sdow);

    &.sticky {
        @include min(1201px) {
            --size-hd: 6rem;
            // background: white;

            .hd-logo {
                .custom-logo-link {
                    width: 15rem;
                    @include imgPd(60, 88);

                    img {
                        @include imgContain;
                    }
                }
            }

            .hd-nav .menu-nav>.menu-list>.menu-item::before {
                bottom: 1.6rem;
            }
        }
    }

    &-wr {
        height: 100%;
    }

    &-flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        max-width: 123rem;
        margin: auto;
        position: relative;
        padding: 0 1.5rem;
    }

    &-nav {
        position: relative;
        z-index: 2;
        height: 100%;

        @include max(1200px) {
            display: none;
        }

        .menu-nav {
            height: 100%;

            .menu-list {
                // justify-content: space-between;
            }
        }
    }

    &-logo {
        .custom-logo-link {
            display: flex;
            @include transitionRe;
            width: 30rem;

            @include max(1200px) {
                width: 15rem;
            }

            img {
                max-width: 100%;
                height: auto;
            }
        }
    }

    &-act {
        --gap: 3.4rem;
        display: flex;
        align-items: center;
        gap: var(--gap);
        height: 100%;
    }

    &-lang {
        position: relative;

        &:hover {
            >.hd-lang-list {
                transform: translateY(0);
                opacity: 1;
                visibility: visible;
            }
        }

        &-main {
            @include flex;
            align-items: center;

            img {
                margin-right: 0.4rem;
            }

            .arrow {
                width: 1.6rem;
                height: 1.6rem;
                @include mid-flex;
                margin-left: 0.2rem;

                i {
                    font-size: 1.4rem;
                    color: var(--color-white);
                }
            }
        }

        &-txt {
            transition: 0.4s;
            @include fz-14;
            font-weight: 500;
            color: var(--color-white);
        }

        &-list {
            position: absolute;
            @include flex;
            flex-direction: column;
            width: 100%;
            top: calc(100% + 0.5rem);
            transition: 0.4s;
            transform: translateY(1rem);
            opacity: 0;
            visibility: hidden;
        }

        &-item {
            @include flex;
            align-items: center;
            background: var(--color-pri);
            padding: 0.5rem;
            border-radius: 0.5rem;
            box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
            transition: 0.4s;

            @include hover {
                background-color: rgba(255, 255, 255, 0.30);

                .hd-lang-txt {
                    color: $color-white;
                }
            }

            img {
                margin-right: 0.4rem;
            }
        }
    }

    .burger {
        width: 4rem;
        height: 4rem;
        background: var(--color-pri);
        border-radius: 0.4rem;
        margin-left: 1rem;

        @include max(1200px) {
            // margin-right: 2.62rem;
            order: -1;
            margin-left: 0;
        }

        @include min($res-mobile-min) {
            display: none;
        }
    }
}