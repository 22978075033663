@import url("../assets/font/Mulish/stylesheet.css");
@import url("../assets/font/Inter/stylesheet.css");
@import url("../assets/font/Roboto/stylesheet.css");

* {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;

    @media screen and (min-width: 2000px) {
        font-size: 100%;
    }
}

body {
    font-family: 'Mulish';
    font-size: 18px;
    line-height: 1.5;
    font-weight: 400;
    color: $color-text;
    overflow: hidden auto;

    @media screen and (min-width: 2000px) {
        font-size: 20px;
    }
}

a {
    text-decoration: none;
    color: inherit;
}

button,
select {
    border: none;
    outline: none;
    cursor: pointer;
    -webkit-appearance: none;
    cursor: pointer;
    @include font-pri;
}

button {
    padding: 0;
    background-color: transparent;
}

input,
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    @include font-pri;
}

.main {
    min-height: 68vh;
    padding-top: var(--size-hd);
}

.container {
    width: 100%;
    max-width: 140.8rem;
    height: 100%;
    margin: 0 auto;
    padding: 0 1.5rem;

    @include max(1560px) {
        max-width: 90%;
    }

    @include max(1200px) {
        max-width: 100%;
    }
}

.over {
    overflow-x: auto;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
        display: none;
    }
}

.no-scroll {
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    overscroll-behavior: none;
}

iframe {
    vertical-align: middle;
}

.load-item {
    display: none;
}

.show-hide-toggle {
    cursor: pointer;
}

img {
    max-width: 100%;
    border-radius: inherit;
    height: auto;
}

.global-title {
    max-width: 0;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
}