.breadcrumb {
    // padding: 1.2rem 0;
    @include pt(4rem);
    @include pb(3.2rem);


    &-wrapper {
        @include min($res-mobile) {
            width: fit-content;
        }
    }

    &-list {
        @include flex;
        align-items: center;
        flex-wrap: wrap;
        overflow: hidden;
        gap: .8rem;
    }

    &-item {
        @include font-second;
        @include fz-20;
        display: flex;
        align-items: center;
        gap: .8rem;
        font-weight: 400;
        color: var(--Gray-500);
        text-wrap: pretty;

        &:not(:first-child) {
            &::before {
                display: inline-block;
                content: '\f105';
                font-family: "Font Awesome 6 Pro";
                // @include fz-18;
                border-radius: 100rem;
                vertical-align: middle;
            }
        }

        &:last-child {
            color: var(--Gray-500);
            pointer-events: none;
        }

        &:hover {
            text-decoration: underline;

            &:not(:first-child) {
                &::before {
                    text-decoration: underline;
                }
            }
        }
    }

    &-link {
        color: inherit;
        transition: .2s ease-in-out;
    }
}