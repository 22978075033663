@charset "UTF-8";
@import url("../assets/library/fontawesome/css/all.css");
@import url("../assets/library/fontawesome/fontawesome/fontawesome.css");
@import url("../assets/library/aos/aos.css");
@import url("../assets/library/swiper/swiper-bundle.min.css");
@import url("../assets/library/themify-icons/themify-icons.css");
@import url("../assets/library/select2/select2.min.css");
@import url("../assets/library/themifi/themify-icons.css");
@import url("../assets/library/gallery/lightgallery.min.css");
@import url("../assets/library/splitting/splitting.css");
@import url("../assets/library/fancybox/fancybox.css");
@import url("../assets/library/datetime/daterangepicker.css");
@import url("../assets/font/Mulish/stylesheet.css");
@import url("../assets/font/Inter/stylesheet.css");
@import url("../assets/font/Roboto/stylesheet.css");
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.mona-content strong,
.mona-content b {
  font-weight: bold; }

.mona-content em,
.mona-content i {
  font-style: italic; }

.mona-content h1,
.mona-content h2,
.mona-content h3,
.mona-content h4,
.mona-content h5,
.mona-content h6 {
  line-height: 1.3;
  margin-bottom: 0.5em;
  margin-top: 0.6em; }

.mona-content h1 {
  font-size: 1.7em; }

.mona-content h2 {
  font-size: 1.6em; }

.mona-content h3 {
  font-size: 1.25em; }

.mona-content h4 {
  font-size: 1.125em; }

.mona-content h5 {
  font-size: 1em; }

.mona-content h6 {
  font-size: 0.85em; }

.mona-content p {
  margin: 1em 0; }

.mona-content ul,
.mona-content ol {
  margin: 1em 0;
  list-style-position: inside; }

.mona-content ul ul,
.mona-content ul ol,
.mona-content ol ul,
.mona-content ol ol {
  margin-left: 1em; }

.mona-content ul {
  list-style-type: disc; }

.mona-content ol {
  list-style-type: decimal; }

.mona-content ul ul,
.mona-content ol ul {
  list-style-type: circle; }

.mona-content ol ol,
.mona-content ul ol {
  list-style-type: lower-latin; }

.mona-content img,
.mona-content iframe {
  max-width: 100% !important;
  height: auto; }

.mona-content blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1em 0;
  padding: 1em;
  quotes: "“" "”" "‘" "’"; }
  .mona-content blockquote::before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em; }
  .mona-content blockquote p {
    display: inline; }

.mona-content table {
  border-collapse: collapse;
  max-width: 100%;
  margin: 1em 0;
  border: 1px solid #e1e1e1; }
  .mona-content table th,
  .mona-content table td {
    border-right: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    padding: 5px 10px;
    vertical-align: middle; }

.mona-content .mona-youtube-wrap {
  position: relative;
  height: 0;
  padding-top: 56.25%; }
  .mona-content .mona-youtube-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.mona-content .mona-table-wrap {
  overflow: auto; }

:root {
  --size-hd: 11.8rem;
  --pd-sc: 8rem;
  --transition: 0.6s calc(var(--char-index) * 0.02s) ease-in-out;
  --pBot: calc((298/1728*100%));
  --recontainer: calc(100vw - (100vw - 120rem)/2);
  --half: calc((100vw - 120rem)/2);
  --color-text: #0F172A;
  --color-pri: #D72429;
  --color-second: #FF8387;
  --color-white: #FFF;
  --color-hover: #FF8387;
  --Gray-500: #ACA1A1;
  --Gray-900: #131111;
  --Primary: #D72429;
  --sdow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  --linear-pastel: linear-gradient(30deg, #FFE7E7, #FFC6C6);
  --linear-blue:linear-gradient(135deg, #F5F8FF 0%, #E8F0FF 100%);
  --filter:brightness(0) saturate(100%) invert(70%) sepia(9%) saturate(938%) hue-rotate(178deg) brightness(95%) contrast(90%); }
  @media (max-width: 1200px) {
    :root {
      --pd-sc: 4rem;
      --radius: .8rem;
      --size-hd: 6rem; } }

* {
  box-sizing: border-box; }

html {
  font-size: 62.5%;
  scroll-behavior: smooth; }
  @media screen and (min-width: 2000px) {
    html {
      font-size: 100%; } }

body {
  font-family: 'Mulish';
  font-size: 18px;
  line-height: 1.5;
  font-weight: 400;
  color: #686060;
  overflow: hidden auto; }
  @media screen and (min-width: 2000px) {
    body {
      font-size: 20px; } }

a {
  text-decoration: none;
  color: inherit; }

button,
select {
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  cursor: pointer;
  font-family: "Mulish", sans-serif; }

button {
  padding: 0;
  background-color: transparent; }

input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: "Mulish", sans-serif; }

.main {
  min-height: 68vh;
  padding-top: var(--size-hd); }

.container {
  width: 100%;
  max-width: 140.8rem;
  height: 100%;
  margin: 0 auto;
  padding: 0 1.5rem; }
  @media screen and (max-width: 1560px) {
    .container {
      max-width: 90%; } }
  @media screen and (max-width: 1200px) {
    .container {
      max-width: 100%; } }

.over {
  overflow-x: auto;
  scroll-snap-type: x mandatory; }
  .over::-webkit-scrollbar {
    display: none; }

.no-scroll {
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  overscroll-behavior: none; }

iframe {
  vertical-align: middle; }

.load-item {
  display: none; }

.show-hide-toggle {
  cursor: pointer; }

img {
  max-width: 100%;
  border-radius: inherit;
  height: auto; }

.global-title {
  max-width: 0;
  max-height: 0;
  opacity: 0;
  visibility: hidden; }

.row {
  --cg: 2.4rem;
  --rg: 2.4rem;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--cg) / 2 * -1) calc(var(--rg) * -1); }

.rows {
  --cg: 2.4rem;
  --rg: 2.4rem;
  margin: 0 calc(var(--cg) / 2 * -1); }

.col {
  width: 100%;
  padding: 0 calc(var(--cg) / 2);
  margin-bottom: var(--rg); }
  .col-11 {
    width: calc(11/12 * 100%); }
  .col-10 {
    width: calc(10/12 * 100%); }
  .col-9 {
    width: calc(9/12 * 100%); }
  .col-8 {
    width: calc(8/12 * 100%); }
  .col-7 {
    width: calc(7/12 * 100%); }
  .col-6 {
    width: 50%; }
  .col-5 {
    width: calc(5/12 * 100%); }
  .col-4 {
    width: calc(4/12 * 100%); }
  .col-3 {
    width: calc(3/12 * 100%); }
  .col-2 {
    width: calc(2/12 * 100%); }
  .col-1 {
    width: calc(1/12 * 100%); }
  .col-5i {
    width: calc(100% / 5); }
  .col-4i {
    width: calc(100% / 4); }

@-webkit-keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  50% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

@-moz-keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  50% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

@-ms-keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  50% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

@-o-keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  50% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

@keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  50% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-ms-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-o-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes shine {
  100% {
    left: 125%; } }

@-moz-keyframes shine {
  100% {
    left: 125%; } }

@-ms-keyframes shine {
  100% {
    left: 125%; } }

@-o-keyframes shine {
  100% {
    left: 125%; } }

@keyframes shine {
  100% {
    left: 125%; } }

@-webkit-keyframes ZoomIn {
  0% {
    transform: scale(0.95);
    opacity: 0; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@-moz-keyframes ZoomIn {
  0% {
    transform: scale(0.95);
    opacity: 0; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@-ms-keyframes ZoomIn {
  0% {
    transform: scale(0.95);
    opacity: 0; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@-o-keyframes ZoomIn {
  0% {
    transform: scale(0.95);
    opacity: 0; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes ZoomIn {
  0% {
    transform: scale(0.95);
    opacity: 0; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@-webkit-keyframes Spinner {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@-moz-keyframes Spinner {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@-ms-keyframes Spinner {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@-o-keyframes Spinner {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@keyframes Spinner {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes BTT {
  0% {
    opacity: 0; }
  33% {
    opacity: 1; }
  66% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-moz-keyframes BTT {
  0% {
    opacity: 0; }
  33% {
    opacity: 1; }
  66% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-ms-keyframes BTT {
  0% {
    opacity: 0; }
  33% {
    opacity: 1; }
  66% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-o-keyframes BTT {
  0% {
    opacity: 0; }
  33% {
    opacity: 1; }
  66% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes BTT {
  0% {
    opacity: 0; }
  33% {
    opacity: 1; }
  66% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@-moz-keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@-ms-keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@-o-keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes halo {
  from {
    transform: translate(-50%, -50%) scale(0); }
  to {
    transform: translate(-50%, -50%) scale(1); } }

@-moz-keyframes halo {
  from {
    transform: translate(-50%, -50%) scale(0); }
  to {
    transform: translate(-50%, -50%) scale(1); } }

@-ms-keyframes halo {
  from {
    transform: translate(-50%, -50%) scale(0); }
  to {
    transform: translate(-50%, -50%) scale(1); } }

@-o-keyframes halo {
  from {
    transform: translate(-50%, -50%) scale(0); }
  to {
    transform: translate(-50%, -50%) scale(1); } }

@keyframes halo {
  from {
    transform: translate(-50%, -50%) scale(0); }
  to {
    transform: translate(-50%, -50%) scale(1); } }

@-webkit-keyframes float {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-20px); }
  100% {
    transform: translatey(0px); } }

@-moz-keyframes float {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-20px); }
  100% {
    transform: translatey(0px); } }

@-ms-keyframes float {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-20px); }
  100% {
    transform: translatey(0px); } }

@-o-keyframes float {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-20px); }
  100% {
    transform: translatey(0px); } }

@keyframes float {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-20px); }
  100% {
    transform: translatey(0px); } }

@-webkit-keyframes mirror {
  100% {
    transform: translate3d(2000%, 0, 0) rotate(35deg); } }

@-moz-keyframes mirror {
  100% {
    transform: translate3d(2000%, 0, 0) rotate(35deg); } }

@-ms-keyframes mirror {
  100% {
    transform: translate3d(2000%, 0, 0) rotate(35deg); } }

@-o-keyframes mirror {
  100% {
    transform: translate3d(2000%, 0, 0) rotate(35deg); } }

@keyframes mirror {
  100% {
    transform: translate3d(2000%, 0, 0) rotate(35deg); } }

@-webkit-keyframes DownUp {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-10px); }
  100% {
    transform: translatey(0px); } }

@-moz-keyframes DownUp {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-10px); }
  100% {
    transform: translatey(0px); } }

@-ms-keyframes DownUp {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-10px); }
  100% {
    transform: translatey(0px); } }

@-o-keyframes DownUp {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-10px); }
  100% {
    transform: translatey(0px); } }

@keyframes DownUp {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-10px); }
  100% {
    transform: translatey(0px); } }

@-webkit-keyframes jelly {
  25% {
    transform: scale(0.9, 1.1); }
  50% {
    transform: scale(1.1, 0.9); }
  75% {
    transform: scale(0.95, 1.05); } }

@-moz-keyframes jelly {
  25% {
    transform: scale(0.9, 1.1); }
  50% {
    transform: scale(1.1, 0.9); }
  75% {
    transform: scale(0.95, 1.05); } }

@-ms-keyframes jelly {
  25% {
    transform: scale(0.9, 1.1); }
  50% {
    transform: scale(1.1, 0.9); }
  75% {
    transform: scale(0.95, 1.05); } }

@-o-keyframes jelly {
  25% {
    transform: scale(0.9, 1.1); }
  50% {
    transform: scale(1.1, 0.9); }
  75% {
    transform: scale(0.95, 1.05); } }

@keyframes jelly {
  25% {
    transform: scale(0.9, 1.1); }
  50% {
    transform: scale(1.1, 0.9); }
  75% {
    transform: scale(0.95, 1.05); } }

@-webkit-keyframes jellyTranForm {
  25% {
    transform: translate(-50%, -50%) scale(0.9, 1.1); }
  50% {
    transform: translate(-50%, -50%) scale(1.1, 0.9); }
  75% {
    transform: translate(-50%, -50%) scale(0.95, 1.05); } }

@-moz-keyframes jellyTranForm {
  25% {
    transform: translate(-50%, -50%) scale(0.9, 1.1); }
  50% {
    transform: translate(-50%, -50%) scale(1.1, 0.9); }
  75% {
    transform: translate(-50%, -50%) scale(0.95, 1.05); } }

@-ms-keyframes jellyTranForm {
  25% {
    transform: translate(-50%, -50%) scale(0.9, 1.1); }
  50% {
    transform: translate(-50%, -50%) scale(1.1, 0.9); }
  75% {
    transform: translate(-50%, -50%) scale(0.95, 1.05); } }

@-o-keyframes jellyTranForm {
  25% {
    transform: translate(-50%, -50%) scale(0.9, 1.1); }
  50% {
    transform: translate(-50%, -50%) scale(1.1, 0.9); }
  75% {
    transform: translate(-50%, -50%) scale(0.95, 1.05); } }

@keyframes jellyTranForm {
  25% {
    transform: translate(-50%, -50%) scale(0.9, 1.1); }
  50% {
    transform: translate(-50%, -50%) scale(1.1, 0.9); }
  75% {
    transform: translate(-50%, -50%) scale(0.95, 1.05); } }

@-webkit-keyframes jittery {
  5%,
  50% {
    transform: scale(1); }
  10% {
    transform: scale(0.9); }
  15% {
    transform: scale(1.05); }
  20% {
    transform: scale(1.05) rotate(-5deg); }
  25% {
    transform: scale(1.05) rotate(5deg); }
  30% {
    transform: scale(1.05) rotate(-3deg); }
  35% {
    transform: scale(1.05) rotate(2deg); }
  40% {
    transform: scale(1.05) rotate(0); } }

@-moz-keyframes jittery {
  5%,
  50% {
    transform: scale(1); }
  10% {
    transform: scale(0.9); }
  15% {
    transform: scale(1.05); }
  20% {
    transform: scale(1.05) rotate(-5deg); }
  25% {
    transform: scale(1.05) rotate(5deg); }
  30% {
    transform: scale(1.05) rotate(-3deg); }
  35% {
    transform: scale(1.05) rotate(2deg); }
  40% {
    transform: scale(1.05) rotate(0); } }

@-ms-keyframes jittery {
  5%,
  50% {
    transform: scale(1); }
  10% {
    transform: scale(0.9); }
  15% {
    transform: scale(1.05); }
  20% {
    transform: scale(1.05) rotate(-5deg); }
  25% {
    transform: scale(1.05) rotate(5deg); }
  30% {
    transform: scale(1.05) rotate(-3deg); }
  35% {
    transform: scale(1.05) rotate(2deg); }
  40% {
    transform: scale(1.05) rotate(0); } }

@-o-keyframes jittery {
  5%,
  50% {
    transform: scale(1); }
  10% {
    transform: scale(0.9); }
  15% {
    transform: scale(1.05); }
  20% {
    transform: scale(1.05) rotate(-5deg); }
  25% {
    transform: scale(1.05) rotate(5deg); }
  30% {
    transform: scale(1.05) rotate(-3deg); }
  35% {
    transform: scale(1.05) rotate(2deg); }
  40% {
    transform: scale(1.05) rotate(0); } }

@keyframes jittery {
  5%,
  50% {
    transform: scale(1); }
  10% {
    transform: scale(0.9); }
  15% {
    transform: scale(1.05); }
  20% {
    transform: scale(1.05) rotate(-5deg); }
  25% {
    transform: scale(1.05) rotate(5deg); }
  30% {
    transform: scale(1.05) rotate(-3deg); }
  35% {
    transform: scale(1.05) rotate(2deg); }
  40% {
    transform: scale(1.05) rotate(0); } }

@-webkit-keyframes pulse {
  0% {
    transform: scale(1); }
  70% {
    transform: scale(0.9); }
  100% {
    transform: scale(1); } }

@-moz-keyframes pulse {
  0% {
    transform: scale(1); }
  70% {
    transform: scale(0.9); }
  100% {
    transform: scale(1); } }

@-ms-keyframes pulse {
  0% {
    transform: scale(1); }
  70% {
    transform: scale(0.9); }
  100% {
    transform: scale(1); } }

@-o-keyframes pulse {
  0% {
    transform: scale(1); }
  70% {
    transform: scale(0.9); }
  100% {
    transform: scale(1); } }

@keyframes pulse {
  0% {
    transform: scale(1); }
  70% {
    transform: scale(0.9); }
  100% {
    transform: scale(1); } }

@-webkit-keyframes grow {
  from {
    transform: scale(0, 0);
    opacity: 1; }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0; } }

@-moz-keyframes grow {
  from {
    transform: scale(0, 0);
    opacity: 1; }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0; } }

@-ms-keyframes grow {
  from {
    transform: scale(0, 0);
    opacity: 1; }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0; } }

@-o-keyframes grow {
  from {
    transform: scale(0, 0);
    opacity: 1; }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0; } }

@keyframes grow {
  from {
    transform: scale(0, 0);
    opacity: 1; }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0; } }

@-webkit-keyframes grows {
  from {
    transform: scale(0.9, 0.9);
    opacity: 1; }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0; } }

@-moz-keyframes grows {
  from {
    transform: scale(0.9, 0.9);
    opacity: 1; }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0; } }

@-ms-keyframes grows {
  from {
    transform: scale(0.9, 0.9);
    opacity: 1; }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0; } }

@-o-keyframes grows {
  from {
    transform: scale(0.9, 0.9);
    opacity: 1; }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0; } }

@keyframes grows {
  from {
    transform: scale(0.9, 0.9);
    opacity: 1; }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0; } }

@-webkit-keyframes txt-focus {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0; }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1; } }

@-moz-keyframes txt-focus {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0; }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1; } }

@-ms-keyframes txt-focus {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0; }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1; } }

@-o-keyframes txt-focus {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0; }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1; } }

@keyframes txt-focus {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0; }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1; } }

@-webkit-keyframes MaskPlay {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0; }
  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0; } }

@-moz-keyframes MaskPlay {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0; }
  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0; } }

@-ms-keyframes MaskPlay {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0; }
  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0; } }

@-o-keyframes MaskPlay {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0; }
  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0; } }

@keyframes MaskPlay {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0; }
  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0; } }

@-webkit-keyframes Leaf {
  0% {
    transform: translateY(-100%) rotate(0deg);
    opacity: 1; }
  100% {
    transform: translateY(30rem) rotate(360deg);
    opacity: 0; } }

@-moz-keyframes Leaf {
  0% {
    transform: translateY(-100%) rotate(0deg);
    opacity: 1; }
  100% {
    transform: translateY(30rem) rotate(360deg);
    opacity: 0; } }

@-ms-keyframes Leaf {
  0% {
    transform: translateY(-100%) rotate(0deg);
    opacity: 1; }
  100% {
    transform: translateY(30rem) rotate(360deg);
    opacity: 0; } }

@-o-keyframes Leaf {
  0% {
    transform: translateY(-100%) rotate(0deg);
    opacity: 1; }
  100% {
    transform: translateY(30rem) rotate(360deg);
    opacity: 0; } }

@keyframes Leaf {
  0% {
    transform: translateY(-100%) rotate(0deg);
    opacity: 1; }
  100% {
    transform: translateY(30rem) rotate(360deg);
    opacity: 0; } }

@-webkit-keyframes Leaf2 {
  0% {
    top: 0;
    transform: rotate(0deg);
    opacity: 1; }
  100% {
    top: 100%;
    transform: rotate(360deg);
    opacity: 0; } }

@-moz-keyframes Leaf2 {
  0% {
    top: 0;
    transform: rotate(0deg);
    opacity: 1; }
  100% {
    top: 100%;
    transform: rotate(360deg);
    opacity: 0; } }

@-ms-keyframes Leaf2 {
  0% {
    top: 0;
    transform: rotate(0deg);
    opacity: 1; }
  100% {
    top: 100%;
    transform: rotate(360deg);
    opacity: 0; } }

@-o-keyframes Leaf2 {
  0% {
    top: 0;
    transform: rotate(0deg);
    opacity: 1; }
  100% {
    top: 100%;
    transform: rotate(360deg);
    opacity: 0; } }

@keyframes Leaf2 {
  0% {
    top: 0;
    transform: rotate(0deg);
    opacity: 1; }
  100% {
    top: 100%;
    transform: rotate(360deg);
    opacity: 0; } }

@-webkit-keyframes Leaf3 {
  0% {
    top: 0;
    transform: rotate(0deg);
    opacity: 1; }
  80% {
    top: 100%;
    transform: rotate(360deg) translateY(-100%);
    opacity: 1; }
  90% {
    top: 100%;
    transform: rotate(370deg) translateY(-100%);
    opacity: 1; }
  100% {
    top: 100%;
    transform: rotate(380deg) translateY(-100%);
    opacity: 1; } }

@-moz-keyframes Leaf3 {
  0% {
    top: 0;
    transform: rotate(0deg);
    opacity: 1; }
  80% {
    top: 100%;
    transform: rotate(360deg) translateY(-100%);
    opacity: 1; }
  90% {
    top: 100%;
    transform: rotate(370deg) translateY(-100%);
    opacity: 1; }
  100% {
    top: 100%;
    transform: rotate(380deg) translateY(-100%);
    opacity: 1; } }

@-ms-keyframes Leaf3 {
  0% {
    top: 0;
    transform: rotate(0deg);
    opacity: 1; }
  80% {
    top: 100%;
    transform: rotate(360deg) translateY(-100%);
    opacity: 1; }
  90% {
    top: 100%;
    transform: rotate(370deg) translateY(-100%);
    opacity: 1; }
  100% {
    top: 100%;
    transform: rotate(380deg) translateY(-100%);
    opacity: 1; } }

@-o-keyframes Leaf3 {
  0% {
    top: 0;
    transform: rotate(0deg);
    opacity: 1; }
  80% {
    top: 100%;
    transform: rotate(360deg) translateY(-100%);
    opacity: 1; }
  90% {
    top: 100%;
    transform: rotate(370deg) translateY(-100%);
    opacity: 1; }
  100% {
    top: 100%;
    transform: rotate(380deg) translateY(-100%);
    opacity: 1; } }

@keyframes Leaf3 {
  0% {
    top: 0;
    transform: rotate(0deg);
    opacity: 1; }
  80% {
    top: 100%;
    transform: rotate(360deg) translateY(-100%);
    opacity: 1; }
  90% {
    top: 100%;
    transform: rotate(370deg) translateY(-100%);
    opacity: 1; }
  100% {
    top: 100%;
    transform: rotate(380deg) translateY(-100%);
    opacity: 1; } }

@keyframes animateSvg {
  0% {
    stroke-dashoffset: 2689.942138671875px;
    stroke-dasharray: 2689.942138671875px; }
  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 2689.942138671875px; } }

@keyframes note {
  0% {
    transform-origin: top center;
    transform: rotate(0deg); }
  20% {
    transform: rotate(-5deg); }
  40% {
    transform: rotate(3deg); }
  60% {
    transform: rotate(-2deg); }
  80% {
    transform: rotate(1deg); }
  100% {
    transform: rotate(0deg); } }

@keyframes star {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes rotate45 {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(180deg); } }

@keyframes rotate360 {
  0% {
    transform: translate(-50%, -50%) rotate(0); }
  100% {
    transform: translate(-50%, -50%) rotate(360deg); } }

.back-to-top {
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 1.6rem;
  background-color: #94A3B8;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: fixed;
  bottom: 6rem;
  right: 14.5rem;
  z-index: 7;
  transform: translateX(3rem);
  opacity: 0;
  transition: 0.5s linear;
  cursor: pointer;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden; }
  @media screen and (max-width: 768px) {
    .back-to-top {
      right: 1.5rem; } }
  .back-to-top:hover i:nth-child(1) {
    transform: translateY(-3rem) scale(0);
    opacity: 0; }
  .back-to-top:hover i:nth-child(2) {
    transform: translateY(0);
    opacity: 1; }
  .back-to-top i {
    color: #ffffff;
    font-size: 1.8rem;
    position: absolute;
    transition: 0.4s ease-in-out; }
    .back-to-top i:nth-child(2) {
      transform: translateY(3rem);
      opacity: 0; }
  .back-to-top.active {
    visibility: visible;
    transform: translateX(0);
    opacity: 1; }
  .back-to-top .triangle {
    display: block;
    width: 0;
    height: 0;
    z-index: 2;
    border: 8px solid transparent;
    border-bottom: 6px solid #fff;
    margin-top: -6px;
    border-radius: 5px; }
    .back-to-top .triangle:nth-child(1) {
      animation: BTT 1.5s linear 0.6s infinite; }
    .back-to-top .triangle:nth-child(2) {
      animation: BTT 1.5s linear 0.3s infinite; }
    .back-to-top .triangle:nth-child(3) {
      animation: BTT 1.5s linear 0s infinite; }
  @media only screen and (min-width: 901px) {
    .back-to-top:hover {
      visibility: visible;
      opacity: 1;
      transform: translateX(0); } }

[data-aos="fade-up-cus"] {
  transform: translate(0, 4rem);
  opacity: 0;
  transition-property: transform, opacity; }
  [data-aos="fade-up-cus"].aos-animate {
    transform: translate(0, 0);
    opacity: 1; }

[data-aos="fade-down-cus"] {
  transform: translate(0, -4rem);
  opacity: 0;
  transition-property: transform, opacity; }
  [data-aos="fade-down-cus"].aos-animate {
    transform: translate(0, 0);
    opacity: 1; }

[data-aos="fade-left-cus"] {
  transform: translate(4rem, 0);
  opacity: 0;
  transition-property: transform, opacity; }
  [data-aos="fade-left-cus"].aos-animate {
    transform: translate(0, 0);
    opacity: 1; }

[data-aos="fade-right-cus"] {
  transform: translate(-4rem, 0);
  opacity: 0;
  transition-property: transform, opacity; }
  [data-aos="fade-right-cus"].aos-animate {
    transform: translate(0, 0);
    opacity: 1; }

:root {
  --text: var(--color-white);
  --text-hover: var(--color-white);
  --fw: 400;
  --back: var(--color-pri);
  --textsub: var(--color-white);
  --fz: 1.6rem; }
  @media screen and (max-width: 1470px) {
    :root {
      --fz: 1.6rem; } }

.hd-nav {
  height: 100%; }
  .hd-nav .menu-nav {
    height: 100%; }
    .hd-nav .menu-nav > .menu-list {
      height: 100%;
      gap: 2.4rem; }
      .hd-nav .menu-nav > .menu-list > .menu-item {
        height: 100%;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        align-items: center; }
        .hd-nav .menu-nav > .menu-list > .menu-item:last-child > .menu-list {
          left: initial;
          right: 0; }
        .hd-nav .menu-nav > .menu-list > .menu-item::before {
          content: "";
          position: absolute;
          bottom: 4.6rem;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          -webkit-transform: translateX(-50%);
          -moz-transform: translateX(-50%);
          -o-transform: translateX(-50%);
          height: 0.1rem;
          background: var(--color-white);
          width: 0;
          transition: 0.3s ease-in-out;
          -webkit-transition: 0.3s ease-in-out;
          -moz-transition: 0.3s ease-in-out;
          -ms-transition: 0.3s ease-in-out;
          -o-transition: 0.3s ease-in-out; }
        .hd-nav .menu-nav > .menu-list > .menu-item:hover:not(.dropdown)::before, .hd-nav .menu-nav > .menu-list > .menu-item:has(.current-menu-item)::before {
          width: 100%; }
        .hd-nav .menu-nav > .menu-list > .menu-item:hover:not(.dropdown)::after, .hd-nav .menu-nav > .menu-list > .menu-item:has(.current-menu-item)::after {
          animation: menuLa 1s ease-in-out 0.5s forwards; }
        .hd-nav .menu-nav > .menu-list > .menu-item.dropdown:hover::before {
          width: 100%; }
        .hd-nav .menu-nav > .menu-list > .menu-item:hover > a {
          color: var(--text-hover); }
          .hd-nav .menu-nav > .menu-list > .menu-item:hover > a i {
            transform: rotate(180deg); }
        .hd-nav .menu-nav > .menu-list > .menu-item > .menu-link {
          height: 100%;
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flex;
          display: -o-flex;
          display: flex;
          align-items: center;
          color: var(--text); }
        .hd-nav .menu-nav > .menu-list > .menu-item.current_page_item > a, .hd-nav .menu-nav > .menu-list > .menu-item.current-menu-item > a, .hd-nav .menu-nav > .menu-list > .menu-item.current_page_parent > a, .hd-nav .menu-nav > .menu-list > .menu-item.current-menu-parent > a, .hd-nav .menu-nav > .menu-list > .menu-item.current-post-parent > a {
          color: var(--text-hover); }
        .hd-nav .menu-nav > .menu-list > .menu-item.current_page_item::before, .hd-nav .menu-nav > .menu-list > .menu-item.current-menu-item::before, .hd-nav .menu-nav > .menu-list > .menu-item.current_page_parent::before, .hd-nav .menu-nav > .menu-list > .menu-item.current-menu-parent::before, .hd-nav .menu-nav > .menu-list > .menu-item.current-post-parent::before {
          width: 100%; }
        .hd-nav .menu-nav > .menu-list > .menu-item.current_page_item::after, .hd-nav .menu-nav > .menu-list > .menu-item.current-menu-item::after, .hd-nav .menu-nav > .menu-list > .menu-item.current_page_parent::after, .hd-nav .menu-nav > .menu-list > .menu-item.current-menu-parent::after, .hd-nav .menu-nav > .menu-list > .menu-item.current-post-parent::after {
          animation: menuLa 1s ease-in-out 0.5s forwards; }
        .hd-nav .menu-nav > .menu-list > .menu-item > .menu-list {
          top: calc(100% + 0.7rem); }
          .hd-nav .menu-nav > .menu-list > .menu-item > .menu-list::after {
            content: '';
            position: absolute;
            bottom: 100%;
            left: 0;
            height: 0.7rem;
            width: 100%; }
  .hd-nav .menu-list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center; }
  .hd-nav .menu-item {
    position: relative;
    transition: 0.2s ease-in-out;
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -ms-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition-delay: 0s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -ms-transition-delay: 0s;
    -o-transition-delay: 0s; }
    .hd-nav .menu-item:hover > .menu-list {
      opacity: 1;
      visibility: visible;
      pointer-events: visible;
      transform: translate(0, 0); }
      .hd-nav .menu-item:hover > .menu-list > .menu-item.current_page_item > a, .hd-nav .menu-item:hover > .menu-list > .menu-item.current-menu-item > a {
        color: var(--text-hover); }
        .hd-nav .menu-item:hover > .menu-list > .menu-item.current_page_item > a::before, .hd-nav .menu-item:hover > .menu-list > .menu-item.current-menu-item > a::before {
          width: 100%; }
      .hd-nav .menu-item:hover > .menu-list > .menu-item:hover {
        background: rgba(255, 255, 255, 0.3); }
    .hd-nav .menu-item .menu-link,
    .hd-nav .menu-item a {
      transition: 0.2s ease-in-out;
      -webkit-transition: 0.2s ease-in-out;
      -moz-transition: 0.2s ease-in-out;
      -ms-transition: 0.2s ease-in-out;
      -o-transition: 0.2s ease-in-out;
      transition-delay: 0s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -ms-transition-delay: 0s;
      -o-transition-delay: 0s; }
      .hd-nav .menu-item .menu-link i,
      .hd-nav .menu-item a i {
        transition: 0.2s ease-in-out;
        -webkit-transition: 0.2s ease-in-out;
        -moz-transition: 0.2s ease-in-out;
        -ms-transition: 0.2s ease-in-out;
        -o-transition: 0.2s ease-in-out;
        transition-delay: 0s;
        -webkit-transition-delay: 0s;
        -moz-transition-delay: 0s;
        -ms-transition-delay: 0s;
        -o-transition-delay: 0s; }
    .hd-nav .menu-item > .menu-link,
    .hd-nav .menu-item > a {
      color: var(--textsub);
      font-weight: var(--fw);
      display: block;
      font-size: var(--fz); }
      .hd-nav .menu-item > .menu-link > i,
      .hd-nav .menu-item > a > i {
        display: none;
        font-weight: 600;
        margin-left: 0.4rem;
        font-size: 1.4rem;
        color: inherit; }
    .hd-nav .menu-item.dropdown > a > i {
      display: inline-block; }
    .hd-nav .menu-item > .menu-list {
      position: absolute;
      top: 0;
      left: 0;
      min-width: 25rem;
      max-width: 28rem;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background: var(--back);
      box-shadow: var(--sdow);
      border-radius: .4rem;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;
      opacity: 0;
      visibility: hidden;
      transform: translate(0, -5px);
      pointer-events: none; }
      .hd-nav .menu-item > .menu-list > .menu-item {
        margin-left: 0;
        width: 100%; }
        .hd-nav .menu-item > .menu-list > .menu-item:hover::after {
          opacity: 1; }
        .hd-nav .menu-item > .menu-list > .menu-item:hover > .menu-link,
        .hd-nav .menu-item > .menu-list > .menu-item:hover > a {
          color: var(--text-hover); }
        .hd-nav .menu-item > .menu-list > .menu-item > .menu-link,
        .hd-nav .menu-item > .menu-list > .menu-item > a {
          padding: .5rem 1rem;
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flex;
          display: -o-flex;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative; }
          .hd-nav .menu-item > .menu-list > .menu-item > .menu-link > i,
          .hd-nav .menu-item > .menu-list > .menu-item > a > i {
            transform: rotate(-90deg) translate(5px, 0);
            margin-top: 0.8rem;
            font-size: 1rem; }
        .hd-nav .menu-item > .menu-list > .menu-item > .menu-list {
          left: 100%; }

.mobile-nav .menu-nav > .menu-list > .menu-item.current_page_item > a, .mobile-nav .menu-nav > .menu-list > .menu-item.current-menu-item > a, .mobile-nav .menu-nav > .menu-list > .menu-item.current_page_parent > a, .mobile-nav .menu-nav > .menu-list > .menu-item.current-menu-parent > a, .mobile-nav .menu-nav > .menu-list > .menu-item.current-post-parent > a {
  color: var(--text-hover); }
  .mobile-nav .menu-nav > .menu-list > .menu-item.current_page_item > a::after, .mobile-nav .menu-nav > .menu-list > .menu-item.current-menu-item > a::after, .mobile-nav .menu-nav > .menu-list > .menu-item.current_page_parent > a::after, .mobile-nav .menu-nav > .menu-list > .menu-item.current-menu-parent > a::after, .mobile-nav .menu-nav > .menu-list > .menu-item.current-post-parent > a::after {
    opacity: 1; }

.mobile-nav .menu-nav > .menu-list > .menu-item:has(.current-menu-item) > a {
  color: var(--text-hover); }

.mobile-nav .menu-nav > .menu-list > .menu-item > a {
  padding: 1.2rem;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #303030;
  font-size: 1.4rem;
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: relative; }
  .mobile-nav .menu-nav > .menu-list > .menu-item > a::before {
    display: none; }

.mobile-nav .menu-item.dropdown > a > i {
  display: flex; }

.mobile-nav .menu-item.active > a {
  color: var(--text-hover) !important; }
  .mobile-nav .menu-item.active > a > i {
    transform: rotate(180deg); }

.mobile-nav .menu-item a {
  position: relative; }
  .mobile-nav .menu-item a i {
    width: 2rem;
    height: 2rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    display: none;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out; }

.mobile-nav .menu-item > a {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem;
  font-size: 1.4rem;
  color: #686060;
  font-weight: 500; }

.mobile-nav .menu-item > .menu-list {
  display: none;
  margin-left: 1.6rem;
  position: relative; }
  .mobile-nav .menu-item > .menu-list::before {
    content: '';
    position: absolute;
    top: 2rem;
    bottom: 2rem;
    left: 0;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.1); }
  .mobile-nav .menu-item > .menu-list > .menu-item.current_page_item > a, .mobile-nav .menu-item > .menu-list > .menu-item.current-menu-item > a {
    color: var(--text-hover); }
  .mobile-nav .menu-item > .menu-list > .menu-item:has(.current-menu-item) > a {
    color: var(--text-hover); }

@keyframes menuLa {
  0% {
    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%); }
  50% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); }
  75% {
    transform: rotate(10deg); }
  100% {
    transform: rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); } }

::-webkit-scrollbar {
  width: 0.5rem; }

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-pri);
  border-radius: 100rem; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #D72429; }

.hd {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: 0.4s;
  z-index: 50;
  height: var(--size-hd);
  background: #D72429; }
  @media screen and (min-width: 1201px) {
    .hd.sticky {
      --size-hd: 6rem; }
      .hd.sticky .hd-logo .custom-logo-link {
        width: 15rem;
        display: block;
        padding-top: calc((60 / 88) * 100%);
        position: relative;
        overflow: hidden; }
        .hd.sticky .hd-logo .custom-logo-link img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: contain; }
      .hd.sticky .hd-nav .menu-nav > .menu-list > .menu-item::before {
        bottom: 1.6rem; } }
  .hd-wr {
    height: 100%; }
  .hd-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    max-width: 123rem;
    margin: auto;
    position: relative;
    padding: 0 1.5rem; }
  .hd-nav {
    position: relative;
    z-index: 2;
    height: 100%; }
    @media screen and (max-width: 1200px) {
      .hd-nav {
        display: none; } }
    .hd-nav .menu-nav {
      height: 100%; }
  .hd-logo .custom-logo-link {
    display: flex;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    width: 30rem; }
    @media screen and (max-width: 1200px) {
      .hd-logo .custom-logo-link {
        width: 15rem; } }
    .hd-logo .custom-logo-link img {
      max-width: 100%;
      height: auto; }
  .hd-act {
    --gap: 3.4rem;
    display: flex;
    align-items: center;
    gap: var(--gap);
    height: 100%; }
  .hd-lang {
    position: relative; }
    .hd-lang:hover > .hd-lang-list {
      transform: translateY(0);
      opacity: 1;
      visibility: visible; }
    .hd-lang-main {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      align-items: center; }
      .hd-lang-main img {
        margin-right: 0.4rem; }
      .hd-lang-main .arrow {
        width: 1.6rem;
        height: 1.6rem;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        -ms-align-items: center;
        align-items: center;
        -webkit-align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        margin-left: 0.2rem; }
        .hd-lang-main .arrow i {
          font-size: 1.4rem;
          color: var(--color-white); }
    .hd-lang-txt {
      transition: 0.4s;
      font-size: 1.4rem;
      font-weight: 500;
      color: var(--color-white); }
    .hd-lang-list {
      position: absolute;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;
      width: 100%;
      top: calc(100% + 0.5rem);
      transition: 0.4s;
      transform: translateY(1rem);
      opacity: 0;
      visibility: hidden; }
    .hd-lang-item {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      align-items: center;
      background: var(--color-pri);
      padding: 0.5rem;
      border-radius: 0.5rem;
      box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
      transition: 0.4s; }
      @media (hover: hover) {
        .hd-lang-item:hover {
          background-color: rgba(255, 255, 255, 0.3); }
          .hd-lang-item:hover .hd-lang-txt {
            color: #ffffff; } }
      .hd-lang-item img {
        margin-right: 0.4rem; }
  .hd .burger {
    width: 4rem;
    height: 4rem;
    background: var(--color-pri);
    border-radius: 0.4rem;
    margin-left: 1rem; }
    @media screen and (max-width: 1200px) {
      .hd .burger {
        order: -1;
        margin-left: 0; } }
    @media screen and (min-width: 1201px) {
      .hd .burger {
        display: none; } }

.ft {
  width: 100%;
  position: relative;
  background: var(--color-pri);
  overflow: hidden; }
  @media screen and (max-width: 900px) {
    .ft-flex {
      margin-bottom: unset; }
      .ft-flex .ft-item .tt {
        margin-top: unset; }
      .ft-flex .ft-item:nth-child(2) {
        order: 0;
        width: 100%; } }
    @media screen and (max-width: 900px) and (max-width: 600px) {
      .ft-flex .ft-item:nth-child(2) {
        margin-bottom: 2rem; } }
  @media screen and (max-width: 900px) {
      .ft-flex .ft-item:nth-child(1), .ft-flex .ft-item:nth-child(3) {
        order: 1;
        width: 50%; } }
    @media screen and (max-width: 900px) and (max-width: 600px) {
      .ft-flex .ft-item:nth-child(1), .ft-flex .ft-item:nth-child(3) {
        width: 100%; }
        .ft-flex .ft-item:nth-child(1) .wrapper, .ft-flex .ft-item:nth-child(3) .wrapper {
          width: 100%; } }
  @media screen and (max-width: 900px) {
        .ft-flex .ft-item:nth-child(1) .wrapper, .ft-flex .ft-item:nth-child(3) .wrapper {
          width: 100%;
          text-align: left; } }
  @media screen and (max-width: 900px) and (max-width: 600px) {
    .ft-flex .ft-item:nth-child(1) {
      margin-bottom: 2rem; } }
  @media screen and (max-width: 900px) and (max-width: 600px) {
    .ft-flex .ft-item:nth-child(3) .name-txt,
    .ft-flex .ft-item:nth-child(3) .val {
      font-size: 1.4rem; } }
  .ft .tt {
    margin-top: 5.9rem;
    color: var(--color-white);
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1.6rem;
    text-transform: uppercase; }
    @media screen and (max-width: 800px) {
      .ft .tt {
        margin-top: calc(5.9rem / 2); } }
    @media only screen and (max-width: 1200px) {
      .ft .tt {
        font-size: 1.8rem; } }
    @media only screen and (max-width: 800px) {
      .ft .tt {
        font-size: 1.6rem; } }
    @media screen and (max-width: 800px) {
      .ft .tt {
        margin-bottom: calc(1.6rem / 2); } }
  .ft-wr {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 7.2rem; }
    @media screen and (max-width: 1200px) {
      .ft-wr {
        padding: 0 1.5rem; } }
  .ft-logo {
    width: fit-content;
    margin: auto;
    margin-top: 4.8rem;
    margin-bottom: 2.4rem; }
    @media screen and (max-width: 800px) {
      .ft-logo {
        margin-top: calc(4.8rem / 2); } }
    @media screen and (max-width: 800px) {
      .ft-logo {
        margin-bottom: calc(2.4rem / 2); } }
    .ft-logo .custom-logo-link {
      width: 35rem;
      max-width: 100%; }
    @media screen and (max-width: 425px) {
      .ft-logo {
        width: 80%;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        -ms-align-items: center;
        align-items: center;
        -webkit-align-items: center;
        justify-content: center;
        -webkit-justify-content: center; } }
  .ft-item {
    width: calc(100% / 3); }
    .ft-item:nth-child(1) .wrapper {
      width: 27.3rem;
      max-width: 100%;
      margin-right: auto; }
    .ft-item:nth-child(3) .wrapper {
      width: 46.8rem;
      max-width: 100%;
      margin-left: auto;
      text-align: right; }
  .ft .menu-list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.2rem;
    margin-bottom: -2.4rem; }
    @media screen and (max-width: 500px) {
      .ft .menu-list {
        margin: 0 -0.4rem;
        margin-bottom: -0.8rem; } }
  .ft .menu-item {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    padding: 0 1.2rem;
    margin-bottom: 2.4rem;
    width: 50%;
    margin-bottom: 2rem; }
    @media screen and (max-width: 500px) {
      .ft .menu-item {
        padding: 0 0.4rem;
        margin-bottom: 0.8rem; } }
    @media screen and (max-width: 800px) {
      .ft .menu-item {
        margin-bottom: calc(2rem / 2); } }
  .ft .menu-link {
    color: var(--color-white);
    font-size: 1.8rem;
    font-weight: 700;
    transition: .3s; }
    @media only screen and (max-width: 1200px) {
      .ft .menu-link {
        font-size: 1.6rem; } }
    @media only screen and (max-width: 800px) {
      .ft .menu-link {
        font-size: 1.5rem; } }
    .ft .menu-link:hover {
      color: var(--color-hover); }
  .ft-social {
    display: flex;
    gap: 2rem; }
    .ft-social .txt {
      color: white;
      font-weight: 500; }
    .ft-social .social {
      width: fit-content;
      margin: 0 auto;
      display: flex;
      align-items: center;
      gap: 2rem;
      margin-top: 1.6rem;
      margin-bottom: 2.4rem; }
      @media screen and (max-width: 800px) {
        .ft-social .social {
          margin-top: calc(1.6rem / 2); } }
      @media screen and (max-width: 800px) {
        .ft-social .social {
          margin-bottom: calc(2.4rem / 2); } }
      .ft-social .social-link {
        width: 4.8rem;
        height: 4.8rem; }
        .ft-social .social-link img {
          width: inherit;
          height: inherit; }
        @media screen and (max-width: 800px) {
          .ft-social .social-link {
            width: 3.8rem;
            height: 3.8rem; } }
  .ft-hotline {
    margin-top: 1.6rem; }
    @media screen and (max-width: 800px) {
      .ft-hotline {
        margin-top: calc(1.6rem / 2); } }
    .ft-hotline .text {
      color: #ffffff;
      text-align: center; }
      .ft-hotline .text.bold {
        font-weight: 700; }
      .ft-hotline .text .val {
        text-wrap: pretty;
        transition: .3s; }
        .ft-hotline .text .val:hover {
          color: var(--color-hover); }
  .ft-content:last-child {
    margin-top: 1.6rem; }
    @media screen and (max-width: 800px) {
      .ft-content:last-child {
        margin-top: calc(1.6rem / 2); } }
  .ft-content .name {
    margin-bottom: .4rem; }
    .ft-content .name-txt {
      color: var(--color-white);
      font-weight: 700; }
  .ft-content .val {
    color: var(--color-white);
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400; }
  .ft-company {
    color: var(--color-white);
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    text-wrap: pretty; }
    @media only screen and (max-width: 1200px) {
      .ft-company {
        font-size: 1.8rem; } }
    @media only screen and (max-width: 800px) {
      .ft-company {
        font-size: 1.6rem; } }
  .ft-line {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    height: 100%; }
  .ft-copyright {
    padding: 1.4rem 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1.6rem; }
    @media screen and (max-width: 735px) {
      .ft-copyright {
        justify-content: center; } }
    @media screen and (max-width: 675px) {
      .ft-copyright {
        gap: .6rem; } }
    .ft-copyright .text {
      color: var(--color-white);
      font-size: 1.4rem;
      text-wrap: pretty;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center; }
      @media screen and (max-width: 675px) {
        .ft-copyright .text {
          text-align: center;
          gap: .6rem; } }
  .ft-signature-wr {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1.1rem;
    justify-content: flex-end; }
    .ft-signature-wr .text {
      color: var(--color-white); }
    .ft-signature-wr .image {
      height: 2.4rem; }
      .ft-signature-wr .image img {
        height: 100%;
        width: 100%;
        object-fit: contain; }

.hd-burger {
  padding: 1rem 0;
  cursor: pointer; }
  .hd-burger.active .line:nth-child(1) {
    -webkit-transform: translate3d(0, 0.3rem, 0) rotate(45deg) !important;
    transform: translate3d(0, 0.3rem, 0) rotate(45deg) !important; }
  .hd-burger.active .line:nth-child(2) {
    -webkit-transform: translate3d(0, -0.4rem, 0) rotate(135deg) !important;
    transform: translate3d(0, -0.4rem, 0) rotate(135deg) !important;
    width: 2.4rem; }

.burger {
  position: relative; }

.hamburger .line {
  width: 2.4rem;
  height: 0.2rem;
  border-radius: .4rem;
  background: #F36F3F;
  display: block;
  margin: 0.5rem 0;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }
  .hamburger .line:nth-child(2) {
    width: 1.8rem;
    margin-left: auto; }

.hamburger.active .ham {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg); }
  .hamburger.active .ham .top {
    stroke-dashoffset: -98px; }
  .hamburger.active .ham .bottom {
    stroke-dashoffset: -138px; }

.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .ham .line {
    fill: none;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke: #ffffff;
    stroke-width: 5.5;
    stroke-linecap: round; }
  .ham .top {
    stroke-dasharray: 40 140; }
  .ham .bottom {
    stroke-dasharray: 40 180; }

.mobile {
  position: fixed;
  z-index: 140;
  top: var(--size-hd);
  left: 0;
  bottom: 0;
  min-width: 50rem;
  width: 50rem;
  background-color: white;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.05);
  transition: 0.4s linear;
  -webkit-transition: 0.4s linear;
  -moz-transition: 0.4s linear;
  -ms-transition: 0.4s linear;
  -o-transition: 0.4s linear;
  transition-delay: 0s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -ms-transition-delay: 0s;
  -o-transition-delay: 0s;
  transform: translateX(-110%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-width: 30rem; }
  @media screen and (max-width: 500px) {
    .mobile {
      width: 30rem; } }
  .mobile.sticky {
    top: var(--size-hd); }
  .mobile.open {
    transform: translateX(0); }
  @media screen and (min-width: 1201px) {
    .mobile {
      display: none; } }
  .mobile-con {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    overflow-y: auto; }
  .mobile-heading {
    font-size: 1.8rem;
    line-height: 1.5;
    color: #D72429;
    font-weight: 700;
    margin-bottom: 2rem;
    text-transform: uppercase; }
  .mobile-overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.8);
    transition: 0.6s ease;
    -webkit-transition: 0.6s ease;
    -moz-transition: 0.6s ease;
    -ms-transition: 0.6s ease;
    -o-transition: 0.6s ease;
    transition-delay: 0s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -ms-transition-delay: 0s;
    -o-transition-delay: 0s;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-110%);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    top: var(--size-hd); }
    .mobile-overlay.sticky {
      top: var(--size-hd); }
    .mobile-overlay.open {
      opacity: 1;
      visibility: visible;
      transform: translateX(0); }
    @media screen and (min-width: 1201px) {
      .mobile-overlay {
        display: none; } }
  .mobile-wr {
    padding-top: 2rem;
    position: relative; }
  .mobile-content {
    padding: 2.4rem 1.2rem;
    margin-top: auto; }
    .mobile-content .titles {
      color: var(--color-pri);
      font-weight: 700;
      font-size: 1.4rem;
      margin-bottom: 1rem;
      padding: 0; }
      .mobile-content .titles:not(:first-child) {
        margin-top: 2rem; }
    .mobile-content-bl {
      margin-bottom: .6rem; }
      .mobile-content-bl .name {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex; }
        .mobile-content-bl .name .ic {
          width: 1.4rem;
          height: 1.4rem;
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flex;
          display: -o-flex;
          display: flex;
          -ms-align-items: center;
          align-items: center;
          -webkit-align-items: center;
          justify-content: center;
          -webkit-justify-content: center;
          margin-right: 0.8rem;
          flex-shrink: 0;
          margin-top: 0.4rem;
          border-radius: 100rem; }
          .mobile-content-bl .name .ic img {
            width: inherit;
            height: inherit; }
        .mobile-content-bl .name-txt {
          display: block; }
          .mobile-content-bl .name-txt a {
            word-break: break-word; }
      .mobile-content-bl .val {
        font-size: 1.4rem; }
    .mobile-content .social {
      display: flex;
      gap: 1rem; }
      .mobile-content .social-link {
        width: 3rem;
        height: 3rem;
        border-radius: 100rem;
        display: flex;
        justify-content: center;
        align-items: center; }
        .mobile-content .social-link img {
          width: 3rem;
          height: 3rem;
          border-radius: 0;
          filter: brightness(0) saturate(100%) invert(19%) sepia(100%) saturate(2373%) hue-rotate(343deg) brightness(95%) contrast(93%); }
  .mobile-title {
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 1.6rem; }
  .mobile-mxh {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
    .mobile-mxh-link {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      flex: 1;
      height: 100%;
      background-color: white;
      cursor: pointer; }
      .mobile-mxh-link:not(:first-child) {
        border-left: 1px solid rgba(0, 0, 0, 0.1); }
      .mobile-mxh-link img {
        width: 2.4rem;
        height: 2.4rem; }
  .mobile-text {
    font-size: 1.4rem;
    display: block;
    color: #D72429;
    word-break: break-word;
    font-weight: 500; }
    .mobile-text:not(:last-child) {
      margin-bottom: 0.8rem; }
  .mobile-account {
    position: absolute;
    top: 1rem;
    left: 1rem; }
  .mobile-re {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center; }
    .mobile-re-link {
      font-size: 1.6rem;
      color: #D72429;
      text-decoration: underline; }
      .mobile-re-link:not(:first-child) {
        margin-left: 16px; }
  .mobile-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 3.5rem;
    height: 3.5rem;
    border: 1px solid #D72429;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    cursor: pointer;
    background-color: white; }
    .mobile-close:hover .icon {
      transform: rotate(360deg); }
    .mobile-close .icon {
      transition: 0.6s ease-in-out;
      font-weight: 500;
      font-size: 1.8rem;
      color: #D72429; }
  .mobile-fl .fw-6 {
    margin-bottom: 0.8rem;
    margin-bottom: 0.8rem;
    display: inline;
    width: fit-content;
    font-size: 1.4rem; }
  .mobile-fl .mobile-text {
    display: inline;
    font-size: 1.4rem; }
  .mobile-fl .dot {
    display: none; }
  @media screen and (max-width: 1200px) {
    .mobile-nav .menu-list > .menu-item.active > a {
      color: var(--color-text) !important; }
    .mobile-nav .menu-nav > .menu-list > .menu-item.current-menu-item > a {
      color: var(--color-pri); } }

.select2-container {
  height: 4.4rem;
  background-color: transparent;
  border-radius: 6px; }

.select2.select2-container {
  width: 100% !important; }

.selection {
  width: 100%; }

.select2-container--default .select2-selection--single {
  border: none;
  border-radius: 0.8rem;
  color: #ffffff;
  height: 4.4rem;
  background-color: transparent;
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%; }

.select2.select2-container .select2-selection--multiple {
  height: auto;
  min-height: 34px; }

.select2-container .select2-selection--single .select2-selection__rendered {
  background-color: transparent;
  border: 1px solid transparent;
  color: #000000;
  font-size: 1.4rem;
  font-weight: 400;
  display: inline !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 100%; }

.select2.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: block;
  padding: 0 4px;
  padding-left: 1.4rem; }

.select2-container .select2-dropdown {
  background: transparent;
  border: none; }

.select2-container .select2-dropdown .select2-search {
  padding: 0; }

.select2-container .select2-dropdown .select2-search input {
  outline: none !important;
  border: none;
  border-bottom: none !important;
  padding: 4px 6px !important;
  background-color: #000000;
  color: #000000; }

.select2-container .select2-dropdown .select2-results ul {
  background: #e6eeed;
  color: #000000; }

.select2-container .select2-dropdown .select2-results ul .select2-results__option--highlighted[aria-selected] {
  background-color: var(--color-pri);
  font-size: 1.4rem; }

.select2-container--default .select2-results__option--selected {
  background-color: #e6eeed;
  color: #000000;
  font-size: 1.4rem; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 100%;
  border-color: #000 transparent transparent transparent; }

.select2-search.select2-search--dropdown {
  display: none !important; }

.select2-results__option {
  font-size: 1.4rem; }

.select2-results {
  display: block;
  border-radius: 6px !important;
  overflow: hidden;
  border: 0.1rem solid white; }

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single {
  border-radius: 0.8rem !important;
  border-color: var(--color-pri); }

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 1.4rem;
  font-weight: 700;
  color: #686060;
  padding-right: 3rem; }

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #C9C9C9; }

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  margin-left: -1.2rem; }

.select2-container .select2-dropdown .select2-results ul .select2-results__option--highlighted[aria-selected] {
  font-size: 1.4rem; }

.effectShine {
  position: relative;
  overflow: hidden; }

.effectShine::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg); }

.effectShine:hover::before {
  -webkit-animation: shine 1s;
  animation: shine 1s; }

.sl-cus {
  position: relative; }
  .sl-cus.min {
    min-width: 12rem; }
    .sl-cus.min:not(:first-child) {
      margin-left: 0.5rem; }

.sl-head {
  background-color: #f8f8f8;
  height: 4rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 1.5rem;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 5;
  cursor: pointer;
  border-radius: 0.6rem; }
  .sl-head::before {
    content: "";
    position: absolute;
    right: 10px;
    top: 13px;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 5px solid #FF8387; }
  .sl-head::after {
    content: "";
    position: absolute;
    right: 1rem;
    bottom: 1.3rem;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 5px solid #FF8387; }

.sl-main {
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0.5px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.sl-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: none;
  background-color: white;
  z-index: 10;
  display: none;
  overflow: hidden;
  border-radius: 0 0 6px 6px;
  max-height: 20rem;
  overflow-y: auto; }

.sl-item {
  font-size: 1.4rem;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: #000000;
  padding: 1rem 1.5rem;
  cursor: pointer;
  transition: 0.2s ease-in-out; }
  .sl-item:hover {
    background-color: #FF8387;
    color: white; }

.popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  visibility: hidden;
  pointer-events: none; }
  .popup.open {
    visibility: visible;
    pointer-events: visible; }
    .popup.open .popup-overlay {
      opacity: 1;
      visibility: visible;
      height: 100%; }
    .popup.open .popup-main {
      opacity: 1;
      visibility: visible;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;
      transition-delay: 0.1s;
      -webkit-transition-delay: 0.1s;
      -moz-transition-delay: 0.1s;
      -ms-transition-delay: 0.1s;
      -o-transition-delay: 0.1s;
      clip-path: polygon();
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      -moz-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      -ms-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      -o-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); }
  .popup-overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out; }
  .popup-main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 70rem;
    max-width: 95%;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    clip-path: polygon();
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    -moz-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    -ms-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    -o-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0); }
    .popup-main-wrapper {
      overflow: hidden;
      border-radius: 6px;
      position: relative;
      padding: 2rem 0;
      background: white;
      border-radius: 1rem; }
  .popup-over {
    overflow-y: auto;
    min-height: 40vh;
    max-height: 80vh;
    max-width: calc(100% - 3rem);
    background-color: #ffffff;
    border-radius: 0.8rem;
    margin: auto;
    position: relative;
    z-index: 2; }
  .popup-wrapper {
    width: 100%;
    min-height: 40vh;
    position: relative;
    padding: 7rem 2rem 2rem 2rem; }
  .popup-title {
    font-size: 2.4rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1.6rem; }
    @media only screen and (max-width: 1200px) {
      .popup-title {
        font-size: 2rem; } }
    @media only screen and (max-width: 800px) {
      .popup-title {
        font-size: 1.7rem; } }
  .popup-close {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    cursor: pointer;
    transition: 0.6s ease-in-out;
    width: 3rem;
    height: 3rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    border-radius: 100rem;
    background-color: var(--color-pri);
    z-index: 3; }
    .popup-close:hover .icon {
      transform: rotate(360deg); }
    .popup-close .icon {
      color: #ffffff;
      font-weight: 300;
      font-size: 2.4rem;
      transition: 0.6s ease-in-out;
      -webkit-transition: 0.6s ease-in-out;
      -moz-transition: 0.6s ease-in-out;
      -ms-transition: 0.6s ease-in-out;
      -o-transition: 0.6s ease-in-out;
      transition-delay: 0s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -ms-transition-delay: 0s;
      -o-transition-delay: 0s; }
  .popup .popSearch {
    width: 50rem; }
    .popup .popSearch .popup-over {
      min-height: unset; }
    .popup .popSearch .popup-wrapper {
      min-height: unset;
      padding: 0rem;
      padding-top: 3rem; }
    .popup .popSearch .content-form {
      display: flex;
      border: 0.1rem solid var(--color-pri);
      padding: 0.5rem;
      border-radius: 1rem;
      margin: auto;
      max-width: 50rem; }
      .popup .popSearch .content-form input {
        border: none;
        outline: none;
        background: transparent;
        width: 100%;
        padding: 0 2rem;
        font-size: 1.6rem; }
      .popup .popSearch .content-form button {
        flex-shrink: 0;
        height: 4rem; }
  .popup .popWish .popup-over {
    min-height: unset; }
  .popup .popWish .popup-wrapper {
    min-height: unset;
    padding: 2rem;
    padding-top: 3rem; }
    @media screen and (max-width: 370px) {
      .popup .popWish .popup-wrapper {
        padding: 3rem 0rem 0rem 0rem; } }
  .popup .popWish .wish-form {
    display: flex;
    flex-direction: column;
    gap: 0.5rem; }
    .popup .popWish .wish-form input {
      width: 100%;
      padding: 1rem;
      border: 0.1rem solid var(--color-pri);
      border-radius: 1rem;
      font-size: 1.6rem;
      color: var(--color-pri); }
  .popup .popWish .wish-bot {
    display: flex;
    gap: 1rem; }
  .popup .popWish .wish-tt {
    font-size: 2.4rem;
    font-weight: 500;
    font-family: "Inter", sans-serif; }
  .popup .popWish .wish-note {
    font-size: 1rem;
    font-weight: 300; }

.dateTime {
  position: relative;
  height: 4.4rem;
  z-index: 2;
  border-radius: 100rem; }
  .dateTime img {
    width: 2.4rem;
    height: 2.4rem;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    pointer-events: none;
    border-radius: unset; }

.dateTimeItem {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit; }

.dateTimeText {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0 2.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: none;
  background-color: transparent;
  z-index: 2;
  font-size: 1.6rem;
  color: #64748B; }
  .dateTimeText.disable {
    opacity: 0;
    visibility: hidden; }

.dateTimeInput {
  padding: 0.8rem 1.4rem;
  background-color: transparent;
  width: 100%;
  font-family: "Mulish", sans-serif;
  border: none;
  outline: none;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  font-size: 1.3rem;
  font-family: "Mulish", sans-serif;
  border-radius: inherit;
  background-color: #ffffff; }

@-webkit-keyframes bounce {
  from,
  20%,
  53%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0) scaleY(1.1);
    transform: translate3d(0, -30px, 0) scaleY(1.1); }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0) scaleY(1.05);
    transform: translate3d(0, -15px, 0) scaleY(1.05); }
  80% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0) scaleY(0.95);
    transform: translate3d(0, 0, 0) scaleY(0.95); }
  90% {
    -webkit-transform: translate3d(0, -4px, 0) scaleY(1.02);
    transform: translate3d(0, -4px, 0) scaleY(1.02); } }

@keyframes bounce {
  from,
  20%,
  53%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0) scaleY(1.1);
    transform: translate3d(0, -30px, 0) scaleY(1.1); }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0) scaleY(1.05);
    transform: translate3d(0, -15px, 0) scaleY(1.05); }
  80% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0) scaleY(0.95);
    transform: translate3d(0, 0, 0) scaleY(0.95); }
  90% {
    -webkit-transform: translate3d(0, -4px, 0) scaleY(1.02);
    transform: translate3d(0, -4px, 0) scaleY(1.02); } }

.animate__bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom; }

@-webkit-keyframes flash {
  from,
  50%,
  to {
    opacity: 1; }
  25%,
  75% {
    opacity: 0; } }

@keyframes flash {
  from,
  50%,
  to {
    opacity: 1; }
  25%,
  75% {
    opacity: 0; } }

.animate__flash {
  -webkit-animation-name: flash;
  animation-name: flash; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.animate__pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out; }

@-webkit-keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.animate__rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand; }

@-webkit-keyframes shakeX {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); } }

@keyframes shakeX {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); } }

.animate__shakeX {
  -webkit-animation-name: shakeX;
  animation-name: shakeX; }

@-webkit-keyframes shakeY {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); } }

@keyframes shakeY {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); } }

.animate__shakeY {
  -webkit-animation-name: shakeY;
  animation-name: shakeY; }

@-webkit-keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg); }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg); }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg); }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg); }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg); }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg); }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg); }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg); }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

.animate__headShake {
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-name: headShake;
  animation-name: headShake; }

@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg); }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg); } }

@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg); }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg); } }

.animate__swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing; }

@-webkit-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg); }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg); }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg); }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg); }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.animate__tada {
  -webkit-animation-name: tada;
  animation-name: tada; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble; }

@-webkit-keyframes jello {
  from,
  11.1%,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg); }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg); }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg); }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg); }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg); }
  77.7% {
    -webkit-transform: skewX(0.39063deg) skewY(0.39063deg);
    transform: skewX(0.39063deg) skewY(0.39063deg); }
  88.8% {
    -webkit-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    transform: skewX(-0.19531deg) skewY(-0.19531deg); } }

@keyframes jello {
  from,
  11.1%,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg); }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg); }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg); }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg); }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg); }
  77.7% {
    -webkit-transform: skewX(0.39063deg) skewY(0.39063deg);
    transform: skewX(0.39063deg) skewY(0.39063deg); }
  88.8% {
    -webkit-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    transform: skewX(-0.19531deg) skewY(-0.19531deg); } }

.animate__jello {
  -webkit-animation-name: jello;
  animation-name: jello;
  -webkit-transform-origin: center;
  transform-origin: center; }

@-webkit-keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  28% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  28% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.animate__heartBeat {
  -webkit-animation-name: heartBeat;
  animation-name: heartBeat;
  -webkit-animation-duration: calc(1s * 1.3);
  animation-duration: calc(1s * 1.3);
  -webkit-animation-duration: calc(var(--animate-duration) * 1.3);
  animation-duration: calc(var(--animate-duration) * 1.3);
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out; }

/* Back entrances */
@-webkit-keyframes backInDown {
  0% {
    -webkit-transform: translateY(-1200px) scale(0.7);
    transform: translateY(-1200px) scale(0.7);
    opacity: 0.7; }
  80% {
    -webkit-transform: translateY(0px) scale(0.7);
    transform: translateY(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

@keyframes backInDown {
  0% {
    -webkit-transform: translateY(-1200px) scale(0.7);
    transform: translateY(-1200px) scale(0.7);
    opacity: 0.7; }
  80% {
    -webkit-transform: translateY(0px) scale(0.7);
    transform: translateY(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

.animate__backInDown {
  -webkit-animation-name: backInDown;
  animation-name: backInDown; }

@-webkit-keyframes backInLeft {
  0% {
    -webkit-transform: translateX(-2000px) scale(0.7);
    transform: translateX(-2000px) scale(0.7);
    opacity: 0.7; }
  80% {
    -webkit-transform: translateX(0px) scale(0.7);
    transform: translateX(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

@keyframes backInLeft {
  0% {
    -webkit-transform: translateX(-2000px) scale(0.7);
    transform: translateX(-2000px) scale(0.7);
    opacity: 0.7; }
  80% {
    -webkit-transform: translateX(0px) scale(0.7);
    transform: translateX(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

.animate__backInLeft {
  -webkit-animation-name: backInLeft;
  animation-name: backInLeft; }

@-webkit-keyframes backInRight {
  0% {
    -webkit-transform: translateX(2000px) scale(0.7);
    transform: translateX(2000px) scale(0.7);
    opacity: 0.7; }
  80% {
    -webkit-transform: translateX(0px) scale(0.7);
    transform: translateX(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

@keyframes backInRight {
  0% {
    -webkit-transform: translateX(2000px) scale(0.7);
    transform: translateX(2000px) scale(0.7);
    opacity: 0.7; }
  80% {
    -webkit-transform: translateX(0px) scale(0.7);
    transform: translateX(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

.animate__backInRight {
  -webkit-animation-name: backInRight;
  animation-name: backInRight; }

@-webkit-keyframes backInUp {
  0% {
    -webkit-transform: translateY(1200px) scale(0.7);
    transform: translateY(1200px) scale(0.7);
    opacity: 0.7; }
  80% {
    -webkit-transform: translateY(0px) scale(0.7);
    transform: translateY(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

@keyframes backInUp {
  0% {
    -webkit-transform: translateY(1200px) scale(0.7);
    transform: translateY(1200px) scale(0.7);
    opacity: 0.7; }
  80% {
    -webkit-transform: translateY(0px) scale(0.7);
    transform: translateY(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

.animate__backInUp {
  -webkit-animation-name: backInUp;
  animation-name: backInUp; }

/* Back exits */
@-webkit-keyframes backOutDown {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  20% {
    -webkit-transform: translateY(0px) scale(0.7);
    transform: translateY(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: translateY(700px) scale(0.7);
    transform: translateY(700px) scale(0.7);
    opacity: 0.7; } }

@keyframes backOutDown {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  20% {
    -webkit-transform: translateY(0px) scale(0.7);
    transform: translateY(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: translateY(700px) scale(0.7);
    transform: translateY(700px) scale(0.7);
    opacity: 0.7; } }

.animate__backOutDown {
  -webkit-animation-name: backOutDown;
  animation-name: backOutDown; }

@-webkit-keyframes backOutLeft {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  20% {
    -webkit-transform: translateX(0px) scale(0.7);
    transform: translateX(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: translateX(-2000px) scale(0.7);
    transform: translateX(-2000px) scale(0.7);
    opacity: 0.7; } }

@keyframes backOutLeft {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  20% {
    -webkit-transform: translateX(0px) scale(0.7);
    transform: translateX(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: translateX(-2000px) scale(0.7);
    transform: translateX(-2000px) scale(0.7);
    opacity: 0.7; } }

.animate__backOutLeft {
  -webkit-animation-name: backOutLeft;
  animation-name: backOutLeft; }

@-webkit-keyframes backOutRight {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  20% {
    -webkit-transform: translateX(0px) scale(0.7);
    transform: translateX(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: translateX(2000px) scale(0.7);
    transform: translateX(2000px) scale(0.7);
    opacity: 0.7; } }

@keyframes backOutRight {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  20% {
    -webkit-transform: translateX(0px) scale(0.7);
    transform: translateX(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: translateX(2000px) scale(0.7);
    transform: translateX(2000px) scale(0.7);
    opacity: 0.7; } }

.animate__backOutRight {
  -webkit-animation-name: backOutRight;
  animation-name: backOutRight; }

@-webkit-keyframes backOutUp {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  20% {
    -webkit-transform: translateY(0px) scale(0.7);
    transform: translateY(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: translateY(-700px) scale(0.7);
    transform: translateY(-700px) scale(0.7);
    opacity: 0.7; } }

@keyframes backOutUp {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  20% {
    -webkit-transform: translateY(0px) scale(0.7);
    transform: translateY(0px) scale(0.7);
    opacity: 0.7; }
  100% {
    -webkit-transform: translateY(-700px) scale(0.7);
    transform: translateY(-700px) scale(0.7);
    opacity: 0.7; } }

.animate__backOutUp {
  -webkit-animation-name: backOutUp;
  animation-name: backOutUp; }

/* Bouncing entrances  */
@-webkit-keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.animate__bounceIn {
  -webkit-animation-duration: calc(1s * 0.75);
  animation-duration: calc(1s * 0.75);
  -webkit-animation-duration: calc(var(--animate-duration) * 0.75);
  animation-duration: calc(var(--animate-duration) * 0.75);
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn; }

@-webkit-keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0) scaleY(3);
    transform: translate3d(0, -3000px, 0) scaleY(3); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0) scaleY(0.9);
    transform: translate3d(0, 25px, 0) scaleY(0.9); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0) scaleY(0.95);
    transform: translate3d(0, -10px, 0) scaleY(0.95); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0) scaleY(0.985);
    transform: translate3d(0, 5px, 0) scaleY(0.985); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0) scaleY(3);
    transform: translate3d(0, -3000px, 0) scaleY(3); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0) scaleY(0.9);
    transform: translate3d(0, 25px, 0) scaleY(0.9); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0) scaleY(0.95);
    transform: translate3d(0, -10px, 0) scaleY(0.95); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0) scaleY(0.985);
    transform: translate3d(0, 5px, 0) scaleY(0.985); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0) scaleX(3);
    transform: translate3d(-3000px, 0, 0) scaleX(3); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0) scaleX(1);
    transform: translate3d(25px, 0, 0) scaleX(1); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0) scaleX(0.98);
    transform: translate3d(-10px, 0, 0) scaleX(0.98); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0) scaleX(0.995);
    transform: translate3d(5px, 0, 0) scaleX(0.995); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0) scaleX(3);
    transform: translate3d(-3000px, 0, 0) scaleX(3); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0) scaleX(1);
    transform: translate3d(25px, 0, 0) scaleX(1); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0) scaleX(0.98);
    transform: translate3d(-10px, 0, 0) scaleX(0.98); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0) scaleX(0.995);
    transform: translate3d(5px, 0, 0) scaleX(0.995); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0) scaleX(3);
    transform: translate3d(3000px, 0, 0) scaleX(3); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0) scaleX(1);
    transform: translate3d(-25px, 0, 0) scaleX(1); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0) scaleX(0.98);
    transform: translate3d(10px, 0, 0) scaleX(0.98); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0) scaleX(0.995);
    transform: translate3d(-5px, 0, 0) scaleX(0.995); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0) scaleX(3);
    transform: translate3d(3000px, 0, 0) scaleX(3); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0) scaleX(1);
    transform: translate3d(-25px, 0, 0) scaleX(1); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0) scaleX(0.98);
    transform: translate3d(10px, 0, 0) scaleX(0.98); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0) scaleX(0.995);
    transform: translate3d(-5px, 0, 0) scaleX(0.995); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0) scaleY(5);
    transform: translate3d(0, 3000px, 0) scaleY(5); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
    transform: translate3d(0, -20px, 0) scaleY(0.9); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0) scaleY(0.95);
    transform: translate3d(0, 10px, 0) scaleY(0.95); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0) scaleY(0.985);
    transform: translate3d(0, -5px, 0) scaleY(0.985); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0) scaleY(5);
    transform: translate3d(0, 3000px, 0) scaleY(5); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
    transform: translate3d(0, -20px, 0) scaleY(0.9); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0) scaleY(0.95);
    transform: translate3d(0, 10px, 0) scaleY(0.95); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0) scaleY(0.985);
    transform: translate3d(0, -5px, 0) scaleY(0.985); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp; }

/* Bouncing exits  */
@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); } }

@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); } }

.animate__bounceOut {
  -webkit-animation-duration: calc(1s * 0.75);
  animation-duration: calc(1s * 0.75);
  -webkit-animation-duration: calc(var(--animate-duration) * 0.75);
  animation-duration: calc(var(--animate-duration) * 0.75);
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut; }

@-webkit-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0) scaleY(0.985);
    transform: translate3d(0, 10px, 0) scaleY(0.985); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
    transform: translate3d(0, -20px, 0) scaleY(0.9); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0) scaleY(3);
    transform: translate3d(0, 2000px, 0) scaleY(3); } }

@keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0) scaleY(0.985);
    transform: translate3d(0, 10px, 0) scaleY(0.985); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
    transform: translate3d(0, -20px, 0) scaleY(0.9); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0) scaleY(3);
    transform: translate3d(0, 2000px, 0) scaleY(3); } }

.animate__bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown; }

@-webkit-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0) scaleX(0.9);
    transform: translate3d(20px, 0, 0) scaleX(0.9); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0) scaleX(2);
    transform: translate3d(-2000px, 0, 0) scaleX(2); } }

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0) scaleX(0.9);
    transform: translate3d(20px, 0, 0) scaleX(0.9); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0) scaleX(2);
    transform: translate3d(-2000px, 0, 0) scaleX(2); } }

.animate__bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0) scaleX(0.9);
    transform: translate3d(-20px, 0, 0) scaleX(0.9); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0) scaleX(2);
    transform: translate3d(2000px, 0, 0) scaleX(2); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0) scaleX(0.9);
    transform: translate3d(-20px, 0, 0) scaleX(0.9); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0) scaleX(2);
    transform: translate3d(2000px, 0, 0) scaleX(2); } }

.animate__bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight; }

@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0) scaleY(0.985);
    transform: translate3d(0, -10px, 0) scaleY(0.985); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0) scaleY(0.9);
    transform: translate3d(0, 20px, 0) scaleY(0.9); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0) scaleY(3);
    transform: translate3d(0, -2000px, 0) scaleY(3); } }

@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0) scaleY(0.985);
    transform: translate3d(0, -10px, 0) scaleY(0.985); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0) scaleY(0.9);
    transform: translate3d(0, 20px, 0) scaleY(0.9); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0) scaleY(3);
    transform: translate3d(0, -2000px, 0) scaleY(3); } }

.animate__bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp; }

/* Fading entrances  */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.animate__fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn; }

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown; }

@-webkit-keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig; }

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft; }

@-webkit-keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig; }

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight; }

@-webkit-keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig; }

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

@-webkit-keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig; }

@-webkit-keyframes fadeInTopLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, -100%, 0);
    transform: translate3d(-100%, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInTopLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, -100%, 0);
    transform: translate3d(-100%, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__fadeInTopLeft {
  -webkit-animation-name: fadeInTopLeft;
  animation-name: fadeInTopLeft; }

@-webkit-keyframes fadeInTopRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, -100%, 0);
    transform: translate3d(100%, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInTopRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, -100%, 0);
    transform: translate3d(100%, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__fadeInTopRight {
  -webkit-animation-name: fadeInTopRight;
  animation-name: fadeInTopRight; }

@-webkit-keyframes fadeInBottomLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 100%, 0);
    transform: translate3d(-100%, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInBottomLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 100%, 0);
    transform: translate3d(-100%, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__fadeInBottomLeft {
  -webkit-animation-name: fadeInBottomLeft;
  animation-name: fadeInBottomLeft; }

@-webkit-keyframes fadeInBottomRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 100%, 0);
    transform: translate3d(100%, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInBottomRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 100%, 0);
    transform: translate3d(100%, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__fadeInBottomRight {
  -webkit-animation-name: fadeInBottomRight;
  animation-name: fadeInBottomRight; }

/* Fading exits */
@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.animate__fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.animate__fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown; }

@-webkit-keyframes fadeOutDownBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

@keyframes fadeOutDownBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

.animate__fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig; }

@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes fadeOutLeft {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.animate__fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft; }

@-webkit-keyframes fadeOutLeftBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

@keyframes fadeOutLeftBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

.animate__fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig; }

@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

@keyframes fadeOutRight {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

.animate__fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight; }

@-webkit-keyframes fadeOutRightBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

@keyframes fadeOutRightBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

.animate__fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig; }

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.animate__fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp; }

@-webkit-keyframes fadeOutUpBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

@keyframes fadeOutUpBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

.animate__fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig; }

@-webkit-keyframes fadeOutTopLeft {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, -100%, 0);
    transform: translate3d(-100%, -100%, 0); } }

@keyframes fadeOutTopLeft {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, -100%, 0);
    transform: translate3d(-100%, -100%, 0); } }

.animate__fadeOutTopLeft {
  -webkit-animation-name: fadeOutTopLeft;
  animation-name: fadeOutTopLeft; }

@-webkit-keyframes fadeOutTopRight {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, -100%, 0);
    transform: translate3d(100%, -100%, 0); } }

@keyframes fadeOutTopRight {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, -100%, 0);
    transform: translate3d(100%, -100%, 0); } }

.animate__fadeOutTopRight {
  -webkit-animation-name: fadeOutTopRight;
  animation-name: fadeOutTopRight; }

@-webkit-keyframes fadeOutBottomRight {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 100%, 0);
    transform: translate3d(100%, 100%, 0); } }

@keyframes fadeOutBottomRight {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 100%, 0);
    transform: translate3d(100%, 100%, 0); } }

.animate__fadeOutBottomRight {
  -webkit-animation-name: fadeOutBottomRight;
  animation-name: fadeOutBottomRight; }

@-webkit-keyframes fadeOutBottomLeft {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 100%, 0);
    transform: translate3d(-100%, 100%, 0); } }

@keyframes fadeOutBottomLeft {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 100%, 0);
    transform: translate3d(-100%, 100%, 0); } }

.animate__fadeOutBottomLeft {
  -webkit-animation-name: fadeOutBottomLeft;
  animation-name: fadeOutBottomLeft; }

/* Flippers */
@-webkit-keyframes flip {
  from {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  to {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; } }

@keyframes flip {
  from {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  to {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; } }

.animate__animated.animate__flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip; }

@-webkit-keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

@keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

.animate__flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX; }

@-webkit-keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

@keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

.animate__flipInY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  animation-name: flipInY; }

@-webkit-keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

@keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

.animate__flipOutX {
  -webkit-animation-duration: calc(1s * 0.75);
  animation-duration: calc(1s * 0.75);
  -webkit-animation-duration: calc(var(--animate-duration) * 0.75);
  animation-duration: calc(var(--animate-duration) * 0.75);
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important; }

@-webkit-keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0; } }

@keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0; } }

.animate__flipOutY {
  -webkit-animation-duration: calc(1s * 0.75);
  animation-duration: calc(1s * 0.75);
  -webkit-animation-duration: calc(var(--animate-duration) * 0.75);
  animation-duration: calc(var(--animate-duration) * 0.75);
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  animation-name: flipOutY; }

/* Lightspeed */
@-webkit-keyframes lightSpeedInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0; }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1; }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes lightSpeedInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0; }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1; }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__lightSpeedInRight {
  -webkit-animation-name: lightSpeedInRight;
  animation-name: lightSpeedInRight;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out; }

@-webkit-keyframes lightSpeedInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0) skewX(30deg);
    transform: translate3d(-100%, 0, 0) skewX(30deg);
    opacity: 0; }
  60% {
    -webkit-transform: skewX(-20deg);
    transform: skewX(-20deg);
    opacity: 1; }
  80% {
    -webkit-transform: skewX(5deg);
    transform: skewX(5deg); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes lightSpeedInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0) skewX(30deg);
    transform: translate3d(-100%, 0, 0) skewX(30deg);
    opacity: 0; }
  60% {
    -webkit-transform: skewX(-20deg);
    transform: skewX(-20deg);
    opacity: 1; }
  80% {
    -webkit-transform: skewX(5deg);
    transform: skewX(5deg); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__lightSpeedInLeft {
  -webkit-animation-name: lightSpeedInLeft;
  animation-name: lightSpeedInLeft;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out; }

@-webkit-keyframes lightSpeedOutRight {
  from {
    opacity: 1; }
  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0; } }

@keyframes lightSpeedOutRight {
  from {
    opacity: 1; }
  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0; } }

.animate__lightSpeedOutRight {
  -webkit-animation-name: lightSpeedOutRight;
  animation-name: lightSpeedOutRight;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in; }

@-webkit-keyframes lightSpeedOutLeft {
  from {
    opacity: 1; }
  to {
    -webkit-transform: translate3d(-100%, 0, 0) skewX(-30deg);
    transform: translate3d(-100%, 0, 0) skewX(-30deg);
    opacity: 0; } }

@keyframes lightSpeedOutLeft {
  from {
    opacity: 1; }
  to {
    -webkit-transform: translate3d(-100%, 0, 0) skewX(-30deg);
    transform: translate3d(-100%, 0, 0) skewX(-30deg);
    opacity: 0; } }

.animate__lightSpeedOutLeft {
  -webkit-animation-name: lightSpeedOutLeft;
  animation-name: lightSpeedOutLeft;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in; }

/* Rotating entrances */
@-webkit-keyframes rotateIn {
  from {
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes rotateIn {
  from {
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

.animate__rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn;
  -webkit-transform-origin: center;
  transform-origin: center; }

@-webkit-keyframes rotateInDownLeft {
  from {
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes rotateInDownLeft {
  from {
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

.animate__rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft;
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom; }

@-webkit-keyframes rotateInDownRight {
  from {
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes rotateInDownRight {
  from {
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

.animate__rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom; }

@-webkit-keyframes rotateInUpLeft {
  from {
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes rotateInUpLeft {
  from {
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

.animate__rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft;
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom; }

@-webkit-keyframes rotateInUpRight {
  from {
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes rotateInUpRight {
  from {
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

.animate__rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom; }

/* Rotating exits */
@-webkit-keyframes rotateOut {
  from {
    opacity: 1; }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0; } }

@keyframes rotateOut {
  from {
    opacity: 1; }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0; } }

.animate__rotateOut {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut;
  -webkit-transform-origin: center;
  transform-origin: center; }

@-webkit-keyframes rotateOutDownLeft {
  from {
    opacity: 1; }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; } }

@keyframes rotateOutDownLeft {
  from {
    opacity: 1; }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; } }

.animate__rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft;
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom; }

@-webkit-keyframes rotateOutDownRight {
  from {
    opacity: 1; }
  to {
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

@keyframes rotateOutDownRight {
  from {
    opacity: 1; }
  to {
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

.animate__rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom; }

@-webkit-keyframes rotateOutUpLeft {
  from {
    opacity: 1; }
  to {
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

@keyframes rotateOutUpLeft {
  from {
    opacity: 1; }
  to {
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

.animate__rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft;
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom; }

@-webkit-keyframes rotateOutUpRight {
  from {
    opacity: 1; }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0; } }

@keyframes rotateOutUpRight {
  from {
    opacity: 1; }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0; } }

.animate__rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom; }

/* Specials */
@-webkit-keyframes hinge {
  0% {
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  20%,
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  40%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1; }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0; } }

@keyframes hinge {
  0% {
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  20%,
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  40%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1; }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0; } }

.animate__hinge {
  -webkit-animation-duration: calc(1s * 2);
  animation-duration: calc(1s * 2);
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: calc(var(--animate-duration) * 2);
  -webkit-animation-name: hinge;
  animation-name: hinge;
  -webkit-transform-origin: top left;
  transform-origin: top left; }

@-webkit-keyframes jackInTheBox {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom; }
  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg); }
  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes jackInTheBox {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom; }
  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg); }
  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1); } }

.animate__jackInTheBox {
  -webkit-animation-name: jackInTheBox;
  animation-name: jackInTheBox; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes rollOut {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg); } }

@keyframes rollOut {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg); } }

.animate__rollOut {
  -webkit-animation-name: rollOut;
  animation-name: rollOut; }

/* Zooming entrances */
@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

.animate__zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn; }

@-webkit-keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.animate__zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown; }

@-webkit-keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.animate__zoomInLeft {
  -webkit-animation-name: zoomInLeft;
  animation-name: zoomInLeft; }

@-webkit-keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.animate__zoomInRight {
  -webkit-animation-name: zoomInRight;
  animation-name: zoomInRight; }

@-webkit-keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.animate__zoomInUp {
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp; }

/* Zooming exits */
@-webkit-keyframes zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

@keyframes zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

.animate__zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut; }

@-webkit-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.animate__zoomOutDown {
  -webkit-animation-name: zoomOutDown;
  animation-name: zoomOutDown;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom; }

@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0); } }

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0); } }

.animate__zoomOutLeft {
  -webkit-animation-name: zoomOutLeft;
  animation-name: zoomOutLeft;
  -webkit-transform-origin: left center;
  transform-origin: left center; }

@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0); } }

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0); } }

.animate__zoomOutRight {
  -webkit-animation-name: zoomOutRight;
  animation-name: zoomOutRight;
  -webkit-transform-origin: right center;
  transform-origin: right center; }

@-webkit-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.animate__zoomOutUp {
  -webkit-animation-name: zoomOutUp;
  animation-name: zoomOutUp;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom; }

/* Sliding entrances */
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown; }

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft; }

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight; }

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp; }

/* Sliding exits */
@-webkit-keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.animate__slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown; }

@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.animate__slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft; }

@-webkit-keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

@keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

.animate__slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight; }

@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.animate__slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp; }

.custom-bounce {
  animation-delay: var(--delay) !important; }
  .custom-bounce.active {
    animation: bounce var(--duration) forwards;
    -webkit-animation: bounce var(--duration) forwards;
    -moz-animation: bounce var(--duration) forwards;
    -ms-animation: bounce var(--duration) forwards;
    -o-animation: bounce var(--duration) forwards; }

.custom-bounceIn {
  opacity: 0;
  -webkit-transform: scale(0.3);
  -ms-transform: scale(0.3);
  transform: scale(0.3);
  animation-delay: var(--delay) !important; }
  .custom-bounceIn.active {
    animation: bounceIn var(--duration) forwards;
    -webkit-animation: bounceIn var(--duration) forwards;
    -moz-animation: bounceIn var(--duration) forwards;
    -ms-animation: bounceIn var(--duration) forwards;
    -o-animation: bounceIn var(--duration) forwards; }

.custom-bounceInRight {
  -webkit-transform: translate3d(3000px, 0, 0) scaleX(3);
  transform: translate3d(3000px, 0, 0) scaleX(3);
  animation-delay: var(--delay) !important; }
  .custom-bounceInRight.active {
    animation: bounceInRight var(--duration) forwards;
    -webkit-animation: bounceInRight var(--duration) forwards;
    -moz-animation: bounceInRight var(--duration) forwards;
    -ms-animation: bounceInRight var(--duration) forwards;
    -o-animation: bounceInRight var(--duration) forwards; }

.custom-bouncenInLeft {
  -webkit-transform: translate3d(-3000px, 0, 0) scaleX(3);
  transform: translate3d(-3000px, 0, 0) scaleX(3);
  animation-delay: var(--delay) !important; }
  .custom-bouncenInLeft.active {
    animation: bounceInLeft var(--duration) forwards;
    -webkit-animation: bounceInLeft var(--duration) forwards;
    -moz-animation: bounceInLeft var(--duration) forwards;
    -ms-animation: bounceInLeft var(--duration) forwards;
    -o-animation: bounceInLeft var(--duration) forwards; }

.custom-bounceInUp {
  -webkit-transform: translateY(2000px);
  transform: translateY(2000px);
  animation-delay: var(--delay) !important; }
  .custom-bounceInUp.active {
    animation: bounceInUp var(--duration) forwards;
    -webkit-animation: bounceInUp var(--duration) forwards;
    -moz-animation: bounceInUp var(--duration) forwards;
    -ms-animation: bounceInUp var(--duration) forwards;
    -o-animation: bounceInUp var(--duration) forwards; }

.custom-bounceInDown {
  -webkit-transform: translateY(-2000px);
  transform: translateY(-2000px);
  animation-delay: var(--delay) !important; }
  .custom-bounceInDown.active {
    animation: bounceInDown var(--duration) forwards;
    -webkit-animation: bounceInDown var(--duration) forwards;
    -moz-animation: bounceInDown var(--duration) forwards;
    -ms-animation: bounceInDown var(--duration) forwards;
    -o-animation: bounceInDown var(--duration) forwards; }

.custom-bounceOut {
  animation-delay: var(--delay) !important; }
  .custom-bounceOut.active {
    animation: bounceOut var(--duration) forwards;
    -webkit-animation: bounceOut var(--duration) forwards;
    -moz-animation: bounceOut var(--duration) forwards;
    -ms-animation: bounceOut var(--duration) forwards;
    -o-animation: bounceOut var(--duration) forwards; }

.custom-bounceOutDown {
  animation-delay: var(--delay) !important; }
  .custom-bounceOutDown.active {
    animation: bounceOutDown var(--duration) forwards;
    -webkit-animation: bounceOutDown var(--duration) forwards;
    -moz-animation: bounceOutDown var(--duration) forwards;
    -ms-animation: bounceOutDown var(--duration) forwards;
    -o-animation: bounceOutDown var(--duration) forwards; }

.custom-bounceOutLeft {
  animation-delay: var(--delay) !important; }
  .custom-bounceOutLeft.active {
    animation: bounceOutLeft var(--duration) forwards;
    -webkit-animation: bounceOutLeft var(--duration) forwards;
    -moz-animation: bounceOutLeft var(--duration) forwards;
    -ms-animation: bounceOutLeft var(--duration) forwards;
    -o-animation: bounceOutLeft var(--duration) forwards; }

.custom-bounceOutRight {
  animation-delay: var(--delay) !important; }
  .custom-bounceOutRight.active {
    animation: bounceOutRight var(--duration) forwards;
    -webkit-animation: bounceOutRight var(--duration) forwards;
    -moz-animation: bounceOutRight var(--duration) forwards;
    -ms-animation: bounceOutRight var(--duration) forwards;
    -o-animation: bounceOutRight var(--duration) forwards; }

.custom-bounceOutUp {
  animation-delay: var(--delay) !important; }
  .custom-bounceOutUp.active {
    animation: bounceOutUp var(--duration) forwards;
    -webkit-animation: bounceOutUp var(--duration) forwards;
    -moz-animation: bounceOutUp var(--duration) forwards;
    -ms-animation: bounceOutUp var(--duration) forwards;
    -o-animation: bounceOutUp var(--duration) forwards; }

.custom-fadeIn {
  opacity: 0;
  animation-delay: var(--delay) !important; }
  .custom-fadeIn.active {
    animation: fadeIn var(--duration) forwards;
    -webkit-animation: fadeIn var(--duration) forwards;
    -moz-animation: fadeIn var(--duration) forwards;
    -ms-animation: fadeIn var(--duration) forwards;
    -o-animation: fadeIn var(--duration) forwards; }

.custom-fadeInDown {
  opacity: 0;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  animation-delay: var(--delay) !important; }
  .custom-fadeInDown.active {
    animation: fadeInDown var(--duration) forwards;
    -webkit-animation: fadeInDown var(--duration) forwards;
    -moz-animation: fadeInDown var(--duration) forwards;
    -ms-animation: fadeInDown var(--duration) forwards;
    -o-animation: fadeInDown var(--duration) forwards; }

.custom-fadeInLeft {
  opacity: 0;
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
  animation-delay: var(--delay) !important; }
  .custom-fadeInLeft.active {
    animation: fadeInLeft var(--duration) forwards;
    -webkit-animation: fadeInLeft var(--duration) forwards;
    -moz-animation: fadeInLeft var(--duration) forwards;
    -ms-animation: fadeInLeft var(--duration) forwards;
    -o-animation: fadeInLeft var(--duration) forwards; }

.custom-fadeInRight {
  opacity: 0;
  -webkit-transform: translateX(20px);
  transform: translateX(20px);
  animation-delay: var(--delay) !important; }
  .custom-fadeInRight.active {
    animation: fadeInRight var(--duration) forwards;
    -webkit-animation: fadeInRight var(--duration) forwards;
    -moz-animation: fadeInRight var(--duration) forwards;
    -ms-animation: fadeInRight var(--duration) forwards;
    -o-animation: fadeInRight var(--duration) forwards; }

.custom-fadeInUp {
  opacity: 0;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  animation-delay: var(--delay) !important; }
  .custom-fadeInUp.active {
    animation: fadeInUp var(--duration) forwards;
    -webkit-animation: fadeInUp var(--duration) forwards;
    -moz-animation: fadeInUp var(--duration) forwards;
    -ms-animation: fadeInUp var(--duration) forwards;
    -o-animation: fadeInUp var(--duration) forwards; }

.custom-fadeInLeftBig {
  -webkit-transform: translate3d(-2000px, 0, 0);
  transform: translate3d(-2000px, 0, 0);
  animation-delay: var(--delay) !important;
  opacity: 0; }
  .custom-fadeInLeftBig.active {
    animation: fadeInLeftBig var(--duration) forwards;
    -webkit-animation: fadeInLeftBig var(--duration) forwards;
    -moz-animation: fadeInLeftBig var(--duration) forwards;
    -ms-animation: fadeInLeftBig var(--duration) forwards;
    -o-animation: fadeInLeftBig var(--duration) forwards; }

.custom-fadeInRightBig {
  -webkit-transform: translate3d(2000px, 0, 0);
  transform: translate3d(2000px, 0, 0);
  animation-delay: var(--delay) !important;
  opacity: 0; }
  .custom-fadeInRightBig.active {
    animation: fadeInRightBig var(--duration) forwards; }

.custom-fadeInUpBig {
  -webkit-transform: translateY(2000px);
  -ms-transform: translateY(2000px);
  transform: translateY(2000px);
  opacity: 0;
  animation-delay: var(--delay) !important; }
  .custom-fadeInUpBig.active {
    animation: fadeInUpBig var(--duration) forwards;
    -webkit-animation: fadeInUpBig var(--duration) forwards;
    -moz-animation: fadeInUpBig var(--duration) forwards;
    -ms-animation: fadeInUpBig var(--duration) forwards;
    -o-animation: fadeInUpBig var(--duration) forwards; }

.custom-fadeInDownBig {
  -webkit-transform: translateY(-2000px);
  -ms-transform: translateY(-2000px);
  transform: translateY(-2000px);
  opacity: 0;
  animation-delay: var(--delay) !important; }
  .custom-fadeInDownBig.active {
    animation: fadeInDownBig var(--duration) forwards;
    -webkit-animation: fadeInDownBig var(--duration) forwards;
    -moz-animation: fadeInDownBig var(--duration) forwards;
    -ms-animation: fadeInDownBig var(--duration) forwards;
    -o-animation: fadeInDownBig var(--duration) forwards; }

.custom-fadeInTopLeft {
  opacity: 0;
  -webkit-transform: translate3d(-100%, -100%, 0);
  transform: translate3d(-100%, -100%, 0);
  animation-delay: var(--delay) !important; }
  .custom-fadeInTopLeft.active {
    animation: fadeInTopLeft var(--duration) forwards;
    -webkit-animation: fadeInTopLeft var(--duration) forwards;
    -moz-animation: fadeInTopLeft var(--duration) forwards;
    -ms-animation: fadeInTopLeft var(--duration) forwards;
    -o-animation: fadeInTopLeft var(--duration) forwards; }

.custom-fadeInTopRight {
  opacity: 0;
  -webkit-transform: translate3d(100%, -100%, 0);
  transform: translate3d(100%, -100%, 0);
  animation-delay: var(--delay) !important; }
  .custom-fadeInTopRight.active {
    animation: fadeInTopRight var(--duration) forwards;
    -webkit-animation: fadeInTopRight var(--duration) forwards;
    -moz-animation: fadeInTopRight var(--duration) forwards;
    -ms-animation: fadeInTopRight var(--duration) forwards;
    -o-animation: fadeInTopRight var(--duration) forwards; }

.custom-fadeInBottomLeft {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 100%, 0);
  transform: translate3d(-100%, 100%, 0);
  animation-delay: var(--delay) !important; }
  .custom-fadeInBottomLeft.active {
    animation: fadeInBottomLeft var(--duration) forwards;
    -webkit-animation: fadeInBottomLeft var(--duration) forwards;
    -moz-animation: fadeInBottomLeft var(--duration) forwards;
    -ms-animation: fadeInBottomLeft var(--duration) forwards;
    -o-animation: fadeInBottomLeft var(--duration) forwards; }

.custom-fadeInBottomRight {
  opacity: 0;
  -webkit-transform: translate3d(100%, 100%, 0);
  transform: translate3d(100%, 100%, 0);
  animation-delay: var(--delay) !important; }
  .custom-fadeInBottomRight.active {
    animation: fadeInBottomRight var(--duration) forwards;
    -webkit-animation: fadeInBottomRight var(--duration) forwards;
    -moz-animation: fadeInBottomRight var(--duration) forwards;
    -ms-animation: fadeInBottomRight var(--duration) forwards;
    -o-animation: fadeInBottomRight var(--duration) forwards; }

.custom-fadeOut {
  animation-delay: var(--delay) !important; }
  .custom-fadeOut.active {
    animation: fadeOut var(--duration) forwards;
    -webkit-animation: fadeOut var(--duration) forwards;
    -moz-animation: fadeOut var(--duration) forwards;
    -ms-animation: fadeOut var(--duration) forwards;
    -o-animation: fadeOut var(--duration) forwards; }

.custom-fadeOutDown {
  animation-delay: var(--delay) !important; }
  .custom-fadeOutDown.active {
    animation: fadeOutDown var(--duration) forwards;
    -webkit-animation: fadeOutDown var(--duration) forwards;
    -moz-animation: fadeOutDown var(--duration) forwards;
    -ms-animation: fadeOutDown var(--duration) forwards;
    -o-animation: fadeOutDown var(--duration) forwards; }

.custom-fadeOutDownBig {
  animation-delay: var(--delay) !important; }
  .custom-fadeOutDownBig.active {
    animation: fadeOutDownBig var(--duration) forwards;
    -webkit-animation: fadeOutDownBig var(--duration) forwards;
    -moz-animation: fadeOutDownBig var(--duration) forwards;
    -ms-animation: fadeOutDownBig var(--duration) forwards;
    -o-animation: fadeOutDownBig var(--duration) forwards; }

.custom-fadeOutLeft {
  animation-delay: var(--delay) !important; }
  .custom-fadeOutLeft.active {
    animation: fadeOutLeft var(--duration) forwards;
    -webkit-animation: fadeOutLeft var(--duration) forwards;
    -moz-animation: fadeOutLeft var(--duration) forwards;
    -ms-animation: fadeOutLeft var(--duration) forwards;
    -o-animation: fadeOutLeft var(--duration) forwards; }

.custom-fadeOutLeftBig {
  animation-delay: var(--delay) !important; }
  .custom-fadeOutLeftBig.active {
    animation: fadeOutLeftBig var(--duration) forwards;
    -webkit-animation: fadeOutLeftBig var(--duration) forwards;
    -moz-animation: fadeOutLeftBig var(--duration) forwards;
    -ms-animation: fadeOutLeftBig var(--duration) forwards;
    -o-animation: fadeOutLeftBig var(--duration) forwards; }

.custom-fadeOutRight {
  animation-delay: var(--delay) !important; }
  .custom-fadeOutRight.active {
    animation: fadeOutRight var(--duration) forwards;
    -webkit-animation: fadeOutRight var(--duration) forwards;
    -moz-animation: fadeOutRight var(--duration) forwards;
    -ms-animation: fadeOutRight var(--duration) forwards;
    -o-animation: fadeOutRight var(--duration) forwards; }

.custom-fadeOutRightBig {
  animation-delay: var(--delay) !important; }
  .custom-fadeOutRightBig.active {
    animation: fadeOutRightBig var(--duration) forwards;
    -webkit-animation: fadeOutRightBig var(--duration) forwards;
    -moz-animation: fadeOutRightBig var(--duration) forwards;
    -ms-animation: fadeOutRightBig var(--duration) forwards;
    -o-animation: fadeOutRightBig var(--duration) forwards; }

.custom-fadeOutUp {
  animation-delay: var(--delay) !important; }
  .custom-fadeOutUp.active {
    animation: fadeOutUp var(--duration) forwards;
    -webkit-animation: fadeOutUp var(--duration) forwards;
    -moz-animation: fadeOutUp var(--duration) forwards;
    -ms-animation: fadeOutUp var(--duration) forwards;
    -o-animation: fadeOutUp var(--duration) forwards; }

.custom-fadeOutUpBig {
  animation-delay: var(--delay) !important; }
  .custom-fadeOutUpBig.active {
    animation: fadeOutUpBig var(--duration) forwards;
    -webkit-animation: fadeOutUpBig var(--duration) forwards;
    -moz-animation: fadeOutUpBig var(--duration) forwards;
    -ms-animation: fadeOutUpBig var(--duration) forwards;
    -o-animation: fadeOutUpBig var(--duration) forwards; }

.custom-fadeOutTopLeft {
  animation-delay: var(--delay) !important; }
  .custom-fadeOutTopLeft.active {
    animation: fadeOutTopLeft var(--duration) forwards;
    -webkit-animation: fadeOutTopLeft var(--duration) forwards;
    -moz-animation: fadeOutTopLeft var(--duration) forwards;
    -ms-animation: fadeOutTopLeft var(--duration) forwards;
    -o-animation: fadeOutTopLeft var(--duration) forwards; }

.custom-fadeOutTopRight {
  animation-delay: var(--delay) !important; }
  .custom-fadeOutTopRight.active {
    animation: fadeOutTopRight var(--duration) forwards;
    -webkit-animation: fadeOutTopRight var(--duration) forwards;
    -moz-animation: fadeOutTopRight var(--duration) forwards;
    -ms-animation: fadeOutTopRight var(--duration) forwards;
    -o-animation: fadeOutTopRight var(--duration) forwards; }

.custom-fadeOutBottomRight {
  animation-delay: var(--delay) !important; }
  .custom-fadeOutBottomRight.active {
    animation: fadeOutBottomRight var(--duration) forwards;
    -webkit-animation: fadeOutBottomRight var(--duration) forwards;
    -moz-animation: fadeOutBottomRight var(--duration) forwards;
    -ms-animation: fadeOutBottomRight var(--duration) forwards;
    -o-animation: fadeOutBottomRight var(--duration) forwards; }

.custom-fadeOutBottomLeft {
  animation-delay: var(--delay) !important; }
  .custom-fadeOutBottomLeft.active {
    animation: fadeOutBottomLeft var(--duration) forwards;
    -webkit-animation: fadeOutBottomLeft var(--duration) forwards;
    -moz-animation: fadeOutBottomLeft var(--duration) forwards;
    -ms-animation: fadeOutBottomLeft var(--duration) forwards;
    -o-animation: fadeOutBottomLeft var(--duration) forwards; }

.custom-zoomIn {
  opacity: 0;
  -webkit-transform: scale3d(0.3, 0.3, 0.3);
  transform: scale3d(0.3, 0.3, 0.3);
  animation-delay: var(--delay) !important; }
  .custom-zoomIn.active {
    animation: zoomIn var(--duration) forwards;
    -webkit-animation: zoomIn var(--duration) forwards;
    -moz-animation: zoomIn var(--duration) forwards;
    -ms-animation: zoomIn var(--duration) forwards;
    -o-animation: zoomIn var(--duration) forwards; }

.custom-zoomInDown {
  opacity: 0;
  -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
  transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
  -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  animation-delay: var(--delay) !important; }
  .custom-zoomInDown.active {
    animation: zoomInDown var(--duration) forwards;
    -webkit-animation: zoomInDown var(--duration) forwards;
    -moz-animation: zoomInDown var(--duration) forwards;
    -ms-animation: zoomInDown var(--duration) forwards;
    -o-animation: zoomInDown var(--duration) forwards; }

.custom-zoomInLeft {
  opacity: 0;
  -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
  transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
  -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  animation-delay: var(--delay) !important; }
  .custom-zoomInLeft.active {
    animation: zoomInLeft var(--duration) forwards;
    -webkit-animation: zoomInLeft var(--duration) forwards;
    -moz-animation: zoomInLeft var(--duration) forwards;
    -ms-animation: zoomInLeft var(--duration) forwards;
    -o-animation: zoomInLeft var(--duration) forwards; }

.custom-zoomInRight {
  opacity: 0;
  -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
  transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
  -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  animation-delay: var(--delay) !important; }
  .custom-zoomInRight.active {
    animation: zoomInRight var(--duration) forwards;
    -webkit-animation: zoomInRight var(--duration) forwards;
    -moz-animation: zoomInRight var(--duration) forwards;
    -ms-animation: zoomInRight var(--duration) forwards;
    -o-animation: zoomInRight var(--duration) forwards; }

.custom-zoomInUp {
  opacity: 0;
  -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
  transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
  -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  animation-delay: var(--delay) !important; }
  .custom-zoomInUp.active {
    animation: zoomInUp var(--duration) forwards;
    -webkit-animation: zoomInUp var(--duration) forwards;
    -moz-animation: zoomInUp var(--duration) forwards;
    -ms-animation: zoomInUp var(--duration) forwards;
    -o-animation: zoomInUp var(--duration) forwards; }

.custom-zoomOut {
  animation-delay: var(--delay) !important; }
  .custom-zoomOut.active {
    animation: zoomOut var(--duration) forwards;
    -webkit-animation: zoomOut var(--duration) forwards;
    -moz-animation: zoomOut var(--duration) forwards;
    -ms-animation: zoomOut var(--duration) forwards;
    -o-animation: zoomOut var(--duration) forwards; }

.custom-zoomOutDown {
  animation-delay: var(--delay) !important; }
  .custom-zoomOutDown.active {
    animation: zoomOutDown var(--duration) forwards;
    -webkit-animation: zoomOutDown var(--duration) forwards;
    -moz-animation: zoomOutDown var(--duration) forwards;
    -ms-animation: zoomOutDown var(--duration) forwards;
    -o-animation: zoomOutDown var(--duration) forwards; }

.custom-zoomOutLeft {
  animation-delay: var(--delay) !important; }
  .custom-zoomOutLeft.active {
    animation: zoomOutLeft var(--duration) forwards;
    -webkit-animation: zoomOutLeft var(--duration) forwards;
    -moz-animation: zoomOutLeft var(--duration) forwards;
    -ms-animation: zoomOutLeft var(--duration) forwards;
    -o-animation: zoomOutLeft var(--duration) forwards; }

.custom-zoomOutRight {
  animation-delay: var(--delay) !important; }
  .custom-zoomOutRight.active {
    animation: zoomOutRight var(--duration) forwards;
    -webkit-animation: zoomOutRight var(--duration) forwards;
    -moz-animation: zoomOutRight var(--duration) forwards;
    -ms-animation: zoomOutRight var(--duration) forwards;
    -o-animation: zoomOutRight var(--duration) forwards; }

.custom-zoomOutUp {
  animation-delay: var(--delay) !important; }
  .custom-zoomOutUp.active {
    animation: zoomOutUp var(--duration) forwards;
    -webkit-animation: zoomOutUp var(--duration) forwards;
    -moz-animation: zoomOutUp var(--duration) forwards;
    -ms-animation: zoomOutUp var(--duration) forwards;
    -o-animation: zoomOutUp var(--duration) forwards; }

.custom-slideInDown {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  visibility: visible;
  animation-delay: var(--delay) !important; }
  .custom-slideInDown.active {
    animation: slideInDown var(--duration) forwards;
    -webkit-animation: slideInDown var(--duration) forwards;
    -moz-animation: slideInDown var(--duration) forwards;
    -ms-animation: slideInDown var(--duration) forwards;
    -o-animation: slideInDown var(--duration) forwards; }

.custom-slideInLeft {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  visibility: visible;
  animation-delay: var(--delay) !important; }
  .custom-slideInLeft.active {
    animation: slideInLeft var(--duration) forwards;
    -webkit-animation: slideInLeft var(--duration) forwards;
    -moz-animation: slideInLeft var(--duration) forwards;
    -ms-animation: slideInLeft var(--duration) forwards;
    -o-animation: slideInLeft var(--duration) forwards; }

.custom-slideInRight {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  visibility: visible;
  animation-delay: var(--delay) !important; }
  .custom-slideInRight.active {
    animation: slideInRight var(--duration) forwards;
    -webkit-animation: slideInRight var(--duration) forwards;
    -moz-animation: slideInRight var(--duration) forwards;
    -ms-animation: slideInRight var(--duration) forwards;
    -o-animation: slideInRight var(--duration) forwards; }

.custom-slideInUp {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  visibility: visible;
  animation-delay: var(--delay) !important; }
  .custom-slideInUp.active {
    animation: slideInUp var(--duration) forwards;
    -webkit-animation: slideInUp var(--duration) forwards;
    -moz-animation: slideInUp var(--duration) forwards;
    -ms-animation: slideInUp var(--duration) forwards;
    -o-animation: slideInUp var(--duration) forwards; }

.custom-slideOutDown {
  animation-delay: var(--delay) !important; }
  .custom-slideOutDown.active {
    animation: slideOutDown var(--duration) forwards;
    -webkit-animation: slideOutDown var(--duration) forwards;
    -moz-animation: slideOutDown var(--duration) forwards;
    -ms-animation: slideOutDown var(--duration) forwards;
    -o-animation: slideOutDown var(--duration) forwards; }

.custom-slideOutLeft {
  animation-delay: var(--delay) !important; }
  .custom-slideOutLeft.active {
    animation: slideOutLeft var(--duration) forwards;
    -webkit-animation: slideOutLeft var(--duration) forwards;
    -moz-animation: slideOutLeft var(--duration) forwards;
    -ms-animation: slideOutLeft var(--duration) forwards;
    -o-animation: slideOutLeft var(--duration) forwards; }

.custom-slideOutRight {
  animation-delay: var(--delay) !important; }
  .custom-slideOutRight.active {
    animation: slideOutRight var(--duration) forwards;
    -webkit-animation: slideOutRight var(--duration) forwards;
    -moz-animation: slideOutRight var(--duration) forwards;
    -ms-animation: slideOutRight var(--duration) forwards;
    -o-animation: slideOutRight var(--duration) forwards; }

.custom-slideOutUp {
  animation-delay: var(--delay) !important; }
  .custom-slideOutUp.active {
    animation: slideOutUp var(--duration) forwards;
    -webkit-animation: slideOutUp var(--duration) forwards;
    -moz-animation: slideOutUp var(--duration) forwards;
    -ms-animation: slideOutUp var(--duration) forwards;
    -o-animation: slideOutUp var(--duration) forwards; }

.custom-hinge {
  animation-delay: var(--delay) !important; }
  .custom-hinge.active {
    animation: hinge var(--duration) forwards;
    -webkit-animation: hinge var(--duration) forwards;
    -moz-animation: hinge var(--duration) forwards;
    -ms-animation: hinge var(--duration) forwards;
    -o-animation: hinge var(--duration) forwards; }

.custom-jackInTheBox {
  opacity: 0;
  -webkit-transform: scale(0.1) rotate(30deg);
  transform: scale(0.1) rotate(30deg);
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
  animation-delay: var(--delay) !important; }
  .custom-jackInTheBox.active {
    animation: jackInTheBox var(--duration) forwards;
    -webkit-animation: jackInTheBox var(--duration) forwards;
    -moz-animation: jackInTheBox var(--duration) forwards;
    -ms-animation: jackInTheBox var(--duration) forwards;
    -o-animation: jackInTheBox var(--duration) forwards; }

.custom-rollIn {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  animation-delay: var(--delay) !important; }
  .custom-rollIn.active {
    animation: rollIn var(--duration) forwards;
    -webkit-animation: rollIn var(--duration) forwards;
    -moz-animation: rollIn var(--duration) forwards;
    -ms-animation: rollIn var(--duration) forwards;
    -o-animation: rollIn var(--duration) forwards; }

.custom-rollOut {
  animation-delay: var(--delay) !important; }
  .custom-rollOut.active {
    animation: rollOut var(--duration) forwards;
    -webkit-animation: rollOut var(--duration) forwards;
    -moz-animation: rollOut var(--duration) forwards;
    -ms-animation: rollOut var(--duration) forwards;
    -o-animation: rollOut var(--duration) forwards; }

.custom-lightSpeedInRight {
  -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
  transform: translate3d(100%, 0, 0) skewX(-30deg);
  opacity: 0;
  animation-delay: var(--delay) !important; }
  .custom-lightSpeedInRight.active {
    animation: lightSpeedInRight var(--duration) forwards;
    -webkit-animation: lightSpeedInRight var(--duration) forwards;
    -moz-animation: lightSpeedInRight var(--duration) forwards;
    -ms-animation: lightSpeedInRight var(--duration) forwards;
    -o-animation: lightSpeedInRight var(--duration) forwards; }

.custom-lightSpeedInLeft {
  -webkit-transform: translate3d(-100%, 0, 0) skewX(30deg);
  transform: translate3d(-100%, 0, 0) skewX(30deg);
  opacity: 0;
  animation-delay: var(--delay) !important; }
  .custom-lightSpeedInLeft.active {
    animation: lightSpeedInLeft var(--duration) forwards;
    -webkit-animation: lightSpeedInLeft var(--duration) forwards;
    -moz-animation: lightSpeedInLeft var(--duration) forwards;
    -ms-animation: lightSpeedInLeft var(--duration) forwards;
    -o-animation: lightSpeedInLeft var(--duration) forwards; }

.custom-lightSpeedOutRight {
  animation-delay: var(--delay) !important; }
  .custom-lightSpeedOutRight.active {
    animation: lightSpeedOutRight var(--duration) forwards;
    -webkit-animation: lightSpeedOutRight var(--duration) forwards;
    -moz-animation: lightSpeedOutRight var(--duration) forwards;
    -ms-animation: lightSpeedOutRight var(--duration) forwards;
    -o-animation: lightSpeedOutRight var(--duration) forwards; }

.custom-lightSpeedOutLeft {
  animation-delay: var(--delay) !important; }
  .custom-lightSpeedOutLeft.active {
    animation: lightSpeedOutLeft var(--duration) forwards;
    -webkit-animation: lightSpeedOutLeft var(--duration) forwards;
    -moz-animation: lightSpeedOutLeft var(--duration) forwards;
    -ms-animation: lightSpeedOutLeft var(--duration) forwards;
    -o-animation: lightSpeedOutLeft var(--duration) forwards; }

.custom-rotateIn {
  -webkit-transform: rotate3d(0, 0, 1, -200deg);
  transform: rotate3d(0, 0, 1, -200deg);
  opacity: 0;
  animation-delay: var(--delay) !important; }
  .custom-rotateIn.active {
    animation: rotateIn var(--duration) forwards;
    -webkit-animation: rotateIn var(--duration) forwards;
    -moz-animation: rotateIn var(--duration) forwards;
    -ms-animation: rotateIn var(--duration) forwards;
    -o-animation: rotateIn var(--duration) forwards; }

.custom-rotateInDownLeft {
  -webkit-transform: rotate3d(0, 0, 1, -45deg);
  transform: rotate3d(0, 0, 1, -45deg);
  opacity: 0;
  animation-delay: var(--delay) !important; }
  .custom-rotateInDownLeft.active {
    animation: rotateInDownLeft var(--duration) forwards;
    -webkit-animation: rotateInDownLeft var(--duration) forwards;
    -moz-animation: rotateInDownLeft var(--duration) forwards;
    -ms-animation: rotateInDownLeft var(--duration) forwards;
    -o-animation: rotateInDownLeft var(--duration) forwards; }

.custom-rotateInDownRight {
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
  transform: rotate3d(0, 0, 1, 45deg);
  opacity: 0;
  animation-delay: var(--delay) !important; }
  .custom-rotateInDownRight.active {
    animation: rotateInDownRight var(--duration) forwards;
    -webkit-animation: rotateInDownRight var(--duration) forwards;
    -moz-animation: rotateInDownRight var(--duration) forwards;
    -ms-animation: rotateInDownRight var(--duration) forwards;
    -o-animation: rotateInDownRight var(--duration) forwards; }

.custom-rotateInUpLeft {
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
  transform: rotate3d(0, 0, 1, 45deg);
  opacity: 0;
  animation-delay: var(--delay) !important; }
  .custom-rotateInUpLeft.active {
    animation: rotateInUpLeft var(--duration) forwards;
    -webkit-animation: rotateInUpLeft var(--duration) forwards;
    -moz-animation: rotateInUpLeft var(--duration) forwards;
    -ms-animation: rotateInUpLeft var(--duration) forwards;
    -o-animation: rotateInUpLeft var(--duration) forwards; }

.custom-rotateInUpRight {
  -webkit-transform: rotate3d(0, 0, 1, -90deg);
  transform: rotate3d(0, 0, 1, -90deg);
  opacity: 0;
  animation-delay: var(--delay) !important; }
  .custom-rotateInUpRight.active {
    animation: rotateInUpRight var(--duration) forwards;
    -webkit-animation: rotateInUpRight var(--duration) forwards;
    -moz-animation: rotateInUpRight var(--duration) forwards;
    -ms-animation: rotateInUpRight var(--duration) forwards;
    -o-animation: rotateInUpRight var(--duration) forwards; }

.custom-rotateOut {
  animation-delay: var(--delay) !important; }
  .custom-rotateOut.active {
    animation: rotateOut var(--duration) forwards;
    -webkit-animation: rotateOut var(--duration) forwards;
    -moz-animation: rotateOut var(--duration) forwards;
    -ms-animation: rotateOut var(--duration) forwards;
    -o-animation: rotateOut var(--duration) forwards; }

.custom-rotateOutDownLeft {
  animation-delay: var(--delay) !important; }
  .custom-rotateOutDownLeft.active {
    animation: rotateOutDownLeft var(--duration) forwards;
    -webkit-animation: rotateOutDownLeft var(--duration) forwards;
    -moz-animation: rotateOutDownLeft var(--duration) forwards;
    -ms-animation: rotateOutDownLeft var(--duration) forwards;
    -o-animation: rotateOutDownLeft var(--duration) forwards; }

.custom-rotateOutDownRight {
  animation-delay: var(--delay) !important; }
  .custom-rotateOutDownRight.active {
    animation: rotateOutDownRight var(--duration) forwards;
    -webkit-animation: rotateOutDownRight var(--duration) forwards;
    -moz-animation: rotateOutDownRight var(--duration) forwards;
    -ms-animation: rotateOutDownRight var(--duration) forwards;
    -o-animation: rotateOutDownRight var(--duration) forwards; }

.custom-rotateOutUpLeft {
  animation-delay: var(--delay) !important; }
  .custom-rotateOutUpLeft.active {
    animation: rotateOutUpLeft var(--duration) forwards;
    -webkit-animation: rotateOutUpLeft var(--duration) forwards;
    -moz-animation: rotateOutUpLeft var(--duration) forwards;
    -ms-animation: rotateOutUpLeft var(--duration) forwards;
    -o-animation: rotateOutUpLeft var(--duration) forwards; }

.custom-rotateOutUpRight {
  animation-delay: var(--delay) !important; }
  .custom-rotateOutUpRight.active {
    animation: rotateOutUpRight var(--duration) forwards;
    -webkit-animation: rotateOutUpRight var(--duration) forwards;
    -moz-animation: rotateOutUpRight var(--duration) forwards;
    -ms-animation: rotateOutUpRight var(--duration) forwards;
    -o-animation: rotateOutUpRight var(--duration) forwards; }

.custom-flip {
  animation-delay: var(--delay) !important; }
  .custom-flip.active {
    animation: flip var(--duration) forwards;
    -webkit-animation: flip var(--duration) forwards;
    -moz-animation: flip var(--duration) forwards;
    -ms-animation: flip var(--duration) forwards;
    -o-animation: flip var(--duration) forwards; }

.custom-flipInX {
  -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
  transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
  opacity: 0;
  animation-delay: var(--delay) !important; }
  .custom-flipInX.active {
    animation: flipInX var(--duration) forwards;
    -webkit-animation: flipInX var(--duration) forwards;
    -moz-animation: flipInX var(--duration) forwards;
    -ms-animation: flipInX var(--duration) forwards;
    -o-animation: flipInX var(--duration) forwards; }

.custom-flipInY {
  -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
  transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
  opacity: 0;
  animation-delay: var(--delay) !important; }
  .custom-flipInY.active {
    animation: flipInY var(--duration) forwards;
    -webkit-animation: flipInY var(--duration) forwards;
    -moz-animation: flipInY var(--duration) forwards;
    -ms-animation: flipInY var(--duration) forwards;
    -o-animation: flipInY var(--duration) forwards; }

.custom-flipOutX {
  animation-delay: var(--delay) !important; }
  .custom-flipOutX.active {
    animation: flipOutX var(--duration) forwards;
    -webkit-animation: flipOutX var(--duration) forwards;
    -moz-animation: flipOutX var(--duration) forwards;
    -ms-animation: flipOutX var(--duration) forwards;
    -o-animation: flipOutX var(--duration) forwards; }

.custom-flipOutY {
  animation-delay: var(--delay) !important; }
  .custom-flipOutY.active {
    animation: flipOutY var(--duration) forwards;
    -webkit-animation: flipOutY var(--duration) forwards;
    -moz-animation: flipOutY var(--duration) forwards;
    -ms-animation: flipOutY var(--duration) forwards;
    -o-animation: flipOutY var(--duration) forwards; }

.custom-flash {
  animation-delay: var(--delay) !important; }
  .custom-flash.active {
    animation: flash var(--duration) forwards;
    -webkit-animation: flash var(--duration) forwards;
    -moz-animation: flash var(--duration) forwards;
    -ms-animation: flash var(--duration) forwards;
    -o-animation: flash var(--duration) forwards; }

.custom-pulse {
  animation-delay: var(--delay) !important; }
  .custom-pulse.active {
    animation: pulse var(--duration) forwards;
    -webkit-animation: pulse var(--duration) forwards;
    -moz-animation: pulse var(--duration) forwards;
    -ms-animation: pulse var(--duration) forwards;
    -o-animation: pulse var(--duration) forwards; }

.custom-rubberBand {
  animation-delay: var(--delay) !important; }
  .custom-rubberBand.active {
    animation: rubberBand var(--duration) forwards;
    -webkit-animation: rubberBand var(--duration) forwards;
    -moz-animation: rubberBand var(--duration) forwards;
    -ms-animation: rubberBand var(--duration) forwards;
    -o-animation: rubberBand var(--duration) forwards; }

.custom-shakeX {
  animation-delay: var(--delay) !important; }
  .custom-shakeX.active {
    animation: shakeX var(--duration) forwards;
    -webkit-animation: shakeX var(--duration) forwards;
    -moz-animation: shakeX var(--duration) forwards;
    -ms-animation: shakeX var(--duration) forwards;
    -o-animation: shakeX var(--duration) forwards; }

.custom-shakeX {
  animation-delay: var(--delay) !important; }
  .custom-shakeX.active {
    animation: shakeX var(--duration) forwards;
    -webkit-animation: shakeX var(--duration) forwards;
    -moz-animation: shakeX var(--duration) forwards;
    -ms-animation: shakeX var(--duration) forwards;
    -o-animation: shakeX var(--duration) forwards; }

.custom-headShake {
  animation-delay: var(--delay) !important; }
  .custom-headShake.active {
    animation: headShake var(--duration) forwards;
    -webkit-animation: headShake var(--duration) forwards;
    -moz-animation: headShake var(--duration) forwards;
    -ms-animation: headShake var(--duration) forwards;
    -o-animation: headShake var(--duration) forwards; }

.custom-swing {
  animation-delay: var(--delay) !important; }
  .custom-swing.active {
    animation: swing var(--duration) forwards;
    -webkit-animation: swing var(--duration) forwards;
    -moz-animation: swing var(--duration) forwards;
    -ms-animation: swing var(--duration) forwards;
    -o-animation: swing var(--duration) forwards; }

.custom-tada {
  animation-delay: var(--delay) !important; }
  .custom-tada.active {
    animation: tada var(--duration) forwards;
    -webkit-animation: tada var(--duration) forwards;
    -moz-animation: tada var(--duration) forwards;
    -ms-animation: tada var(--duration) forwards;
    -o-animation: tada var(--duration) forwards; }

.custom-wobble {
  animation-delay: var(--delay) !important; }
  .custom-wobble.active {
    animation: wobble var(--duration) forwards;
    -webkit-animation: wobble var(--duration) forwards;
    -moz-animation: wobble var(--duration) forwards;
    -ms-animation: wobble var(--duration) forwards;
    -o-animation: wobble var(--duration) forwards; }

.custom-jello {
  animation-delay: var(--delay) !important; }
  .custom-jello.active {
    animation: jello var(--duration) forwards;
    -webkit-animation: jello var(--duration) forwards;
    -moz-animation: jello var(--duration) forwards;
    -ms-animation: jello var(--duration) forwards;
    -o-animation: jello var(--duration) forwards; }

.custom-heartBeat {
  animation-delay: var(--delay) !important; }
  .custom-heartBeat.active {
    animation: heartBeat var(--duration) forwards;
    -webkit-animation: heartBeat var(--duration) forwards;
    -moz-animation: heartBeat var(--duration) forwards;
    -ms-animation: heartBeat var(--duration) forwards;
    -o-animation: heartBeat var(--duration) forwards; }

.custom-backInDown {
  -webkit-transform: translateY(-1200px) scale(0.7);
  transform: translateY(-1200px) scale(0.7);
  opacity: 0.7;
  animation-delay: var(--delay) !important; }
  .custom-backInDown.active {
    animation: backInDown var(--duration) forwards;
    -webkit-animation: backInDown var(--duration) forwards;
    -moz-animation: backInDown var(--duration) forwards;
    -ms-animation: backInDown var(--duration) forwards;
    -o-animation: backInDown var(--duration) forwards; }

.custom-backInLeft {
  -webkit-transform: translateX(-2000px) scale(0.7);
  transform: translateX(-2000px) scale(0.7);
  opacity: 0.7;
  animation-delay: var(--delay) !important; }
  .custom-backInLeft.active {
    animation: backInLeft var(--duration) forwards;
    -webkit-animation: backInLeft var(--duration) forwards;
    -moz-animation: backInLeft var(--duration) forwards;
    -ms-animation: backInLeft var(--duration) forwards;
    -o-animation: backInLeft var(--duration) forwards; }

.custom-backInRight {
  -webkit-transform: translateX(2000px) scale(0.7);
  transform: translateX(2000px) scale(0.7);
  opacity: 0.7;
  animation-delay: var(--delay) !important; }
  .custom-backInRight.active {
    animation: backInRight var(--duration) forwards;
    -webkit-animation: backInRight var(--duration) forwards;
    -moz-animation: backInRight var(--duration) forwards;
    -ms-animation: backInRight var(--duration) forwards;
    -o-animation: backInRight var(--duration) forwards; }

.custom-backInUp {
  -webkit-transform: translateY(1200px) scale(0.7);
  transform: translateY(1200px) scale(0.7);
  opacity: 0.7;
  animation-delay: var(--delay) !important; }
  .custom-backInUp.active {
    animation: backInUp var(--duration) forwards;
    -webkit-animation: backInUp var(--duration) forwards;
    -moz-animation: backInUp var(--duration) forwards;
    -ms-animation: backInUp var(--duration) forwards;
    -o-animation: backInUp var(--duration) forwards; }

.custom-backOutDown {
  animation-delay: var(--delay) !important; }
  .custom-backOutDown.active {
    animation: backOutDown var(--duration) forwards;
    -webkit-animation: backOutDown var(--duration) forwards;
    -moz-animation: backOutDown var(--duration) forwards;
    -ms-animation: backOutDown var(--duration) forwards;
    -o-animation: backOutDown var(--duration) forwards; }

.custom-backOutLeft {
  animation-delay: var(--delay) !important; }
  .custom-backOutLeft.active {
    animation: backOutLeft var(--duration) forwards;
    -webkit-animation: backOutLeft var(--duration) forwards;
    -moz-animation: backOutLeft var(--duration) forwards;
    -ms-animation: backOutLeft var(--duration) forwards;
    -o-animation: backOutLeft var(--duration) forwards; }

.custom-backOutRight {
  animation-delay: var(--delay) !important; }
  .custom-backOutRight.active {
    animation: backOutRight var(--duration) forwards;
    -webkit-animation: backOutRight var(--duration) forwards;
    -moz-animation: backOutRight var(--duration) forwards;
    -ms-animation: backOutRight var(--duration) forwards;
    -o-animation: backOutRight var(--duration) forwards; }

.custom-backOutUp {
  animation-delay: var(--delay) !important; }
  .custom-backOutUp.active {
    animation: backOutUp var(--duration) forwards;
    -webkit-animation: backOutUp var(--duration) forwards;
    -moz-animation: backOutUp var(--duration) forwards;
    -ms-animation: backOutUp var(--duration) forwards;
    -o-animation: backOutUp var(--duration) forwards; }

.swiper-wrapper.row {
  flex-wrap: nowrap; }

.swiper.rows .col {
  margin-bottom: 0; }

.swiper-container {
  overflow: hidden; }

.swiper-slide {
  height: initial;
  overflow: hidden; }

.swiper-pagination {
  position: static;
  margin-top: 1.6rem; }
  .swiper-pagination-bullet {
    --circle: -0.4rem;
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 1;
    position: relative;
    margin: 0 0.3rem !important;
    transition: 0.4s;
    border-radius: 0.4rem; }
    .swiper-pagination-bullet::before {
      content: '';
      position: absolute;
      top: var(--circle);
      right: var(--circle);
      bottom: var(--circle);
      left: var(--circle);
      border: 1px solid var(--color-pri);
      border-radius: 100rem;
      opacity: 0;
      transition: 0.1s ease-in-out;
      display: none; }
    .swiper-pagination-bullet-active {
      background-color: var(--color-pri);
      width: 5.5rem;
      border-radius: 0.4rem; }
      .swiper-pagination-bullet-active::before {
        opacity: 1; }

.swiper-control {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  --size: 4rem; }
  @media screen and (max-width: 1250px) {
    .swiper-control {
      --size: 3rem; } }
  .swiper-control-btn {
    width: var(--size);
    height: var(--size);
    border-radius: 100rem;
    background-color: white;
    border: 0.1rem solid var(--color-pri);
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    z-index: 5;
    position: relative;
    overflow: hidden;
    font-size: 1.6rem;
    color: var(--color-pri); }
    .swiper-control-btn::after {
      position: absolute;
      content: "";
      width: 0;
      height: 100%;
      top: 0;
      right: 0;
      background: var(--color-pri);
      transition: all 0.4s ease;
      z-index: 1;
      pointer-events: none; }
    .swiper-control-btn > * {
      position: relative;
      z-index: 2; }
    .swiper-control-btn.swiper-button-disabled {
      cursor: default;
      box-shadow: none;
      opacity: 0.5;
      display: none; }
    @media (hover: hover) {
      .swiper-control-btn:hover:not(.swiper-button-disabled) {
        color: white !important; }
        .swiper-control-btn:hover:not(.swiper-button-disabled)::after {
          left: 0;
          width: 100%; } }
  .swiper-control.white .swiper-control-btn {
    background-color: transparent;
    border-color: white;
    color: white; }
  @media screen and (max-width: 1350px) {
    .swiper-control.white.mobile-plus .swiper-control-btn {
      background-color: white;
      border-color: #D72429;
      color: #D72429; } }
  .swiper-control.posi .swiper-control-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%); }
  .swiper-control.posi.minus .swiper-prev {
    right: calc(100% + 4rem); }
    @media screen and (max-width: 1350px) {
      .swiper-control.posi.minus .swiper-prev {
        right: unset;
        left: calc(var(--size) / 2 * -1); } }
  .swiper-control.posi.minus .swiper-next {
    left: calc(100% + 4rem); }
    @media screen and (max-width: 1350px) {
      .swiper-control.posi.minus .swiper-next {
        left: unset;
        right: calc(var(--size) / 2 * -1); } }
  @media screen and (max-width: 1199px) {
    .swiper-control.posi.minus.mobile-plus .swiper-prev {
      left: calc((var(--size) / 2 * -1) + (1.5rem / 2)); }
    .swiper-control.posi.minus.mobile-plus .swiper-next {
      right: calc((var(--size) / 2 * -1) + (1.5rem / 2)); } }
  .swiper-control.posi.midle .swiper-prev {
    left: calc(var(--size) / 2 * -1); }
  .swiper-control.posi.midle .swiper-next {
    right: calc(var(--size) / 2 * -1); }
  .swiper-control.posi.plus .swiper-prev {
    left: 1rem; }
  .swiper-control.posi.plus .swiper-next {
    right: 1rem; }
  @media screen and (max-width: 1199px) {
    .swiper-control.posi.mobile-plus .swiper-prev {
      left: calc((var(--size) / 2 * -1) + (1.5rem / 2)); }
    .swiper-control.posi.mobile-plus .swiper-next {
      right: calc((var(--size) / 2 * -1) + (1.5rem / 2)); } }
  .swiper-control.custom .swiper-control-btn {
    top: 25%;
    background-color: transparent;
    border: unset !important; }
    .swiper-control.custom .swiper-control-btn::after {
      display: none; }
    .swiper-control.custom .swiper-control-btn i {
      font-size: 2rem; }
      @media only screen and (max-width: 1200px) {
        .swiper-control.custom .swiper-control-btn i {
          font-size: 1.8rem; } }
      @media only screen and (max-width: 800px) {
        .swiper-control.custom .swiper-control-btn i {
          font-size: 1.6rem; } }
    .swiper-control.custom .swiper-control-btn:hover i {
      color: var(--color-hover); }

.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.1);
  height: .1rem !important; }

.swiper-pagination-progressbar-fill {
  background-color: #FF8387 !important; }

.breadcrumb {
  padding-top: 4rem;
  padding-bottom: 3.2rem; }
  @media screen and (max-width: 800px) {
    .breadcrumb {
      padding-top: calc(4rem / 2); } }
  @media screen and (max-width: 800px) {
    .breadcrumb {
      padding-bottom: calc(3.2rem / 2); } }
  @media screen and (min-width: 1199px) {
    .breadcrumb-wrapper {
      width: fit-content; } }
  .breadcrumb-list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
    gap: .8rem; }
  .breadcrumb-item {
    font-family: "Inter", sans-serif;
    font-size: 2rem;
    display: flex;
    align-items: center;
    gap: .8rem;
    font-weight: 400;
    color: var(--Gray-500);
    text-wrap: pretty; }
    @media only screen and (max-width: 1200px) {
      .breadcrumb-item {
        font-size: 1.8rem; } }
    @media only screen and (max-width: 800px) {
      .breadcrumb-item {
        font-size: 1.6rem; } }
    .breadcrumb-item:not(:first-child)::before {
      display: inline-block;
      content: '\f105';
      font-family: "Font Awesome 6 Pro";
      border-radius: 100rem;
      vertical-align: middle; }
    .breadcrumb-item:last-child {
      color: var(--Gray-500);
      pointer-events: none; }
    .breadcrumb-item:hover {
      text-decoration: underline; }
      .breadcrumb-item:hover:not(:first-child)::before {
        text-decoration: underline; }
  .breadcrumb-link {
    color: inherit;
    transition: .2s ease-in-out; }

.btn {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  width: fit-content;
  text-align: center;
  cursor: pointer;
  border-radius: 5rem;
  font-weight: 600;
  font-family: "Mulish", sans-serif;
  transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  -ms-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition-delay: 0s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -ms-transition-delay: 0s;
  -o-transition-delay: 0s;
  overflow: hidden;
  position: relative;
  background: transparent;
  gap: 1rem;
  color: #ffffff;
  font-size: 1.8rem;
  height: 4.8rem;
  background-color: var(--color-pri);
  border: 0.1rem solid var(--color-pri); }
  .btn > * {
    position: relative;
    z-index: 2; }
  .btn:after {
    position: absolute;
    content: "";
    width: 120%;
    height: 102%;
    top: 0;
    left: -13%;
    background: #ffffff;
    transition: transform 0.6s;
    z-index: 0;
    transform-origin: right;
    transform: skew(30deg) scaleX(0); }
  .btn .txt {
    position: relative;
    display: grid;
    place-items: center;
    height: 100%;
    padding: 1.4rem 2.5rem;
    transition: 0.4s;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    gap: 1rem;
    color: white;
    flex-shrink: 0; }
  .btn img {
    width: 4rem;
    height: 4rem;
    filter: brightness(0) invert(1); }
  .btn:hover .txt {
    color: var(--color-pri); }
  .btn:hover img {
    filter: brightness(0) saturate(100%) invert(35%) sepia(49%) saturate(6636%) hue-rotate(340deg) brightness(86%) contrast(121%); }
  .btn:hover::after {
    transform: skew(30deg) scaleX(1);
    transform-origin: left; }
  .btn.black {
    background-color: black; }
    .btn.black .txt {
      color: white; }
    @media (hover: hover) {
      .btn.black:hover .txt {
        color: #ffffff; } }
  .btn.white {
    background-color: white;
    border: 0.1rem solid var(--color-pri); }
    .btn.white .txt {
      color: var(--color-pri); }
    .btn.white::after {
      background: var(--color-pri); }
    @media (hover: hover) {
      .btn.white:hover .txt {
        color: #ffffff; } }
  .btn.bd-white {
    background-color: white;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    border: unset; }
    .btn.bd-white .txt {
      color: var(--color-pri);
      flex-direction: row-reverse; }
      .btn.bd-white .txt img {
        filter: unset; }
    .btn.bd-white::after {
      background: var(--color-pri); }
    @media (hover: hover) {
      .btn.bd-white:hover .txt {
        color: #ffffff; }
        .btn.bd-white:hover .txt img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(10%) hue-rotate(238deg) brightness(104%) contrast(103%); } }
  .btn.second {
    background-color: var(--color-second);
    border: 0.1rem solid var(--color-second); }
    .btn.second .txt {
      color: white; }
    .btn.second::after {
      background-color: white; }
    @media (hover: hover) {
      .btn.second:hover img {
        filter: none; }
      .btn.second:hover .txt {
        color: var(--color-second); } }
  .btn.third {
    background-color: white;
    border: 0.1rem solid var(--color-second); }
    .btn.third .txt {
      color: var(--color-second); }
    .btn.third::after {
      background-color: var(--color-second); }
    .btn.third img {
      filter: none; }
    @media (hover: hover) {
      .btn.third:hover .txt {
        color: white; }
      .btn.third:hover img {
        filter: brightness(0) invert(1); } }
  .btn-gr {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem; }
  .btn.center {
    margin-left: auto;
    margin-right: auto; }
  .btn.full {
    width: 100%; }
  .btn.btn-pri {
    padding: 0 1.2rem; }
    @media screen and (max-width: 500px) {
      .btn.btn-pri {
        padding: 0; } }
    @media screen and (max-width: 1200px) {
      .btn.btn-pri .txt {
        font-size: 1.4rem; } }
  .btn.bg-trans {
    background-color: transparent; }
    .btn.bg-trans .txt {
      color: #D72429; }
    .btn.bg-trans::after {
      background-color: #D72429; }
    .btn.bg-trans:hover .txt {
      color: #ffffff; }
  .btn.arrow .txt {
    padding-right: .4rem;
    gap: 2rem; }
  .btn.w200 {
    width: 20rem;
    max-width: 100%; }

.seeDetail {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
  transition: 0.4s; }
  @media (hover: hover) {
    .seeDetail:hover {
      color: var(--color-second); } }

.link {
  display: block;
  color: #D72429;
  width: 100%;
  font-weight: 700;
  font-size: 1.6rem;
  transition: .3s; }
  .link:hover {
    color: var(--color-second); }
  .link.center {
    text-align: center; }

.input {
  font-family: "Mulish", sans-serif;
  width: 100%; }
  .input-area {
    height: auto; }

.re-label {
  color: #000000;
  font-weight: 700;
  margin-bottom: 0.8rem;
  display: block;
  font-size: 1.4rem; }

.re-input {
  padding: 1.4rem;
  width: 100%;
  font-family: "Mulish", sans-serif;
  border: none;
  outline: none;
  display: block;
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  border-radius: .8rem;
  font-size: 1.4rem;
  font-weight: 600; }
  .re-input:focus {
    border-color: #FF8387; }
  .re-input.placeholder {
    color: #686060; }
  .re-input:-moz-placeholder {
    color: #686060; }
  .re-input::-moz-placeholder {
    color: #686060; }
  .re-input:-ms-input-placeholder {
    color: #686060; }
  .re-input::-webkit-input-placeholder {
    color: #686060; }

textarea {
  resize: none; }

form .wpcf7-not-valid-tip {
  color: #D72429;
  bottom: .1rem;
  right: .6rem; }

form .btn .wpcf7-spinner {
  position: absolute; }

form .wpcf7-form-control-wrap {
  display: block;
  position: relative; }

.wpcf7-response-output {
  text-align: center;
  color: #D72429 !important; }

.ft-regi-btn {
  cursor: pointer; }

.page-numbers {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 5;
  margin-top: 1.6rem; }
  .page-numbers > li:not(:first-child) {
    margin-left: 0.8rem; }
  .page-numbers > li > .page-numbers {
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 100rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    background-color: transparent;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    color: #4C4B50;
    font-weight: 700;
    font-size: 1.6rem;
    transition: .3s; }
    .page-numbers > li > .page-numbers::after {
      position: absolute;
      content: "";
      width: 0;
      height: 100%;
      top: 0;
      right: 0;
      background: var(--color-pri);
      transition: all 0.4s ease;
      z-index: 1;
      pointer-events: none; }
    .page-numbers > li > .page-numbers:after {
      z-index: -1; }
    @media (hover: hover) {
      .page-numbers > li > .page-numbers:hover:not(.dot) {
        color: white; }
        .page-numbers > li > .page-numbers:hover:not(.dot)::after {
          left: 0;
          width: 100%; } }
    .page-numbers > li > .page-numbers.current {
      color: white; }
      .page-numbers > li > .page-numbers.current::after {
        left: 0;
        width: 100%; }
    .page-numbers > li > .page-numbers.dot {
      background-color: transparent; }
    .page-numbers > li > .page-numbers i {
      font-weight: 400; }

.sidefix {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  right: 14.5rem;
  bottom: 13.2rem;
  z-index: 5;
  gap: 2rem; }
  @media screen and (max-width: 768px) {
    .sidefix {
      right: 1.5rem; } }
  .sidefix-item {
    position: relative;
    padding: 1.2rem;
    background-color: var(--color-pri);
    border-radius: 1.6rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    cursor: pointer; }
  .sidefix-link {
    width: 3.2rem;
    height: 3.2rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center; }
    .sidefix-link img {
      width: inherit;
      height: inherit;
      animation: jittery 2.5s linear infinite; }

.sideright {
  border: 1px solid #E2E8F0;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  right: 0rem;
  bottom: 6rem;
  z-index: 5;
  background: #ffffff;
  border-radius: 1.6rem 0rem 0rem 1.6rem; }
  .sideright-item:nth-child(1) .sideright-link img {
    animation: phoneRing 2.5s linear infinite; }
  .sideright-item:nth-child(2) .sideright-link img {
    animation: note 2.5s linear infinite; }
  .sideright-item:nth-child(3) .sideright-link img {
    animation: DownUp 2.5s linear infinite; }
  .sideright-item {
    width: 10.6rem;
    height: 10.6rem;
    position: relative;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    cursor: pointer; }
    .sideright-item:not(:first-child) {
      border-top: 1px solid #E2E8F0; }
    .sideright-item:hover .sideright-link .text {
      color: #D72429; }
    .sideright-item:hover .sideright-link img {
      scale: 1.1; }
  .sideright-link {
    width: 2.4rem;
    height: 2.4rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: column;
    gap: .4rem;
    transition: .3s; }
    .sideright-link img {
      width: inherit;
      height: inherit;
      transition: .3s; }
    .sideright-link .text {
      text-wrap: nowrap;
      color: var(--Neutral-Gray-900, #0F172A);
      text-align: center;
      font-family: "Roboto";
      font-size: 1.6rem;
      font-weight: 500;
      text-transform: uppercase;
      transition: .3s; }
  @media screen and (max-width: 768px) {
    .sideright {
      bottom: 22rem; }
      .sideright-item {
        width: 7rem;
        height: 7rem; }
      .sideright-link {
        width: 2rem;
        height: 2rem; }
        .sideright-link .text {
          font-size: 1rem; } }

.t-title {
  font-size: 4.4rem;
  font-weight: 700;
  color: #D72429; }
  @media only screen and (max-width: 1200px) {
    .t-title {
      font-size: 4rem; } }
  @media only screen and (max-width: 800px) {
    .t-title {
      font-size: 3rem; } }
  @media screen and (max-width: 600px) {
    .t-title {
      font-size: 2.2rem; } }

.title-sub {
  font-weight: 700;
  color: #408630; }
  .title-sub.dc {
    --w: 1.9rem;
    display: inline-block;
    padding-right: calc(var(--w) + .4rem);
    position: relative; }
    .title-sub.dc::after {
      content: '';
      position: absolute;
      background-image: url(../assets/images/la-dc-1.svg);
      width: var(--w);
      height: var(--w);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      right: 0;
      top: -.4rem;
      clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%); }
    .title-sub.dc.is-inview::after {
      animation: laRight 1s ease-in-out .3s forwards; }

.t-title-second {
  font-size: 3.2rem;
  font-weight: 700;
  color: #D72429; }
  @media only screen and (max-width: 1200px) {
    .t-title-second {
      font-size: 2.7rem; } }
  @media only screen and (max-width: 800px) {
    .t-title-second {
      font-size: 2.2rem; } }

@keyframes laRight {
  0% {
    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%); }
  50% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); }
  75% {
    transform: rotate(10deg); }
  100% {
    transform: rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); } }

.t-center {
  text-align: center; }

.fw-1 {
  font-weight: 100; }

.fw-2 {
  font-weight: 200; }

.fw-3 {
  font-weight: 300; }

.fw-5 {
  font-weight: 500; }

.fw-6 {
  font-weight: 600; }

.fw-7 {
  font-weight: 700; }

.fw-8 {
  font-weight: 800; }

.fw-b {
  font-weight: bold; }

.fsi {
  font-style: italic; }

.t-up {
  text-transform: uppercase; }

.t9 {
  font-size: 0.9rem; }

.t11 {
  font-size: 1.1rem; }

.t12 {
  font-size: 1.2rem; }

.t14 {
  font-size: 1.4rem; }

.t16 {
  font-size: 1.6rem; }

.t18 {
  font-size: 1.8rem; }
  @media only screen and (max-width: 1200px) {
    .t18 {
      font-size: 1.6rem; } }
  @media only screen and (max-width: 800px) {
    .t18 {
      font-size: 1.5rem; } }

.t20 {
  font-size: 2rem; }
  @media only screen and (max-width: 1200px) {
    .t20 {
      font-size: 1.8rem; } }
  @media only screen and (max-width: 800px) {
    .t20 {
      font-size: 1.6rem; } }

.t24 {
  font-size: 2.4rem; }
  @media only screen and (max-width: 1200px) {
    .t24 {
      font-size: 2rem; } }
  @media only screen and (max-width: 800px) {
    .t24 {
      font-size: 1.7rem; } }

.t32 {
  font-size: 3.2rem; }
  @media only screen and (max-width: 1200px) {
    .t32 {
      font-size: 2.7rem; } }
  @media only screen and (max-width: 800px) {
    .t32 {
      font-size: 2.2rem; } }

.t40 {
  font-size: 4rem; }
  @media only screen and (max-width: 1200px) {
    .t40 {
      font-size: 3.5rem; } }
  @media only screen and (max-width: 800px) {
    .t40 {
      font-size: 2.7rem; } }

.t48 {
  font-size: 4.8rem; }
  @media only screen and (max-width: 1200px) {
    .t48 {
      font-size: 4.3rem; } }
  @media only screen and (max-width: 800px) {
    .t48 {
      font-size: 3.3rem; } }

.t56 {
  font-size: 5.6rem; }
  @media only screen and (max-width: 1200px) {
    .t56 {
      font-size: 5.1rem; } }
  @media only screen and (max-width: 800px) {
    .t56 {
      font-size: 4rem; } }
  @media only screen and (max-width: 500px) {
    .t56 {
      font-size: 2.4rem; } }

.t64 {
  font-size: 6.4rem; }
  @media only screen and (max-width: 1200px) {
    .t64 {
      font-size: 3.9rem; } }
  @media only screen and (max-width: 800px) {
    .t64 {
      font-size: 2.9rem; } }

.t72 {
  font-size: 7.2rem; }
  @media only screen and (max-width: 1200px) {
    .t72 {
      font-size: 5rem; } }
  @media only screen and (max-width: 800px) {
    .t72 {
      font-size: 4rem; } }

.c-pri {
  color: #D72429; }

.c-second {
  color: #FF8387; }

.c-third {
  color: #408630; }

.c-four {
  color: #FCB445; }

.c-five {
  color: #f05123; }

.c-white {
  color: white; }

.c-black {
  color: #000000; }

.c-txt {
  color: #686060; }

.c-txt-s {
  color: #363636; }

.bc-pri {
  background-color: #D72429; }

.bc-second {
  background-color: #FF8387; }

.bc-third {
  background-color: #408630; }

.bc-four {
  background-color: #FCB445; }

.bc-five {
  background-color: #f05123; }

.over-hd {
  overflow: hidden; }

.z-i-1 {
  z-index: 1; }

.z-i-2 {
  z-index: 2; }

.z-i-3 {
  z-index: 3; }

.z-i-4 {
  z-index: 4; }

.z-i-5 {
  z-index: 5; }

.m-mid {
  margin: 0 auto; }

.m-end {
  margin-left: auto; }

.m-top {
  margin-top: 2.4rem; }

.m-start {
  margin-right: auto; }

.f-mid {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center; }

.f-end {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-end; }

.lg-download,
#lg-actual-size,
#lg-share {
  display: none; }

.lg-backdrop,
.lg-outer {
  z-index: 99999; }

.lg-outer .lg-thumb-item {
  border: 0.2rem solid #D72429;
  transition: 0.2s ease; }
  .lg-outer .lg-thumb-item.active {
    border-color: #408630; }
  @media (hover: hover) {
    .lg-outer .lg-thumb-item:hover {
      border-color: #408630; } }

.lg-prev.lg-icon::after {
  display: none; }

.lg-next.lg-icon::before {
  display: none; }

.lg-prev.lg-icon,
.lg-next.lg-icon {
  width: 4rem;
  height: 4rem; }
  .lg-prev.lg-icon i,
  .lg-next.lg-icon i {
    background: #F36F3F;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent; }

.lg-outer .lg-thumb-outer {
  background-color: #000; }

.lg-close.lg-icon {
  background: #F36F3F;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent; }

.lg-outer .lg-toogle-thumb {
  background: #F36F3F;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  width: 4rem;
  height: 4rem; }

.lg-img-wrap {
  border-radius: .8rem; }

.lg-thumb-item {
  border-radius: .8rem; }

.lg-outer .lg-thumb {
  margin: 0 auto -5px; }

.tabPanel {
  display: none; }

.tabBtn {
  cursor: pointer; }

.orders-table .status,
table .status {
  font-weight: 700; }
  .orders-table .status.waiting,
  table .status.waiting {
    color: #F2C524; }
  .orders-table .status.success,
  table .status.success {
    color: #408630; }
  .orders-table .status.cancle,
  table .status.cancle {
    color: #D31D1D; }

.slider {
  position: relative; }

.relative {
  position: relative; }

.head {
  margin-bottom: 3.2rem; }
  .head.space {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between; }
  .head.center {
    align-items: center; }
  .head.end {
    align-items: flex-end; }
  .head.start {
    align-items: flex-start; }
  @media screen and (max-width: 700px) {
    .head.mb-center {
      text-align: center; }
      .head.mb-center .left {
        width: 100%; }
      .head.mb-center .right {
        width: 100%; }
      .head.mb-center .cdw {
        justify-content: center; }
      .head.mb-center .btn {
        margin: 0 auto; } }

.hv-bd {
  position: relative; }
  .hv-bd::before {
    content: '';
    position: absolute;
    left: 0.8rem;
    top: 0.8rem;
    right: 0.8rem;
    bottom: 0.8rem;
    border: 0.1rem solid white;
    z-index: 1;
    transition: .3s all ease-in-out;
    transform: scale(1.1);
    opacity: 0;
    border-radius: 1rem; }
  .hv-bd img {
    transition: .3s all ease-in-out; }
  @media (hover: hover) {
    .hv-bd:hover::before {
      opacity: 1;
      transform: scale(1); }
    .hv-bd:hover img {
      transform: translate(-50%, -50%) scale(1.05);
      -webkit-transform: translate(-50%, -50%) scale(1.05);
      -moz-transform: translate(-50%, -50%) scale(1.05);
      -o-transform: translate(-50%, -50%) scale(1.05); } }

.gItem {
  cursor: pointer; }

.parallaxJS img {
  position: relative; }

@media screen and (min-width: 1201px) {
  .hidden-desk {
    display: none !important; } }

@media screen and (max-width: 1199px) {
  .hidden-mobile {
    display: none !important; } }

.full {
  width: 100%; }

.calendar-table td {
  width: 3.2rem !important;
  height: 3.2rem !important;
  border: 0.4rem solid white !important;
  border-radius: 1.2rem !important; }

.calendar-table td.today {
  background: #DFE4EA;
  color: var(--color-pri); }

.calendar-table td.active {
  background: var(--color-pri);
  color: white; }
  @media (hover: hover) {
    .calendar-table td.active:hover {
      background: var(--color-pri); } }

.calendar-table th.next {
  background: #DFE4EA;
  width: 3.2rem !important;
  height: 3.2rem !important;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center; }
  .calendar-table th.next span {
    transform: none !important;
    border: none !important; }
    .calendar-table th.next span::after {
      content: "\f054";
      font-family: "Font Awesome 6 Pro";
      color: var(--color-pri); }

.calendar-table th.prev {
  background: #DFE4EA;
  width: 3.2rem !important;
  height: 3.2rem !important;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center; }
  .calendar-table th.prev span {
    transform: none !important;
    border: none !important; }
    .calendar-table th.prev span::after {
      content: "\f053";
      font-family: "Font Awesome 6 Pro";
      color: var(--color-pri); }

.daterangepicker {
  border: none;
  box-shadow: 0px 6px 15px 0px rgba(64, 79, 104, 0.05);
  border-radius: 1rem; }
  .daterangepicker::before, .daterangepicker::after {
    content: none; }

.star {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center; }
  .star-flex {
    gap: 0.3rem; }
  .star .star-list {
    height: unset; }
  .star .star-empty i {
    color: #dedede;
    font-size: 1.4rem; }
  .star .star-filter i {
    color: #FFC107;
    font-size: 1.4rem; }

.rating {
  display: flex;
  gap: 0.5rem; }
  .rating .stars {
    cursor: pointer; }
    .rating .stars i {
      color: #FCC21F; }
    .rating .stars.active i {
      color: #FCC21F;
      font-weight: 900; }
  .rating .star {
    width: 2rem;
    height: 2rem;
    background: gray;
    display: block; }

.form {
  --height: 4.4rem;
  --bdr: unset;
  --bdcl: var(--color-pri); }
  .form-list {
    --cg: 2.4rem;
    --rg: 2.4rem; }
    @media screen and (max-width: 1200px) {
      .form-list {
        --cg: 1.2rem;
        --rg: 1.2rem; } }
  .form-ip .text {
    display: block;
    margin-bottom: 1rem;
    color: var(--color-text);
    font-weight: 500; }
    .form-ip .text .red {
      color: red;
      font-weight: 400; }
  .form-ip .input {
    position: relative; }
    .form-ip .input .seepassJS {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      color: #dedede;
      cursor: pointer; }
  .form-ip input,
  .form-ip textarea {
    border: none;
    border-bottom: 1px solid #E2E8F0;
    outline: none;
    padding: 0rem;
    width: 100%;
    background-color: transparent;
    transition: 0.4s;
    font-size: 1.6rem;
    height: 4.4rem; }
    .form-ip input.placeholder,
    .form-ip textarea.placeholder {
      color: #475569;
      font-family: "Inter", sans-serif; }
    .form-ip input:-moz-placeholder,
    .form-ip textarea:-moz-placeholder {
      color: #475569;
      font-family: "Inter", sans-serif; }
    .form-ip input::-moz-placeholder,
    .form-ip textarea::-moz-placeholder {
      color: #475569;
      font-family: "Inter", sans-serif; }
    .form-ip input:-ms-input-placeholder,
    .form-ip textarea:-ms-input-placeholder {
      color: #475569;
      font-family: "Inter", sans-serif; }
    .form-ip input::-webkit-input-placeholder,
    .form-ip textarea::-webkit-input-placeholder {
      color: #475569;
      font-family: "Inter", sans-serif; }
    .form-ip input:focus,
    .form-ip textarea:focus {
      border-color: var(--color-pri); }
  .form-ip textarea {
    min-height: 6.7rem; }
  .form-ip.per5 {
    width: 50%; }
    @media screen and (max-width: 500px) {
      .form-ip.per5 {
        width: 100%; } }
  .form-ip .select2-container--default .select2-selection--single {
    border-radius: 0.8rem;
    background-color: transparent;
    border-color: var(--color-pri); }
  .form-ip .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #64748B;
    font-size: 1.6rem; }
  .form-ip .select2-container--default.select2-container--open.select2-container--below .select2-selection--single {
    border-radius: 0.8rem !important; }
  .form-ip .select2-container .select2-selection--single .select2-selection__rendered {
    font-weight: 400;
    font-size: 1.6rem; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-2 {
  margin-bottom: 0.2rem; }

.mb-4 {
  margin-bottom: 0.4rem; }

.mb-6 {
  margin-bottom: 0.6rem; }

.mb-8 {
  margin-bottom: 0.8rem; }

.mb-8s:not(:last-child) {
  margin-bottom: 0.8rem; }

.mb-10 {
  margin-bottom: 1rem; }

.mb-10s:not(:last-child) {
  margin-bottom: 1rem; }

.mb-12 {
  margin-bottom: 1.2rem; }

.mb-12s:not(:last-child) {
  margin-bottom: 1.2rem; }

.mb-14 {
  margin-bottom: 1.4rem; }

.mb-14s:not(:last-child) {
  margin-bottom: 1.4rem; }

.mb-15 {
  margin-bottom: 1.5rem; }

.mb-15s:not(:last-child) {
  margin-bottom: 1.5rem; }

.mb-16 {
  margin-bottom: 1.6rem; }

.mb-16s:not(:last-child) {
  margin-bottom: 1.6rem; }

.mb-18 {
  margin-bottom: 1.8rem; }

.mb-18s:not(:last-child) {
  margin-bottom: 1.8rem; }

.mb-20 {
  margin-bottom: 2rem; }

.mb-20s:not(:last-child) {
  margin-bottom: 2rem; }

.mb-24 {
  margin-bottom: 2.4rem; }

.mb-24s:not(:last-child) {
  margin-bottom: 2.4rem; }

.mb-25 {
  margin-bottom: 2.5rem; }

.mb-25s:not(:last-child) {
  margin-bottom: 2.5rem; }

.mb-30 {
  margin-bottom: 3rem; }

.mb-30s:not(:last-child) {
  margin-bottom: 3rem; }

.mb-32 {
  margin-bottom: 3.2rem; }

.mb-32s:not(:last-child) {
  margin-bottom: 3.2rem; }

.mb-40 {
  margin-bottom: 4rem; }

.mb-40s:not(:last-child) {
  margin-bottom: 4rem; }

.mb-48 {
  margin-bottom: 4.8rem; }

.mb-48s:not(:last-child) {
  margin-bottom: 4.8rem; }

.mb-56 {
  margin-bottom: 5.6rem; }

.mb-56s:not(:last-child) {
  margin-bottom: 5.6rem; }

.mb-64 {
  margin-bottom: 6.4rem; }

.mb-64s:not(:last-child) {
  margin-bottom: 6.4rem; }

.mb-72 {
  margin-bottom: 7.2rem; }

.mb-72s:not(:last-child) {
  margin-bottom: 7.2rem; }

.mb-80 {
  margin-bottom: 8rem; }

.mb-80s:not(:last-child) {
  margin-bottom: 8rem; }

.ss-mg {
  margin: var(--pd-sc) 0; }

.ss-mg-t {
  margin-top: var(--pd-sc); }

.ss-mg-b {
  margin-bottom: var(--pd-sc); }

.ss-pd {
  padding: var(--pd-sc) 0; }

.ss-pd-t {
  padding-top: var(--pd-sc); }

.ss-pd-b {
  padding-bottom: var(--pd-sc); }

.recheck-item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  cursor: pointer; }
  .recheck-item.active .recheck-checkbox::before {
    opacity: 1; }
  .recheck-item.active .recheck-radio {
    border: 0.1rem solid #D72429; }
    .recheck-item.active .recheck-radio::before {
      opacity: 1; }

.recheck-checkbox {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 0.4rem;
  background-color: transparent;
  border: 1px solid #D72429;
  position: relative;
  flex-shrink: 0; }
  .recheck-checkbox::before {
    content: "";
    position: absolute;
    left: -0.2rem;
    top: -0.2rem;
    right: -0.2rem;
    bottom: -0.2rem;
    background-image: url(../assets/images/tick.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-size: cover;
    transition: 0.1s ease-in-out;
    opacity: 0; }

.recheck-radio {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 100rem;
  border: 0.1rem solid #363636;
  flex-shrink: 0;
  position: relative; }
  .recheck-radio::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 100rem;
    background-color: #D72429;
    opacity: 0; }

.recheck-method {
  width: 4.8rem;
  height: 4.8rem;
  border: 0.1rem solid #EBEBEB;
  border-radius: 0.8rem;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  margin-left: 0.8rem; }
  .recheck-method img {
    width: 2.4rem;
    height: 2.4rem; }

.recheck-text {
  margin-left: 0.8rem;
  line-height: 1.1;
  color: #686060; }

.star {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-content: start; }
  .star-list {
    position: relative;
    height: 2rem; }
  .star-flex {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex; }
    .star-flex .icon {
      width: 1.8rem;
      height: 1.8rem; }
      .star-flex .icon:not(:first-child) {
        margin-left: 0.4rem; }
  .star-filter {
    overflow: hidden;
    position: absolute;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    left: 0;
    height: 100%;
    width: 0; }

@media screen and (max-width: 1199px) {
  .side-fixed {
    position: fixed;
    width: 300px;
    max-width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #EEEEEE;
    z-index: 51;
    transition: 0.4s ease-in-out;
    opacity: 0;
    transform: translateX(110%);
    visibility: hidden; }
    .side-fixed.open {
      opacity: 1;
      transform: translate(0, 0);
      visibility: visible; } }

@media screen and (max-width: 1199px) {
  .side-fixed-wrap {
    padding: 5.5rem 1.5rem 1.5rem 1.5rem;
    width: 100%;
    height: 100%;
    overflow-y: auto; } }

.side-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 50;
  transition: 0.5s ease-in-out;
  opacity: 0;
  transform: translateX(110%);
  visibility: hidden; }
  .side-overlay.open {
    opacity: 1;
    transform: translate(0, 0);
    visibility: visible; }
  @media screen and (min-width: 1201px) {
    .side-overlay {
      display: none; } }

.side-open:not(.no-style) {
  width: 4rem;
  height: 4rem;
  position: fixed;
  right: 0;
  z-index: 40;
  background-color: #D72429;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  border-radius: 100rem 0 0 100rem;
  cursor: pointer;
  transition: 0.4s ease-in-out; }
  .side-open:not(.no-style).close {
    opacity: 0;
    transform: translate(100%, -50%);
    visibility: visible; }
  @media screen and (min-width: 1201px) {
    .side-open:not(.no-style) {
      display: none; } }
  .side-open:not(.no-style)-wrap {
    width: 3rem;
    height: 3rem;
    border-radius: 100rem;
    background-color: white;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center; }
    .side-open:not(.no-style)-wrap .icon {
      color: #ffffff;
      font-weight: 600;
      font-size: 1.6rem; }

.side-open-wrap .icon {
  color: #ffffff; }

.side-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4.5rem;
  padding: 0 0 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  background-color: #FF8387; }
  @media screen and (min-width: 1201px) {
    .side-top {
      display: none; } }
  .side-top .text {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden; }

.side-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 3rem;
  height: 3rem;
  background-color: #D72429;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 0 0.4rem; }
  .side-close .icon {
    font-size: 1.8rem;
    font-weight: 400;
    transition: 0.6s ease-in-out;
    color: #ffffff; }
  @media screen and (min-width: 1201px) {
    .side-close {
      display: none; } }

.social-list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  gap: 1.2rem; }

.social-link {
  display: block;
  width: 3.2rem;
  height: 3.2rem;
  transition: 0.3s ease-in-out;
  cursor: pointer; }
  .social-link:hover {
    transform: translateY(-5px); }
  .social-link img {
    width: 100%;
    display: block; }

.text-hori .char {
  transition: 0.6s calc(var(--char-index) * 0.02s) ease-in-out;
  transform: translateX(4rem);
  opacity: 0; }

.text-hori.is-inview .char {
  transform: translateX(0);
  opacity: 1; }

.text-verti .char {
  transition: 0.6s calc(var(--char-index) * 0.02s) ease-in-out;
  transform: translateY(4rem);
  opacity: 0; }

.text-verti.is-inview .char {
  transform: translateY(0);
  opacity: 1; }

.text-scale .char {
  transition: 0.6s calc(var(--char-index) * 0.02s) ease-in-out;
  transform: scale(0);
  opacity: 0; }

.text-scale.is-inview .char {
  transform: scale(1);
  opacity: 1; }

.count {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  border-radius: 0.8rem;
  width: fit-content;
  padding: 0.5rem;
  gap: 1rem; }
  .count-btn {
    width: 3rem;
    height: 3rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    cursor: pointer;
    border-radius: 0.4rem;
    transition: 0.4s; }
    .count-btn i {
      font-size: 1rem; }
    @media (hover: hover) {
      .count-btn:hover {
        background: var(--color-pri);
        color: white; } }

.hover-rec::after {
  content: '';
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: 0rem;
  bottom: 0rem;
  border: 0.1rem solid transparent;
  border-radius: inherit;
  transition: 0.2s;
  pointer-events: none; }

@media (hover: hover) {
  .hover-rec:hover::after {
    left: 2%;
    top: 2%;
    right: 2%;
    bottom: 2%;
    border-color: var(--color-second); } }

.title .title-pri {
  color: var(--Primary, #D72429);
  font-size: 5.6rem;
  font-weight: 700;
  text-transform: capitalize;
  text-wrap: pretty;
  margin-bottom: 4.8rem; }
  @media only screen and (max-width: 1200px) {
    .title .title-pri {
      font-size: 5.1rem; } }
  @media only screen and (max-width: 800px) {
    .title .title-pri {
      font-size: 4rem; } }
  @media only screen and (max-width: 500px) {
    .title .title-pri {
      font-size: 2.4rem; } }
  @media screen and (max-width: 800px) {
    .title .title-pri {
      margin-bottom: calc(4.8rem / 2); } }
  .title .title-pri.white {
    color: white; }
  .title .title-pri.center {
    text-align: center; }

.title .title-second {
  color: var(--color-pri);
  font-size: 5.6rem;
  font-weight: 700;
  margin-bottom: 2.4rem; }
  @media only screen and (max-width: 1200px) {
    .title .title-second {
      font-size: 5.1rem; } }
  @media only screen and (max-width: 800px) {
    .title .title-second {
      font-size: 4rem; } }
  @media only screen and (max-width: 500px) {
    .title .title-second {
      font-size: 2.4rem; } }
  @media screen and (max-width: 800px) {
    .title .title-second {
      margin-bottom: calc(2.4rem / 2); } }

.title .title-sub {
  color: var(--Gray-700, #686060);
  text-align: center; }

.checker {
  cursor: pointer;
  display: flex;
  gap: 1rem;
  user-select: none; }
  .checker .sqr {
    width: 2.4rem;
    height: 2.4rem;
    flex-shrink: 0;
    position: relative;
    display: block; }
    .checker .sqr::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url("../assets/images/check-empty.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      left: 0;
      top: 0; }
  .checker .circle {
    width: 2.4rem;
    height: 2.4rem;
    flex-shrink: 0;
    position: relative;
    display: block; }
    .checker .circle::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url("../assets/images/radio-empty.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      left: 0;
      top: 0; }
  .checker .txt {
    display: block; }
    @media screen and (max-width: 500px) {
      .checker .txt {
        font-size: 1.4rem; } }
  .checker input {
    display: none; }
  .checker input:checked ~ .sqr::after {
    background-image: url("../assets/images/check-fill.svg"); }
  .checker input:checked ~ .circle::after {
    background-image: url("../assets/images/radio-fill.svg"); }

.news-list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.2rem;
  margin-bottom: -2.4rem; }
  @media screen and (max-width: 500px) {
    .news-list {
      margin: 0 -0.4rem;
      margin-bottom: -0.8rem; } }

.news-item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  padding: 0 1.2rem;
  margin-bottom: 2.4rem;
  width: calc(100% / 3); }
  @media screen and (max-width: 500px) {
    .news-item {
      padding: 0 0.4rem;
      margin-bottom: 0.8rem; } }
  .news-item-wr {
    width: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .news-item-wr .image {
      margin-bottom: 2.4rem; }
      @media screen and (max-width: 800px) {
        .news-item-wr .image {
          margin-bottom: calc(2.4rem / 2); } }
      .news-item-wr .image-inner {
        border-radius: .8rem;
        display: block;
        padding-top: calc((279 / 438) * 100%);
        position: relative;
        overflow: hidden; }
        .news-item-wr .image-inner img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: .3s; }
  .news-item-content .news-item-tt {
    color: var(--Gray-900, #131111);
    font-weight: 700;
    margin-bottom: 1.2rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    transition: .3s;
    text-wrap: pretty; }
    @media screen and (max-width: 800px) {
      .news-item-content .news-item-tt {
        margin-bottom: calc(1.2rem / 2); } }
    .news-item-content .news-item-tt:hover {
      color: #D72429; }
  .news-item-content .author {
    margin-bottom: 1.2rem; }
    @media screen and (max-width: 800px) {
      .news-item-content .author {
        margin-bottom: calc(1.2rem / 2); } }
  .news-item-content .news-item-desc {
    margin-bottom: 1.2rem;
    color: var(--Neutral-Gray-700, #334155);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-wrap: pretty; }
    @media screen and (max-width: 800px) {
      .news-item-content .news-item-desc {
        margin-bottom: calc(1.2rem / 2); } }
  .news-item:hover .image img {
    transform: translate(-50%, -50%) scale(1.1); }
  @media screen and (max-width: 768px) {
    .news-item {
      width: 50%; } }
  @media screen and (max-width: 425px) {
    .news-item-wr .image {
      margin-bottom: .8rem; }
    .news-item-content .news-item-tt,
    .news-item-content .author,
    .news-item-content .news-item-desc {
      margin-bottom: .4rem; }
    .news-item-content .news-item-tt,
    .news-item-content .link {
      font-size: 1.4rem; }
    .news-item-content .news-item-desc,
    .news-item-content .author {
      font-size: 1.2rem; }
      .news-item-content .news-item-desc-txt,
      .news-item-content .author-txt {
        font-size: 1.2rem; } }

.author-share {
  margin-bottom: 2.6rem; }
  @media screen and (max-width: 800px) {
    .author-share {
      margin-bottom: calc(2.6rem / 2); } }
  .author-share .as-flex {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem;
    justify-content: space-between; }

.author {
  color: var(--Neutral-Gray-600, #475569); }
  .author-txt {
    font-size: 1.6rem;
    font-weight: 700; }
  .author-space, .author-date {
    font-weight: 400; }

.share-wr {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  gap: 1.6rem; }

.news-dt .title-pri {
  margin-bottom: 2rem; }
  @media screen and (max-width: 800px) {
    .news-dt .title-pri {
      margin-bottom: calc(2rem / 2); } }

.news-dt .image {
  margin-bottom: 4.8rem; }
  @media screen and (max-width: 800px) {
    .news-dt .image {
      margin-bottom: calc(4.8rem / 2); } }
  .news-dt .image-inner {
    display: block;
    padding-top: calc((633 / 1378) * 100%);
    position: relative;
    overflow: hidden; }
    .news-dt .image-inner img {
      border-radius: .8rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover; }

.news-related-wr {
  position: relative; }

.news-related .swiper {
  margin: 0 -1.6rem; }

.news-related .swiper-slide {
  width: calc(100% / 3); }

.news-related .news-item {
  width: 100%; }

.news {
  padding-bottom: 10rem; }
  @media screen and (max-width: 800px) {
    .news {
      padding-bottom: calc(10rem / 2); } }
  .news-top .news-item {
    width: 100%;
    padding: unset;
    margin-bottom: unset; }
    .news-top .news-item-wr {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-wrap: wrap;
      margin: 0 -1.2rem;
      margin-bottom: -2.4rem;
      flex-direction: row; }
      @media screen and (max-width: 500px) {
        .news-top .news-item-wr {
          margin: 0 -0.4rem;
          margin-bottom: -0.8rem; } }
      .news-top .news-item-wr .image {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-wrap: wrap;
        padding: 0 1.2rem;
        margin-bottom: 2.4rem;
        width: calc(5 / 12 * 100%); }
        @media screen and (max-width: 500px) {
          .news-top .news-item-wr .image {
            padding: 0 0.4rem;
            margin-bottom: 0.8rem; } }
        .news-top .news-item-wr .image-inner {
          width: 100%; }
      .news-top .news-item-wr .news-item-content {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-wrap: wrap;
        padding: 0 1.2rem;
        margin-bottom: 2.4rem;
        flex-direction: column;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        -ms-align-items: center;
        align-items: center;
        -webkit-align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        align-items: flex-start;
        width: calc(7 / 12 * 100%); }
        @media screen and (max-width: 500px) {
          .news-top .news-item-wr .news-item-content {
            padding: 0 0.4rem;
            margin-bottom: 0.8rem; } }
        .news-top .news-item-wr .news-item-content .news-item-tt,
        .news-top .news-item-wr .news-item-content .news-item .author, .news-top .news-item-wr .news-item-content .news-item-desc,
        .news-top .news-item-wr .news-item-content .news-item .link {
          height: fit-content; }

.product-dt-main {
  position: relative;
  overflow: hidden;
  position: relative; }
  .product-dt-main::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(255, 231, 192, 0) 96.42%, #FFE7C0 100%);
    z-index: 0; }
  .product-dt-main .decor {
    pointer-events: none;
    width: calc(247.5 / 1728 * 100%);
    content: '';
    position: absolute;
    bottom: 0;
    right: -3.5%; }
    .product-dt-main .decor img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .product-dt-main .breadcrumb {
    position: relative; }
    .product-dt-main .breadcrumb-item {
      color: #686060; }
    .product-dt-main .breadcrumb-item:not(:first-child)::before {
      color: #686060; }
  .product-dt-main .bg {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    z-index: 0;
    pointer-events: none; }
    .product-dt-main .bg img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .product-dt-main .bg {
    z-index: -1; }
    .product-dt-main .bg img {
      object-fit: fill; }
      @media screen and (max-width: 600px) {
        .product-dt-main .bg img {
          object-fit: cover; } }

.product-dt-wr {
  padding-bottom: 3.8rem; }
  @media screen and (max-width: 800px) {
    .product-dt-wr {
      padding-bottom: calc(3.8rem / 2); } }
  @media screen and (max-width: 900px) {
    .product-dt-wr {
      flex-direction: column;
      margin: 0 auto;
      width: 85%; } }
  @media screen and (max-width: 900px) and (max-width: 500px) {
    .product-dt-wr {
      width: 100%; } }
  @media screen and (max-width: 900px) {
      .product-dt-wr .col-6 {
        width: 100%; }
        .product-dt-wr .col-6 .image {
          width: 60%;
          margin: 0 auto; } }
      @media screen and (max-width: 900px) and (max-width: 500px) {
        .product-dt-wr .col-6 .image {
          width: 100%; } }

.product-dt .product-dt-lf {
  position: relative;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }
  .product-dt .product-dt-lf .buc {
    width: calc(100% + (2/12*100%));
    content: '';
    position: absolute;
    bottom: -16rem;
    left: 0;
    right: 0;
    z-index: -1; }
    @media screen and (max-width: 1300px) {
      .product-dt .product-dt-lf .buc {
        bottom: -13rem; } }
    @media screen and (max-width: 900px) {
      .product-dt .product-dt-lf .buc {
        display: none; } }
    .product-dt .product-dt-lf .buc img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .product-dt .product-dt-lf .image {
    height: fit-content; }
    .product-dt .product-dt-lf .image-inner {
      display: block;
      padding-top: calc((587 / 672) * 100%);
      position: relative;
      overflow: hidden; }
      .product-dt .product-dt-lf .image-inner img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: contain; }

.product-dt .product-dt-rt .content {
  padding: 4rem;
  border-radius: 2.4rem;
  background: rgba(255, 255, 255, 0.5); }
  @media screen and (max-width: 800px) {
    .product-dt .product-dt-rt .content {
      padding: 2rem; } }
  .product-dt .product-dt-rt .content .title-pri {
    font-size: 4rem;
    margin-bottom: 2.4rem;
    text-wrap: pretty; }
    @media only screen and (max-width: 1200px) {
      .product-dt .product-dt-rt .content .title-pri {
        font-size: 3.5rem; } }
    @media only screen and (max-width: 800px) {
      .product-dt .product-dt-rt .content .title-pri {
        font-size: 2.7rem; } }
    @media screen and (max-width: 800px) {
      .product-dt .product-dt-rt .content .title-pri {
        margin-bottom: calc(2.4rem / 2); } }
  .product-dt .product-dt-rt .content .tt {
    color: var(--Gray-900, #131111);
    font-weight: 700;
    margin-bottom: .8rem;
    text-wrap: pretty; }
  .product-dt .product-dt-rt .content .desc {
    margin-bottom: .8rem;
    text-wrap: pretty; }

.product-dt .prod-link {
  padding-top: 3.2rem; }
  @media screen and (max-width: 800px) {
    .product-dt .prod-link {
      padding-top: calc(3.2rem / 2); } }
  .product-dt .prod-link-list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.2rem;
    margin-bottom: -2.4rem; }
    @media screen and (max-width: 500px) {
      .product-dt .prod-link-list {
        margin: 0 -0.4rem;
        margin-bottom: -0.8rem; } }
  .product-dt .prod-link-item {
    width: calc(100% / 5);
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    padding: 0 1.2rem;
    margin-bottom: 2.4rem; }
    @media screen and (max-width: 500px) {
      .product-dt .prod-link-item {
        padding: 0 0.4rem;
        margin-bottom: 0.8rem; } }
    .product-dt .prod-link-item .imag {
      width: 100%; }
      .product-dt .prod-link-item .imag-inner {
        border-radius: .8rem;
        border: 1px solid var(--Neutral-Gray-200, #E2E8F0);
        display: block;
        padding-top: calc((1 / 1) * 100%);
        position: relative;
        overflow: hidden; }
        .product-dt .prod-link-item .imag-inner img {
          border-radius: .4rem;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: .3s; }
    .product-dt .prod-link-item.active .imag-inner {
      border: 3px solid var(--color-pri); }
    .product-dt .prod-link-item:hover .imag-inner img {
      transform: translate(-50%, -50%) scale(1.1); }

@media screen and (max-width: 768px) {
  .prod-link .row {
    flex-direction: column; }
    .prod-link .row .col-6 {
      width: 100%; }
    .prod-link .row .prod-link-rt {
      display: none; } }

.product-dt-content .title-second {
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 2.4rem; }
  @media only screen and (max-width: 1200px) {
    .product-dt-content .title-second {
      font-size: 3.5rem; } }
  @media only screen and (max-width: 800px) {
    .product-dt-content .title-second {
      font-size: 2.7rem; } }
  @media screen and (max-width: 800px) {
    .product-dt-content .title-second {
      margin-bottom: calc(2.4rem / 2); } }

.product-dt-content .cus-content {
  margin-bottom: 4rem; }
  @media screen and (max-width: 800px) {
    .product-dt-content .cus-content {
      margin-bottom: calc(4rem / 2); } }
  .product-dt-content .cus-content .cus-banner .banner-inner {
    display: block;
    padding-top: calc((632 / 320) * 100%);
    position: relative;
    overflow: hidden;
    border-radius: .8rem; }
    .product-dt-content .cus-content .cus-banner .banner-inner img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: .3s; }
  .product-dt-content .cus-content .cus-banner:hover .banner-inner img {
    transform: translate(-50%, -50%) scale(1.1); }
  @media screen and (max-width: 768px) {
    .product-dt-content .cus-content {
      flex-direction: column; }
      .product-dt-content .cus-content .col-9 {
        width: 100%; }
      .product-dt-content .cus-content .col-3 {
        width: 32rem;
        max-width: 100%;
        margin: 0 auto; } }

.product-dt-content .prod-size-list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.2rem;
  margin-bottom: -2.4rem; }
  @media screen and (max-width: 500px) {
    .product-dt-content .prod-size-list {
      margin: 0 -0.4rem;
      margin-bottom: -0.8rem; } }

.product-dt-content .prod-size-item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  padding: 0 1.2rem;
  margin-bottom: 2.4rem;
  flex-direction: column;
  width: calc(100% / 10); }
  @media screen and (max-width: 500px) {
    .product-dt-content .prod-size-item {
      padding: 0 0.4rem;
      margin-bottom: 0.8rem; } }
  .product-dt-content .prod-size-item .image {
    width: 100%;
    margin-bottom: .8rem; }
    .product-dt-content .prod-size-item .image-inner {
      display: block;
      padding-top: calc((554 / 232) * 100%);
      position: relative;
      overflow: hidden; }
      .product-dt-content .prod-size-item .image-inner img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: contain; }
  .product-dt-content .prod-size-item:hover .prod-size-tt {
    color: #D72429; }

.product-dt-content .prod-size-tt {
  text-align: center;
  color: #000000;
  font-weight: 700;
  transition: .3s; }
  @media screen and (max-width: 768px) {
    .product-dt-content .prod-size-tt {
      font-size: 1.4rem; } }

@media screen and (max-width: 650px) {
  .product-dt-content .prod-size-item {
    width: calc(100% / 5); } }

.contact-wr {
  box-shadow: 0rem 0.9rem 1.8rem 0rem rgba(0, 0, 0, 0.15); }

.contact .contact-rt {
  margin-bottom: unset;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center; }

.contact .contact-lf {
  background: #D72429;
  margin-bottom: unset; }
  .contact .contact-lf .wrapper {
    padding: 4.8rem 0;
    width: calc(390 / 438 * 100%);
    max-width: 100%;
    margin: 0 auto; }
    @media screen and (max-width: 800px) {
      .contact .contact-lf .wrapper {
        padding: 2.4rem 0; } }
    .contact .contact-lf .wrapper .logo {
      width: 30rem;
      max-width: 100%;
      margin: auto;
      margin-top: 2.4rem;
      margin-bottom: 3.2rem; }
      @media screen and (max-width: 800px) {
        .contact .contact-lf .wrapper .logo {
          margin-top: calc(2.4rem / 2); } }
      @media screen and (max-width: 800px) {
        .contact .contact-lf .wrapper .logo {
          margin-bottom: calc(3.2rem / 2); } }
      .contact .contact-lf .wrapper .logo .cuscom-logo-link img {
        width: 100%;
        height: 100%;
        object-fit: contain; }
    .contact .contact-lf .wrapper .social-list {
      gap: 1.6rem;
      justify-content: center; }
    .contact .contact-lf .wrapper .social-link {
      width: 4.8rem;
      height: 4.8rem; }
      .contact .contact-lf .wrapper .social-link:nth-child(4) {
        width: 9.4rem;
        height: 4.8rem; }
      @media screen and (max-width: 800px) {
        .contact .contact-lf .wrapper .social-link {
          width: 3.8rem;
          height: 3.8rem; }
          .contact .contact-lf .wrapper .social-link:nth-child(4) {
            width: 8.4rem;
            height: 4.8rem; } }

@media screen and (max-width: 768px) {
  .contact-wr {
    flex-direction: column; }
  .contact-lf, .contact-rt {
    width: 100%; } }

@media screen and (max-width: 425px) {
  .contact .ic-text .ic-text-item .text {
    font-size: 1.4rem; } }

@media screen and (max-width: 320px) {
  .contact .wrapper,
  .contact .form-contact {
    width: 100% !important; } }

.ic-text .ic-text-item {
  margin-bottom: 1.6rem;
  display: flex;
  gap: 1.2rem; }
  .ic-text .ic-text-item:last-child {
    margin-bottom: 3.2rem; }
    @media screen and (max-width: 800px) {
      .ic-text .ic-text-item:last-child {
        margin-bottom: calc(3.2rem / 2); } }
  @media screen and (max-width: 800px) {
    .ic-text .ic-text-item {
      margin-bottom: calc(1.6rem / 2); } }
  .ic-text .ic-text-item .ic {
    flex-shrink: 0;
    width: 2.4rem;
    height: 2.4rem; }
    .ic-text .ic-text-item .ic img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .ic-text .ic-text-item .text {
    color: #ffffff;
    font-family: "Inter", sans-serif;
    transition: .3s;
    text-wrap: pretty; }
    .ic-text .ic-text-item .text:hover {
      color: var(--color-hover); }

.form-contact {
  padding: 4rem 0;
  width: calc(748 / 940 * 100%);
  max-width: 100%; }
  @media screen and (max-width: 800px) {
    .form-contact {
      padding: 2rem 0; } }
  .form-contact .title {
    margin-bottom: 2.4rem; }
    @media screen and (max-width: 800px) {
      .form-contact .title {
        margin-bottom: calc(2.4rem / 2); } }
    .form-contact .title-second {
      color: var(--Neutral-Gray-900, #0F172A);
      font-size: 3.6rem;
      font-weight: 700;
      text-align: center;
      margin-bottom: .4rem; }
      @media only screen and (max-width: 1200px) {
        .form-contact .title-second {
          font-size: 3.1rem; } }
      @media only screen and (max-width: 800px) {
        .form-contact .title-second {
          font-size: 2.6rem; } }
    .form-contact .title-sub {
      color: var(--Neutral-Gray-600, #475569);
      text-align: center;
      font-size: 1.8rem; }
      @media only screen and (max-width: 1200px) {
        .form-contact .title-sub {
          font-size: 1.6rem; } }
      @media only screen and (max-width: 800px) {
        .form-contact .title-sub {
          font-size: 1.5rem; } }
