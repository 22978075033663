:root {
    --size-hd: 11.8rem;

    // distance section
    --pd-sc: 8rem;

    @media (max-width: 1200px) {
        --pd-sc: 4rem;
        --radius: .8rem;
        --size-hd: 6rem;
    }

    // translate
    --transition: 0.6s calc(var(--char-index) * 0.02s) ease-in-out;
    // padding
    --pBot: calc((298/1728*100%));
    // Resistance
    --recontainer: calc(100vw - (100vw - 120rem)/2);
    --half: calc((100vw - 120rem)/2);

    // color
    --color-text: #0F172A;
    --color-pri: #D72429;
    --color-second: #FF8387;
    --color-white: #FFF;
    --color-hover: #FF8387;
    --Gray-500: #ACA1A1;
    --Gray-900: #131111;
    --Primary: #D72429;
    // shadow 
    --sdow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    // linear
    --linear-pastel: linear-gradient(30deg, #FFE7E7, #FFC6C6);
    --linear-blue:linear-gradient(135deg, #F5F8FF 0%, #E8F0FF 100%);
    // 
    --filter:brightness(0) saturate(100%) invert(70%) sepia(9%) saturate(938%) hue-rotate(178deg) brightness(95%) contrast(90%);
}

$color-pri-ln: #F36F3F;
$color-pri: #D72429;
$color-second: #FF8387;
$color-third: #408630;
$color-four: #FCB445;
$color-five: #f05123;
$color-black: #000000;
$color-white: #ffffff;
$color-text: #686060; 
$color-text-second: #363636;
$color-border-01: rgba(0, 0, 0, 0.1);
$color-border-02: rgba(0, 0, 0, 0.2);
$color-border-03: rgba(0, 0, 0, 0.3);
$color-border-04: rgba(0, 0, 0, 0.4);
$color-border-05: rgba(0, 0, 0, 0.5);
$color-border-06: rgba(0, 0, 0, 0.6);
$color-border-07: rgba(0, 0, 0, 0.7);
$color-border-08: rgba(0, 0, 0, 0.8);
$color-border-09: rgba(0, 0, 0, 0.9);
$color-opa-white-01: rgba(255, 255, 255, 0.1);
$color-opa-white-02: rgba(255, 255, 255, 0.2);
$color-opa-white-03: rgba(255, 255, 255, 0.3);
$color-opa-white-04: rgba(255, 255, 255, 0.4);
$color-opa-white-05: rgba(255, 255, 255, 0.5);
$color-opa-white-06: rgba(255, 255, 255, 0.6);
$color-opa-white-07: rgba(255, 255, 255, 0.7);
$color-opa-white-08: rgba(255, 255, 255, 0.8);
$color-opa-white-09: rgba(255, 255, 255, 0.9);
//Responsive
$res-mobile: 1199px;
$res-mobile-min: 1201px;
$mobile: 500px;
//Distance
$radius: 0.6rem;
// cubic
$cubic: cubic-bezier(0.72, 0.04, 0.49, 0.82);