.product-dt {
    &-main {
        position: relative;
        overflow: hidden;

        // to shadow the image
        &::before {
            @include pseudo;
            @include full(0, 0, 0, 0);
            background: linear-gradient(180deg, rgba(255, 231, 192, 0.00) 96.42%, #FFE7C0 100%);
            z-index: 0;
        }

        .decor {
            pointer-events: none;
            // width: calc();
            width: calc(247.5 / 1728 * 100%);
            @include pseudo;
            bottom: 0;
            right: -3.5%;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .breadcrumb {
            position: relative;

            &-item {
                color: #686060;
            }

            &-item:not(:first-child)::before {
                color: #686060;
            }
        }

        @include bg;

        .bg {
            z-index: -1;



            img {
                object-fit: fill;

                @include max(600px) {
                    object-fit: cover;
                }
            }
        }
    }

    &-wr {
        @include pb(3.8rem);

        @include max(900px) {
            flex-direction: column;
            margin: 0 auto;
            width: 85%;

            @include max(500px) {
                width: 100%;
            }


            .col-6 {
                width: 100%;

                .image {
                    width: 60%;
                    margin: 0 auto;

                    @include max(500px) {
                        width: 100%;
                    }

                }
            }
        }
    }

    .product-dt-lf {
        position: relative;
        @include flex-col;
        justify-content: flex-end;
        // height: 100%;

        .buc {
            // width: 62rem;
            // width: 75rem;
            width: calc(100% + (2/12*100%));
            @include pseudo;
            bottom: -16rem;

            @include max(1300px) {
                bottom: -13rem;
            }

            @include max(900px) {
                display: none;
            }

            left: 0;
            right: 0;
            z-index: -1;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .image {
            // @include mt(7.7rem);
            height: fit-content;

            &-inner {

                @include imgPd(587, 672);

                img {
                    @include imgContain;
                }
            }

        }
    }

    .product-dt-rt {
        .content {
            padding: 4rem;

            @include max(800px) {
                padding: 2rem;
            }

            border-radius: 2.4rem;
            background: rgba(255, 255, 255, 0.50);

            .title {
                &-pri {
                    @include fz-40;
                    @include mb(2.4rem);
                    text-wrap: pretty;
                }
            }

            .tt {
                color: var(--Gray-900, #131111);
                font-weight: 700;
                margin-bottom: .8rem;
                text-wrap: pretty;
            }

            .desc {
                margin-bottom: .8rem;
                text-wrap: pretty;
            }
        }
    }

    .prod-link {
        @include pt(3.2rem);

        &-list {
            @include flex-list;
        }

        &-item {
            width: calc(100% / 5);
            @include flex-item;

            .imag {
                width: 100%;


                &-inner {
                    border-radius: .8rem;
                    border: 1px solid var(--Neutral-Gray-200, #E2E8F0);
                    @include imgPd(1, 1);

                    img {
                        border-radius: .4rem;
                        @include imgCover;
                        transition: .3s;
                    }
                }
            }

            &.active {
                .imag-inner {
                    border: 3px solid var(--color-pri);
                }
            }

            &:hover {
                .imag-inner {
                    img {
                        transform: translate(-50%, -50%)scale(1.1);
                    }
                }
            }
        }
    }
}


.prod-link {
    .row {
        @include max(768px) {
            flex-direction: column;

            .col-6 {
                width: 100%;
            }

            .prod-link-rt {
                display: none;
            }
        }
    }
}

.product-dt-content {
    .title {
        &-second {
            @include fz-40;
            font-weight: 700;
            @include mb(2.4rem);
        }
    }

    .cus-content {
        @include mb(4rem);

        .cus-content-main {}

        .cus-banner {
            .banner {
                &-inner {
                    @include imgPd(632, 320);
                    border-radius: .8rem;

                    img {
                        @include imgCover;
                        transition: .3s;
                    }
                }
            }

            &:hover {
                .banner-inner {
                    img {
                        transform: translate(-50%, -50%)scale(1.1);
                    }
                }
            }
        }

        @include max(768px) {
            flex-direction: column;

            .col-9 {
                width: 100%;
            }

            .col-3 {
                // width: 100%;
                width: 32rem;
                max-width: 100%;
                margin: 0 auto;

                .banner-inner {}
            }
        }
    }

    .prod-size {
        &-list {
            @include flex-list;
        }

        &-item {
            @include flex-item;
            flex-direction: column;
            width: calc(100% / 10);

            .image {
                width: 100%;
                margin-bottom: .8rem;

                &-inner {
                    @include imgPd(554, 232);

                    img {
                        @include imgContain;
                    }
                }
            }

            &:hover {
                .prod-size-tt {
                    color: $color-pri;
                }
            }
        }

        &-tt {
            text-align: center;
            color: $color-black;
            font-weight: 700;
            transition: .3s;

            @include max(768px) {
                @include fz-14;
            }
        }

        @include max(650px) {
            &-item {
                width: calc(100% / 5);
            }
        }
    }
}